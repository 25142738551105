import React, { useState, useContext, useEffect } from "react";
import { Layout, Popover, Button, Row, Col } from 'antd';
import { useParams, Redirect, useLocation } from "react-router-dom";
import { MenuOutlined } from '@ant-design/icons';
import queryString from "query-string";
import { useTranslation } from 'react-i18next';

import { SideMenu } from "./SideMenu";
import { MENU } from "./constants";
import { DocumentsPage } from '../documents/DocumentsPage'
import { ReviewPage } from '../review/ReviewPage'
import InactiveUserView from './InactiveUserView'
import { sessionStore, SESSION_ATTRS } from "../store/session";
import { logout } from '../login/actions'
import { isDesktop } from "../utils/view";

const { Header } = Layout;

export const DashBoardPage = ({ setIsAuthenticatedUser }) => {

    const { t } = useTranslation();

    const { state } = useContext(sessionStore);
    const [menuCollapsed, setMenuCollapsed] = useState(false);
    let { mainMenu } = useParams();
    let location = useLocation();
    const user = state.get(SESSION_ATTRS.LOGGED_USER);
    const [width, setWidth] = useState(window.innerWidth);

    const toggleCollapsed = () => {
        setMenuCollapsed(!menuCollapsed);
    };

    useEffect(() => {
        function handleResize() {
          setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [width]);

    useEffect(() => {
        if(isDesktop()) {
            if(width <= 768 && !menuCollapsed){
                setMenuCollapsed(true);
            }else if (width > 768 && menuCollapsed){
                setMenuCollapsed(false);
            }
        }
        
      },[menuCollapsed, width]);

    let getView = () => {

        const queryParams = queryString.parse(location.search);
        const orgId = queryParams.org;
        const orgUnitId = queryParams.org ? queryParams.orgUnit : undefined;
        const certificate = queryParams.certification ? queryParams.certification : undefined;

        if (!user.isValidUser) {
            return <InactiveUserView />
        }

        switch (mainMenu) {
            case MENU.HOME:
            case undefined:
                return <ReviewPage orgId={orgId} orgUnitId={orgUnitId} certificate={certificate}/>;
            // case MENU.DOCUMENTS:
            //     return <DocumentsPage />
            // case MENU.INSPECTIONS:
            //     return <DocumentsPage />
            default:
                return <Redirect to={`/`} />;
        }
    };

    const onLogoutClick = async () => {
        await logout();
        setIsAuthenticatedUser(false);
    }

    const userIcon = () => {
        //const getFirstChar = (name) => name ? name.substr(0, 1) : "";
        return `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;
    }

    const mobileMenuToggler = !isDesktop() ? <div className="menu-toggler">
        <Button data-testid={`side-menue`} className="menu-toggle-button" icon={<MenuOutlined className="menu-toggle-icon" />} onClick={toggleCollapsed} />
    </div> : null;

    return (
        <Layout>

            <Header style={{ background: 'white' }}>
                <div>
                    <Row>
                        <Col span={4}>
                            <div className="top-menu-logo" style={{ textAlign: "left" }}>
                                {!isDesktop() && <div className="mobile-drawer-toggler">{mobileMenuToggler}</div> }
                                <img src='/icons/audubon-logo.png' alt='' style={{ maxHeight: '40px', maxWidth: '100px' }} />
                            </div>
                        </Col>
                        <Col span={20}>
                            <div className="login-info">
                                <a href="/" className="profile-link">
                                    {`${user.firstName} ${user.lastName}`}
                                    <Popover placement="bottomRight" content={
                                        <div>
                                            <Button data-testid={`log-out`} type="link" onClick={onLogoutClick}>{t('Logout')}</Button>
                                        </div>
                                    } trigger="click">
                                        <span>{userIcon()}</span>
                                    </Popover>

                                </a>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Header>
            <Layout>
                <SideMenu menuCollapsed={isDesktop() ? false : menuCollapsed} selectedMenu={mainMenu} isDesktop={isDesktop()} toggle={toggleCollapsed} />
                <Layout style={{ padding: isDesktop() ? '0 24px 24px' : '0 16px 24px' }}>
                    {getView()}
                </Layout>
            </Layout>
        </Layout>
    );
}