import React, { useEffect, useState } from 'react';
import { Row, Col, Spin, Empty, Card, Descriptions, Collapse } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons'
import { CSVLink } from "react-csv";
import _ from 'lodash';
import { getProgressIcon } from '../utils/common-utils'
import { STATUS } from '../utils/constants';
import { getFormProgress } from './form-actions/FormActions';

const { Panel } = Collapse;

export const AudubonOrganisationDetailsView = (props) => {
  const [audubonDetails, setAudubonDetails] = useState({});
  const [generalInformationForm, setGeneralInformationForm] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    if (props.orgId && props.orgUnitId && props.data && props.data.audubon_details) {
      const audubonData = props.data.audubon_details;
      let orgDetails = {}

      if (audubonData.GeneralInformationForm) {
        _.merge(orgDetails, { GeneralInformationForm: audubonData.GeneralInformationForm });
        setGeneralInformationForm(audubonData.GeneralInformationForm.FormDetails);
      }
      setAudubonDetails(orgDetails);
    }
    setIsLoading(false);
  }, [props]);

  const cardTitle = <Row><Col span={12}>Organization Details</Col><Col span={12} style={{ textAlign: 'right' }}>{getProgressIcon(getFormProgress(audubonDetails && audubonDetails.GeneralInformationForm ? audubonDetails.GeneralInformationForm : 0))}</Col></Row>;

  const getFieldValue = (field) => field ? field : '';
  const getFieldBoolean = (field) => field ? 'Yes' : 'No';

  const lblStyle = { labelStyle: { backgroundColor: '#E0EBF5', fontWeight: '500' } };
  
  const emptyContent = <Empty description="No Data Added" />
  const { basic_information, typeOfCourses, golfPlay, history, setting } = generalInformationForm;

  const basicInformation = basic_information ? basic_information : {};
  const courses = typeOfCourses ? typeOfCourses : {};
  const golf_play = golfPlay ? golfPlay : {};
  const historyInfo = history ? history : {};
  const settingData = setting ? setting : {};

  const generateGeneralInfoCSVData = () => {

    const generalInfoCSVData = [["Title", "Value"]];
    if (!_.isEmpty(generalInformationForm)) {
      generalInfoCSVData.push(["Course Name", getFieldValue(basicInformation.courseName)]);
      generalInfoCSVData.push(["Address", getFieldValue(basicInformation.address)]);
      generalInfoCSVData.push(["City", getFieldValue(basicInformation.city)]);
      generalInfoCSVData.push(["State / Province", getFieldValue(basicInformation.stateProvince)]);
      generalInfoCSVData.push(["Zip Code", getFieldValue(basicInformation.zipCode)]);
      generalInfoCSVData.push(["Country", getFieldValue(basicInformation.country)]);
      generalInfoCSVData.push(["Country / Municipality", getFieldValue(basicInformation.countyMunicipality)]);
      generalInfoCSVData.push(["Main Contact Name / Title", getFieldValue(basicInformation.mainContactNameTitle)]);
      generalInfoCSVData.push(["Telephone", getFieldValue(basicInformation.telephone)]);
      generalInfoCSVData.push(["E-mail Address", getFieldValue(basicInformation.eMailAddress)]);
      generalInfoCSVData.push(["Mobile Number", getFieldValue(basicInformation.cellNumber)]);
      generalInfoCSVData.push(["Web Address", getFieldValue(basicInformation.webSiteAddress)]);
      generalInfoCSVData.push(["Superintendent", getFieldValue(basicInformation.superintendent)]);
      generalInfoCSVData.push(["General Manager", getFieldValue(basicInformation.generalManager)]);
      generalInfoCSVData.push(["Management Company", getFieldValue(basicInformation.managementCompany)]);
      generalInfoCSVData.push(["Mailing Street Address / PO Box", getFieldValue(basicInformation && basicInformation.mailingAddress ? basicInformation.mailingAddress.mailingStreetAddress : '')]);
      generalInfoCSVData.push(["Mailing City", getFieldValue(basicInformation && basicInformation.mailingAddress ? basicInformation.mailingAddress.mailingCity : '')]);
      generalInfoCSVData.push(["Mailing State / Province", getFieldValue(basicInformation && basicInformation.mailingAddress ? basicInformation.mailingAddress.mailingState : '')]);
      generalInfoCSVData.push(["Mailing Zip Code", getFieldValue(basicInformation && basicInformation.mailingAddress ? basicInformation.mailingAddress.mailingZip : '')]);
      generalInfoCSVData.push(["Type of Course", "Private -" + getFieldBoolean(courses.private) + ", Semi-Private -" + getFieldBoolean(courses.semiPrivate)
        + ", Daily Fee -" + getFieldBoolean(courses.dailyFee) + ", Municipal -" + getFieldBoolean(courses.municipal) +
        ", Resort -" + getFieldBoolean(courses.resort) + ", Golf/Residential Community -" + getFieldBoolean(courses.golfResidentialCommunity) +
        ", Other -" + getFieldBoolean(courses.other)]);
      generalInfoCSVData.push(["Golf Play", ""]);
      generalInfoCSVData.push(["Number of Holes", getFieldValue(golf_play.numberOfHoles)]);
      generalInfoCSVData.push(["No of Members", getFieldValue(golf_play.numberOfMembers)]);
      generalInfoCSVData.push(["Number of Rounds/Year", getFieldValue(golf_play.numberOfRoundsYear)]);
      generalInfoCSVData.push(["Number of Golf Maintainance Staff", getFieldValue(golf_play.numberOfGolfMaintenanceStaff)]);
      generalInfoCSVData.push(["Open for play year round", getFieldBoolean(golf_play.openForPlayYearRound)]);
      generalInfoCSVData.push(["Length of Golf Season", getFieldValue(golf_play.lengthOfGolfSeason)]);
      generalInfoCSVData.push(["Starting Month", getFieldValue(golf_play.startingMonth)]);
      generalInfoCSVData.push(["Ending Month", getFieldValue(golf_play.endingMonth)]);
      generalInfoCSVData.push(["History", ""]);
      generalInfoCSVData.push(["What year was the course built?", getFieldValue(historyInfo.whatYearWasTheCourseBuilt)]);
      generalInfoCSVData.push(["Former land use", getFieldValue(historyInfo.formerLandUse)]);
      generalInfoCSVData.push(["Number of years under former land use", getFieldValue(historyInfo.numberOfYearsFormerLandUse)]);
      generalInfoCSVData.push(["Year(s) of renovation", getFieldValue(historyInfo.yearsOfRenovation)]);
      generalInfoCSVData.push(["Describe what was done during renovations", getFieldValue(historyInfo.whatWasDoneDuringRenovations)]);
      generalInfoCSVData.push(["Setting", "Urban -" + getFieldBoolean(settingData.urban) + ", Suburban -" + getFieldBoolean(settingData.suburban) + ", Rural -" +
        getFieldBoolean(settingData.rural)]);
      generalInfoCSVData.push(["Approximate Elevation", "High -" + getFieldValue(settingData.approximateElevation.approximateElevationHigh) +
        ", Low -" + getFieldValue(settingData.approximateElevation.approximateElevationLow)]);

    }
    return generalInfoCSVData;
  }

  const giHeaderExtra = <>{getFormProgress(audubonDetails.GeneralInformationForm) === STATUS.DONE && <CSVLink data={generateGeneralInfoCSVData()} filename="GeneralInformation.csv"><FileExcelOutlined style={{ fontSize: '150%' }} /> &nbsp; Download</CSVLink>}  &nbsp;&nbsp; {getProgressIcon(getFormProgress(audubonDetails.GeneralInformationForm), 24)}</>;

  return (
    <Card title={cardTitle} >
      <div>
        {isLoading && (<Spin tip='Loading Organization Data...' />)}
        {!isLoading && audubonDetails && (<div>

          <Collapse accordion>
            <Panel header='General Information' key='1' extra={giHeaderExtra}>
              {_.isEmpty(generalInformationForm) && emptyContent}
              {!_.isEmpty(generalInformationForm) && (<>

                <Descriptions title='General Details'  {...lblStyle} bordered>
                  <Descriptions.Item label='Course Name' span={3}>{getFieldValue(basicInformation.courseName)}</Descriptions.Item>
                  <Descriptions.Item label='Address' span={3}>{getFieldValue(basicInformation.address)}</Descriptions.Item>
                  <Descriptions.Item label='City'>{getFieldValue(basicInformation.city)}</Descriptions.Item>
                  <Descriptions.Item label='State / Province'>{getFieldValue(basicInformation.stateProvince)}</Descriptions.Item>
                  <Descriptions.Item label='Zip Code'>{getFieldValue(basicInformation.zipCode)}</Descriptions.Item>
                  <Descriptions.Item label='Country'>{getFieldValue(basicInformation.country)}</Descriptions.Item>
                  <Descriptions.Item label='Country / Municipality'>{getFieldValue(basicInformation.countyMunicipality)}</Descriptions.Item>
                  <Descriptions.Item label='Main Contact Name / Title'>{getFieldValue(basicInformation.mainContactNameTitle)}</Descriptions.Item>
                </Descriptions>

                <Descriptions title='Contact Details'  {...lblStyle} bordered style={{ paddingTop: 24 }}>
                  <Descriptions.Item label='Telephone' >{getFieldValue(basicInformation.telephone)}</Descriptions.Item>
                  <Descriptions.Item label='E-mail Address' >{getFieldValue(basicInformation.eMailAddress)}</Descriptions.Item>
                  <Descriptions.Item label='Mobile Number'>{getFieldValue(basicInformation.cellNumber)}</Descriptions.Item>
                  <Descriptions.Item label='Web Address'>{getFieldValue(basicInformation.webSiteAddress)}</Descriptions.Item>
                  <Descriptions.Item label='Superintendent'>{getFieldValue(basicInformation.superintendent)}</Descriptions.Item>
                  <Descriptions.Item label='General Manager'>{getFieldValue(basicInformation.generalManager)}</Descriptions.Item>
                  <Descriptions.Item label='Management Company'>{getFieldValue(basicInformation.managementCompany)}</Descriptions.Item>
                </Descriptions>

                <Descriptions title='Mailing Address (if different)'  {...lblStyle} bordered style={{ paddingTop: 24 }}>
                  <Descriptions.Item label='Street Address / PO Box' span={3}>{getFieldValue(basicInformation && basicInformation.mailingAddress ? basicInformation.mailingAddress.mailingStreetAddress : '')}</Descriptions.Item>
                  <Descriptions.Item label='City'>{getFieldValue(basicInformation && basicInformation.mailingAddress ? basicInformation.mailingAddress.mailingCity : '')}</Descriptions.Item>
                  <Descriptions.Item label='State / Province'>{getFieldValue(basicInformation && basicInformation.mailingAddress ? basicInformation.mailingAddress.mailingState : '')}</Descriptions.Item>
                  <Descriptions.Item label='Zip Code'>{getFieldValue(basicInformation && basicInformation.mailingAddress ? basicInformation.mailingAddress.mailingZip : '')}</Descriptions.Item>
                </Descriptions>

                <Descriptions title='Type of Course'  {...lblStyle} bordered style={{ paddingTop: 24 }}>
                  <Descriptions.Item label='Private' >{getFieldBoolean(courses.private)}</Descriptions.Item>
                  <Descriptions.Item label='Semi-Private' >{getFieldBoolean(courses.semiPrivate)}</Descriptions.Item>
                  <Descriptions.Item label='Daily Fee'>{getFieldBoolean(courses.dailyFee)}</Descriptions.Item>
                  <Descriptions.Item label='Municipal'>{getFieldBoolean(courses.municipal)}</Descriptions.Item>
                  <Descriptions.Item label='Resort'>{getFieldBoolean(courses.resort)}</Descriptions.Item>
                  <Descriptions.Item label='Golf/Residential Community'>{getFieldBoolean(courses.golfResidentialCommunity)}</Descriptions.Item>
                  <Descriptions.Item label='Other'>{getFieldBoolean(courses.other)}</Descriptions.Item>
                </Descriptions>

                <Descriptions title='Golf Play'  {...lblStyle} bordered style={{ paddingTop: 24 }}>
                  <Descriptions.Item label='Number of Holes' >{getFieldValue(golf_play.numberOfHoles)}</Descriptions.Item>
                  <Descriptions.Item label='No of Members' >{getFieldValue(golf_play.numberOfMembers)}</Descriptions.Item>
                  <Descriptions.Item label='Number of Rounds/Year'>{getFieldValue(golf_play.numberOfRoundsYear)}</Descriptions.Item>
                  <Descriptions.Item label='Number of Golf Maintainance Staff'>{getFieldValue(golf_play.numberOfGolfMaintenanceStaff)}</Descriptions.Item>
                  <Descriptions.Item label='Open for play year round'>{getFieldBoolean(golf_play.openForPlayYearRound)}</Descriptions.Item>
                  <Descriptions.Item label='Length of Golf Season'>{getFieldValue(golf_play.lengthOfGolfSeason)}</Descriptions.Item>
                  <Descriptions.Item label='Starting Month'>{getFieldValue(golf_play.startingMonth)}</Descriptions.Item>
                  <Descriptions.Item label='Ending Month'>{getFieldValue(golf_play.endingMonth)}</Descriptions.Item>
                </Descriptions>

                <Descriptions title='History'  {...lblStyle} bordered style={{ paddingTop: 24 }}>
                  <Descriptions.Item label='What year was the course built?' >{getFieldValue(historyInfo.whatYearWasTheCourseBuilt)}</Descriptions.Item>
                  <Descriptions.Item label='Former land use' >{getFieldValue(historyInfo.formerLandUse)}</Descriptions.Item>
                  <Descriptions.Item label='Number of years under former land use'>{getFieldValue(historyInfo.numberOfYearsFormerLandUse)}</Descriptions.Item>
                  <Descriptions.Item label='Year(s) of renovation'>{getFieldValue(historyInfo.yearsOfRenovation)}</Descriptions.Item>
                  <Descriptions.Item label='Describe what was done during renovations'>{getFieldValue(historyInfo.whatWasDoneDuringRenovations)}</Descriptions.Item>
                </Descriptions>

                <Descriptions title='Setting'  {...lblStyle} bordered style={{ paddingTop: 24 }}>
                  <Descriptions.Item label='Urban' >{getFieldBoolean(settingData.urban)}</Descriptions.Item>
                  <Descriptions.Item label='Suburban' >{getFieldBoolean(settingData.suburban)}</Descriptions.Item>
                  <Descriptions.Item label='Rural'>{getFieldBoolean(settingData.rural)}</Descriptions.Item>
                  <Descriptions.Item label='Approximate Elevation'> High - {getFieldValue(settingData.approximateElevation.approximateElevationHigh)}
                    &nbsp;&nbsp;<br /> Low - {getFieldValue(settingData.approximateElevation.approximateElevationLow)} </Descriptions.Item>
                </Descriptions></>)
              }
            </Panel>
          </Collapse>
        </div>)}
        {!isLoading && !audubonDetails && (<div><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>)}
      </div>
    </Card>
  )
}