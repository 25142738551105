import React, { useEffect, useState } from 'react';

import { Modal, Button, Spin } from 'antd';

import html2pdf from 'html2pdf.js';


export const RecordViewerModal = (props) => {

    const [report, setReport] = useState();
    const [isDownloadShow, setIsDownloadShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [reportName, setReportName] = useState();

    useEffect(() => {
        setReport(props.reportData);
        setIsDownloadShow(true);
        setIsLoading(props.isLoading);
        setReportName(props.reportName + '.pdf');
    }, [props]);

    const downloadDocument = () => {
        // window.scrollTo(0, 0)
        var opt = {
            margin: 1,
            filename: reportName,
            html2canvas: { scale: 2, dpi: 300, letterRendering: true, useCORS: true },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
            pagebreak: { mode: 'avoid-all' }
        };

        html2pdf()
            .from(document.getElementById('section-to-print'))
            .set(opt)
            .save();

    }

    const renderPrintButton = () => {

        return (
            <div>
                {isDownloadShow && (
                    <Button
                        data-testid={`download-document`}
                        type="primary"
                        onClick={() => downloadDocument()}
                    >
                        Download
                    </Button>
                )}
            </div>
        );
    }

    return (
        <Modal
            visible={true}
            footer={null}
            onCancel={props.onClose}
            wrapClassName=''
            maskClosable={false}
            centered
            title= {props.title ?  props.title :'Record View'}
            width='700px'
        >
            {isLoading && (<div className="banner-body">
                        <Spin tip="Loading..." size="small" />
                    </div>)}
            {!isLoading && (<div>
                <div>
                    <div>
                        {renderPrintButton()}
                    </div>
                </div>
                <div>
                    <div style={{ overflowY: 'auto' }} id="section-to-print" dangerouslySetInnerHTML={{ __html: report }}>
                    </div>
                </div>
            </div>) }
            
        </Modal>)
}