import Auth from "@aws-amplify/auth";
import { message } from "antd"

import { ACTIONS } from "../store/session";
import { getApi, APIS } from "../utils/api-utils";

export const isSessionAuthenticated = async (dispatch) => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        await postLogin(user, dispatch, ACTIONS.LOGGED_IN);
        return true;
    } catch (e) {
        console.error(e);
    }
    return false;
}

export const signIn = async (username, password, newPassword, dispatch) => {
    try {
        let user = await Auth.signIn(username, password);
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            if (!newPassword) {
                return ('NEW_PASSWORD_REQUIRED');
            }
            user = await Auth.completeNewPassword(user, newPassword);
        }

        if (!user.signInUserSession.accessToken.payload["cognito:groups"] ||
            (user.signInUserSession.accessToken.payload["cognito:groups"] && !user.signInUserSession.accessToken.payload["cognito:groups"].includes('AudubonAdmin'))) {
            await logout();
            return ('UNAUTHORIZED_USER');
        }

        await postLogin(user, dispatch, ACTIONS.LOGGED_IN);

    } catch (err) {
        console.log('ERR:', err);
        if (err.code === 'UserNotConfirmedException') {
            // The error happens if the user didn't finish the confirmation step when signing up
            // In this case you need to resend the code and confirm the user
            // About how to resend the code and confirm the user, please check the signUp part
        } else if (err.code === 'PasswordResetRequiredException') {
            // The error happens when the password is reset in the Cognito console
            // In this case you need to call forgotPassword to reset the password
            // Please check the Forgot Password part.
        } else if (err.code === 'NotAuthorizedException') {
            // The error happens when the incorrect password is provided
        } else if (err.code === 'UserNotFoundException') {
            // The error happens when the supplied username/email does not exist in the Cognito user pool
        }
        return err.message ? err.message : "Error occured";
    }
}

const postLogin = async (user, dispatch, dispatchType) => {

    let payload = user.getSignInUserSession().getIdToken().payload;

    dispatch({
        type: dispatchType, payload: {
            firstName: payload.name,
            lastName: payload.family_name,
            userId: payload.sub,
            isValidUser: payload["cognito:groups"].includes('AudubonAdmin')
        }
    });

}

export const logout = async () => {
    try {
        await Auth.signOut();
    } catch (err) {
        console.error('ERR:', err);
    }
}

export const sendForogotPasswordCode = async (userName) => {

    try {
        await Auth.forgotPassword(userName);
    } catch (err) {
        console.error('ERR:', err);
        return err.message ? err.message : "Error occured";
    }

};

export const resetNewPassword = async (userName, code, password) => {
    
    try {
        await Auth.forgotPasswordSubmit(userName, code, password);
    } catch (err) {
        console.error('ERR:', err);
        return err.message ? err.message : "Error occured";
    }
};
