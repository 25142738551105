import React from 'react';
import { Descriptions } from 'antd';
import _ from 'lodash';
import { emptyContent, getFieldValue, getFieldValueWithUnit, lblStyle } from '../form-actions/FormActions';

export const IrrigationDetails = ({ irrigationForm }) => {

  return (
    <>
      {_.isEmpty(irrigationForm) ? emptyContent
        : <Descriptions title='Irrigation Details' {...lblStyle} bordered style={{ paddingTop: 24 }} >
          <Descriptions.Item label='What percentage of your irrigation comes from the following sources?' span={3}>Well: {(irrigationForm.well ? irrigationForm.well + '%' : '')}
            <br /> Spring: {getFieldValueWithUnit(irrigationForm.spring, '%')} <br /> River: {getFieldValueWithUnit(irrigationForm.river, '%')}
            <br /> City Water:  {getFieldValueWithUnit(irrigationForm.cityWater, '%')} <br /> Effluent:  {getFieldValueWithUnit(irrigationForm.effluent, '%')}
            <br /> Runoff:  {getFieldValueWithUnit(irrigationForm.runoff, '%')} <br /> Other: {getFieldValueWithUnit(irrigationForm.other, '%')
            }</Descriptions.Item>
          <Descriptions.Item label='Do you store irrigation water in an irrigation pond?'>{getFieldValue(irrigationForm.storeIrrigationWaterInIrrigationPond)}</Descriptions.Item>
          <Descriptions.Item label='How many acres receive irrigation?'>{getFieldValueWithUnit(irrigationForm.receiveIrrigation, ' Acres')}</Descriptions.Item>
        </Descriptions>
      }
    </>
  );
}