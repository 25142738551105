const BASE_API_URL = 'https://www.eparconnectserver.com';
const STAGE = 'prod';

export const AWS_CONGITO_CONFIG = {
    Auth: {
        region: 'us-west-2',
        userPoolId: 'us-west-2_hulGkZArz',
        userPoolWebClientId: '3oqa0mfsauskombgnjlrn4j2cn',
        identityPoolId: 'us-west-2:65eac0d6-d864-4afe-8ca1-dd96e4a0e718'
    }
}

export const AWS_API_CONFIG = {
    baseApiUrls: {
        siteValueAPI:`${BASE_API_URL}/${STAGE}-site-value-api/`,
        adminCommonAPI: `${BASE_API_URL}/${STAGE}-admin-api/${STAGE}/`,
        externalCertificationAPI : `${BASE_API_URL}/${STAGE}-ext-certification-api/`,
        documentAPI : `${BASE_API_URL}/${STAGE}-document-api/${STAGE}/`,
        taskAPI : `${BASE_API_URL}/${STAGE}-task-api/${STAGE}/`,
        inspectionAPI : `${BASE_API_URL}/${STAGE}-inspection-api/${STAGE}/`,
        commentsAPI : `${BASE_API_URL}/${STAGE}-comments-api/${STAGE}/`,
        activitiesAPI : `${BASE_API_URL}/${STAGE}-activities-api/${STAGE}/`
    }
}

export const CONFIG = {
    s3HostUrl: 'https://s3-ap-southeast-2.amazonaws.com',
    externalCertificationDataBucket: `${STAGE}-au-external-certification-data`,
    connectOUCredentialBucket: `${STAGE}-au-connect-ou-credential`,
}