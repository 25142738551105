export const STATUS = {
  TODO: 0,
  DONE: 100,
  INSPECTION_COMPLETE: 25,
  FAILED: -1,
  PENDING_REVIEW: 30,
  PENDING_RESPONSE: 35,
  REWORK_INPROGRESS: 60
};

export const SITE_VALUE_TYPES = [
  { icon: 'air.png', bodyIcon: 'air.svg', key: 'air', value: 'Air', help: 'This will address dust, noise, spray drift, odour management positives' },
  { icon: 'audubon.png', bodyIcon: 'audubon.svg', key: 'audubon', value: 'Audubon', help: 'Audubon' },
  { icon: 'historic.png', bodyIcon: 'historic.svg', key: 'cultural_significance', value: 'Cultural Significance', help: 'Cultural Significance' },
  { icon: 'energy.png', bodyIcon: 'energy.svg', key: 'energy', value: 'Energy', help: 'Consumption and initiatives like solar or wind etc' },
  { icon: 'infrastruct.png', bodyIcon: 'infrastruct.svg', key: 'infrastructure', value: 'Infrastructure', help: 'Concrete contained washbay etc' },
  { icon: 'turf.png', bodyIcon: 'turf.svg', key: 'managed_turf_areas', value: 'Managed Turf Areas', help: 'Greens, Tees, Fairways' },
  { icon: 'vegetation.png', bodyIcon: 'vegetation.svg', key: 'vegetation_and_habitat', value: 'Vegetation and Habitat', help: 'Trees, flora and water bodies on site, riparian zones, wetlands, etc)' },
  { icon: 'waste.png', bodyIcon: 'waste.svg', key: 'waste', value: 'Waste', help: 'Green waste, recycling etc' },
  { icon: 'water.png', bodyIcon: 'water.svg', key: 'water', value: 'Water', help: 'This will cover consumption, conservation, protection initiatives' },
  { icon: 'wildlife.png', bodyIcon: 'wildlife.svg', key: 'wildlife', value: 'Wildlife', help: 'Animals spotted on site' },
  { icon: 'safety.png', bodyIcon: 'safety.svg', key: 'health_and_safety', value: 'Health and Safety', help: 'Health and safety proccedures' },
  { icon: 'development.png', bodyIcon: 'development.svg', key: 'development_and_growth', value: 'Decent Work', help: 'Decent Work and Economic Growth' },
  { icon: 'education.png', bodyIcon: 'education.svg', key: 'education', value: 'Quality Education', help: 'Quality Education' },
  { icon: 'gender_equality.png', bodyIcon: 'gender_equality.svg', key: 'gender_equality', value: 'Gender Equality', help: 'Gender Equality' },
  { icon: 'un_sdg.png', bodyIcon: 'un_sdg.svg', key: 'our_sdg', value: 'Our SDGs', help: 'Our SDGs' },
  { icon: 'partnerships.png', bodyIcon: 'partnerships.svg', key: 'partnerships', value: 'Partnerships', help: 'Partnerships for the Goals' },
  { icon: 'reduce_inequality.png', bodyIcon: 'reduce_inequality.svg', key: 'reduce_inequality', value: 'Reduce Inequalities', help: 'Reduce Inequalities' },
];

export const SITE_VALUE_RECORD_TYPE = {
  CONNECT: '0',
  AUDUBON: '1'
}

export const ATTACHMENT_URL_TYPES = {
  THUMBNAIL: '1',
  FILE: '2'
}

export const ARCHIVE_STATE = {
  ARCHIVED: '1',
  NOT_ARCHIVED: '0',
}

export const CERT_ARRAY = [
  {
    id: "ASCP_FOR_GOLF",
    name: "ASCP for Golf"
  },
  // {
  //   id: "CERTIFIED_SUPERINTENDENT",
  //   name: "Certified Superintendent"
  // },
  // {
  //   id: "CLASSIC_SANCTUARY",
  //   name: "Classic Sanctuary"
  // },
  // {
  //   id: "SIGNATURE_SANCTUARY",
  //   name: "Signature Sanctuary"
  // },
  // {
  //   id: "GREEN_HOSPITALITY",
  //   name: "Green Hospitality"
  // },
  // {
  //   id: "GREEN_LODGING",
  //   name: "Green Lodging"
  // },
  // {
  //   id: "SUSTAINABLE_COMMUNITIES",
  //   name: "Sustainable Communities"
  // }
]

export const QUALIFICATION_VALID_PERIODS = [
  {
    id: 1,
    name: "1 year",
    months: 12
  },
  {
    id: 2,
    name: "2 years",
    months: 24
  },
  {
    id: 3,
    name: "3 years",
    months: 36
  }
]

export const ORG_DETAILS_SECTIONS = {
  GENERAL_INFORMATION: "GeneralInformationForm",
  MANAGEMENT_CONTEXT: "ManagementContextForm",
  IRRIGATION: "IrrigationForm",
  ENVIRONMENTAL_CONTEXT: "EnvironmentalContextForm",
  LANDSCAPE_INFO: "LandscapeInfoForm",
  PLANT_AND_WILD_LIFE: "PlantAndWildlifeForm",
  SUPPLEMENTAL_STRUCTURES: "SupplementalStructuresForm",
  OUTREARCH_EDUCATION: "OutreachEducationForm",
  LOCAL_RESOURCES: "LocalResourcesForm",
  ADDITIONAL_INFO: "AdditionalInfoForm"
};


export const ORG_DETAILS_PROGRESS_STATE = {
  COMPLETE: 'COMPLETE',
  INPROGRESS: 'INPROGRESS',
}

export const USER_TYPE = {
  AUDUBON: 'AUDUBON',
  CONNECT: 'CONNECT',
}

export const TASK_TYPE = {
  DOCUMENT: 'DOCUMENT',
  CERTIFICATION: 'CERTIFICATION'
}

export const STEPS_BAR = [
  {
    key: 0,
    title: 'Welcome',
    subTitle: 'sub title'
  },
  {
    key: 1,
    title: 'Complete Org Details and SAEP',
    subTitle: 'sub title'
  },
  {
    key: 2,
    title: 'Org Details and SAEP Review',
    subTitle: 'sub title'
  },
  {
    key: 3,
    title: 'Complete Certification Requirements',
    subTitle: 'sub title'
  },
  {
    key: 4,
    title: 'Certificate Review ',
    subTitle: 'sub title'
  },
  {
    key: 5,
    title: 'Site Visit',
    subTitle: 'sub title'
  },
  {
    key: 6,
    title: 'Certified',
    subTitle: 'sub title'
  },
]

export const CERTIFICATION_STATUS = {
  0: 0,
  10: 1,
  20: 2,
  50: 3,
  75: 4,
  85: 5,
  100: 6
}

export const CERTIFICATION_STATUS_BY_NAME = {
  START: 0,
  PREM_DATA_SUBMISSION: 10,
  PREM_DATA_REVIEW: 20,
  CERT_DATA_SUBMISSION: 50,
  CERT_DATA_REVIEW: 75,
  SITE_VISIT: 85,
  CERTIFIED: 100
}

export const CERTIFICATION_TYPE = 'AUDUBON';

export const SITE_ASSESSMENT_ORG_DETAILS_SECTIONS = {
  MANAGEMENT_CONTEXT: "ManagementContextForm",
  IRRIGATION: "IrrigationForm",
  ENVIRONMENTAL_CONTEXT: "EnvironmentalContextForm",
  LANDSCAPE_INFO: "LandscapeInfoForm",
  PLANT_AND_WILD_LIFE: "PlantAndWildlifeForm",
  SUPPLEMENTAL_STRUCTURES: "SupplementalStructuresForm",
  OUTREARCH_EDUCATION: "OutreachEducationForm",
  LOCAL_RESOURCES: "LocalResourcesForm",
  ADDITIONAL_INFO: "AdditionalInfoForm"
};

export const QUESTIONS_CATEGORY = 'withCategories';

export const DOC_TYPE = {
  CERTIFICATION : 0,
  PRELIMINARY : 1
};