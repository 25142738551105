import { notification, Spin } from 'antd';
import { SmileTwoTone, FrownTwoTone, LoadingOutlined } from '@ant-design/icons';

export const notifyError = (title, key = undefined) => {
  notification.error({
    message: title,
    icon: <FrownTwoTone twoToneColor="#f94a4d" />,
    key: key
  });
};

export const notifyWarning = (message, duration = undefined) => {
  notification.warning({
    message: message,
    icon: <FrownTwoTone twoToneColor="#F6C627" />,
    duration: duration
  });
}

export const notifySuccess = (title, key = undefined) => {
  notification.success({
    message: title,
    icon: <SmileTwoTone twoToneColor="#52c41a" />,
    key: key
  });
};

export const notifyInProgress = (title, key = undefined, duration = 0) => {
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
  notification.open({
    message: title,
    icon: <Spin indicator={loadingIcon} />,
    key: key,
    duration: duration
  });
};

export const notifiableAPICall = async (fn, key, inProgressMessage, successMessage, errorMessage, errorThrowable = false) => {
  try {
    notifyInProgress(inProgressMessage, key);
    const res = await fn();
    notification.close(key);
    notifySuccess(successMessage, key);
    return res;
  } catch (e) {
    console.error(e);
    notification.close(key);
    notifyError(errorMessage, key);
    if (errorThrowable) throw e;
  }
}



