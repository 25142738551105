import React from 'react';
import { Descriptions } from 'antd';
import _ from 'lodash';
import { emptyContent, getFieldValue, lblStyle } from '../form-actions/FormActions';

export const LandscapeInformation = ({ landscapeInfoForm }) => {
  const { turfSurfaces, gardens, naturalPlantCommunities, pondsAndLakes, streamsAndRivers, otherLandUsage, totalPropertyAcres } = landscapeInfoForm ? landscapeInfoForm : {};

  return (
    <>
      {_.isEmpty(landscapeInfoForm) ? emptyContent
        : <>
          <Descriptions title={`Turf Surfaces ( Total : ${getFieldValue(turfSurfaces ? turfSurfaces.totalAcres : '')}acres )`}  {...lblStyle} bordered style={{ paddingTop: 24 }} column={2}>
            {turfSurfaces && turfSurfaces.tableData && turfSurfaces.tableData.map(turf => (
              <Descriptions.Item label={turf.topic} >
                Turf Type : {getFieldValue(turf.turfTypes)}<br />
                Mowing Heights : {getFieldValue(turf.mowingHeights ? turf.mowingHeights.low : '')} - {getFieldValue(turf.mowingHeights ? turf.mowingHeights.high : '')}<br />
                Acers : {getFieldValue(turf.acres)}<br />
              </Descriptions.Item>
            ))
            }
          </Descriptions>
          <Descriptions title={`Gardens ( Total : ${getFieldValue(gardens ? gardens.totalAcres : '')}ft² )`}  {...lblStyle} bordered style={{ paddingTop: 24 }} column={2}>
            {gardens && gardens.tableData && gardens.tableData.map(garden => (
              <Descriptions.Item label={garden.topic} >
                Number : {getFieldValue(garden.number)} <br />
                Total Size (~ft<sup>2</sup>) : {getFieldValue(garden.totalSize)}<br />
              </Descriptions.Item>
            ))
            }
          </Descriptions>
          <Descriptions title={`Natural Plant Communities ( Total : ${getFieldValue(naturalPlantCommunities ? naturalPlantCommunities.totalAcres : '')}acres )`}  {...lblStyle} bordered style={{ paddingTop: 24 }} column={2}>
            {naturalPlantCommunities && naturalPlantCommunities.tableData && naturalPlantCommunities.tableData.map(plant => (
              <Descriptions.Item label={plant.topic}>
                Total Acres : {getFieldValue(plant.naturalPlantTotalAcres)}
              </Descriptions.Item>
            ))
            }
          </Descriptions>
          <Descriptions title={`Ponds and Lakes ( Total : ${getFieldValue(pondsAndLakes ? pondsAndLakes.totalAcres : '')}acres )`} {...lblStyle} bordered style={{ paddingTop: 24 }} column={2}>
            {pondsAndLakes && pondsAndLakes.tableData && pondsAndLakes.tableData.map(pAl => (
              <Descriptions.Item label='1/2 - 1 acre' >
                Qty : {getFieldValue(pAl.qty)} <br />
                %Turfgrass Shoreline : {getFieldValue(pAl.turfGrassShoreline)}<br />
                %Natural Shoreline : {getFieldValue(pAl.naturalShoreline)}<br />
                %Shallow Water Areas{"( < 2'deep)"} : {getFieldValue(pAl.shallowWaterAreas)}<br />
                %with Aquatic Plants : {getFieldValue(pAl.withAquaticPlants)}<br />
                Total Accres : {getFieldValue(pAl.totalAcres)}<br />
              </Descriptions.Item>
            ))
            }
          </Descriptions>
          <Descriptions title='Streams and Rivers'  {...lblStyle} bordered style={{ paddingTop: 24 }} column={2}>
            {streamsAndRivers && streamsAndRivers.tableData && streamsAndRivers.tableData.map(stream => (
              <Descriptions.Item label={stream.topic} >
                Qty : {getFieldValue(stream.qty)} <br />
                Average Width : {getFieldValue(stream.averageWidth)}<br />
                Average Depth : {getFieldValue(stream.averageDepth)}<br />
                Length : {getFieldValue(stream.length)}<br />
                %Natural Shoreline : {getFieldValue(stream.naturalShoreline)}<br />
                Ripples : {getFieldValue(stream.ripples)}<br />
              </Descriptions.Item>
            ))
            }
          </Descriptions>
          <Descriptions title={`Other ( Total : ${getFieldValue(otherLandUsage ? otherLandUsage.totalAcres : '')}acres )`}  {...lblStyle} bordered style={{ paddingTop: 24 }}>
            <Descriptions.Item label='Buildings, roads, parking lots, tennis courts, swimming pool, etc : ' >
              Total Accres : {getFieldValue(otherLandUsage ? otherLandUsage.totalAcres : '')}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions title={`Total Property Acres : ${totalPropertyAcres && totalPropertyAcres.totalAcres ? parseFloat(getFieldValue(totalPropertyAcres.totalAcres)).toFixed(2) : 0}acres`}  {...lblStyle} bordered style={{ paddingTop: 24 }}></Descriptions>
        </>
      }
    </>
  )
}