import React, { useState } from 'react';
import { Modal, Button, Form, Row, Spin, Col } from 'antd';
import moment from 'moment';
import { DownloadOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { CONFIG } from '../../src/config/app-config';
import { openFile } from '../utils/doc-utils';

export const IssuedCertificateDetailsModal = ({ certificationDetails, onClose }) => {
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const getCertificationStatus = () => {
    if (moment(certificationDetails.expire_date).isAfter(moment())) {
      return <span style={{ color: '#3db39e' }}>{'Certified'}</span>;
    }
    else {
      return <span style={{ color: '#d82222' }}>{'Expired'}</span>;
    }
  }

  const downloadCertificate = async () => {
    if (_.isEmpty(certificationDetails.s3_file_key, true)) return
    setIsBtnLoading(true);
    openFile(`${CONFIG.s3HostUrl}/${CONFIG.connectOUCredentialBucket}/${certificationDetails.s3_file_key}`)
    setIsBtnLoading(false);
  }

  return (
    <Modal
      visible={true}
      onCancel={onClose}
      wrapClassName=''
      maskClosable={false}
      centered
      title={'Certification Details'}
      width='500px'
      footer={null}
    >
      {certificationDetails && <div>
        <Form.Item >
          <Row>
            <Col span={8}>Credential Status</Col>
            <Col span={16}>: &nbsp;{certificationDetails.expire_date && getCertificationStatus()}</Col>
          </Row>
        </Form.Item>
        <Form.Item >
          <Row>
            <Col span={8}>Expiry Date</Col>
            <Col span={16}>: &nbsp;{certificationDetails.expire_date ? moment(certificationDetails.expire_date).format("DD/MM/YYYY") : ''}</Col>
          </Row>
        </Form.Item>
        <Form.Item >
          <Button icon={isBtnLoading ? (<Spin size="small" />) : <DownloadOutlined />} className="btn btn--primary" data-testid={`download-certificate`} onClick={downloadCertificate}>
            &nbsp;View Credential
          </Button>
        </Form.Item>
      </div>}
    </Modal>
  )
}