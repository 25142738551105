import React, { useState } from 'react';
import { Modal, Button, Form, message, Input, Row, Col, Spin } from 'antd';
import { addMembershipDetials } from './actions'
import { CERTIFICATION_TYPE } from '../utils/constants';
import { ulid } from 'ulid';

const MODE = { ADD: 'ADD', UPDATE: 'UPDATE' };
const FormItem = Form.Item;

export const AudubonDetails = ({ orgId, orgUnitId, onClose, onAudubonDetailsUpdate, audubonDetails }) => {

    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [mode] = useState(audubonDetails.length === 0 ? MODE.ADD : MODE.UPDATE);

    const onSubmitClicked = async () => {
        try {
            setIsLoading(true);
            const values = await form.validateFields();

            const data = {
                member_id: values.membershipId,
                certification_type: CERTIFICATION_TYPE,
                ou_id: orgUnitId,
                org_id: orgId,
                contact_details: {
                    contactName: values.contactName,
                    contactNumber: values.contactNumber,
                    contactEmail: values.contactEmail,
                    jobTitle: values.jobTitle
                }
            };
            if (mode === MODE.ADD) data.ou_cert_member_id = ulid();
            await addMembershipDetials(orgId, data);

            message.success(mode === MODE.ADD ? "Membership details added successfully" : "Membership details updated successfully");
            onAudubonDetailsUpdate(values.membershipId);
            setIsLoading(false);
            onClose();
        } catch (e) {
            if (e.errorFields) {
                return;
            }
            console.error(e);
            setIsLoading(false);
            message.error(mode === MODE.ADD ? "Error while adding Membership details" : "Error while updating Membership details");
        }
    }

    return (
        <Modal
            visible={true}
            footer={null}
            onCancel={onClose}
            wrapClassName=''
            maskClosable={false}
            centered
            title='Membership Details'
            width='500px'
        >

            <div>
                <Form
                    layout="vertical"
                    form={form}
                    initialValues={mode === MODE.ADD ? {} :
                        {
                            membershipId: audubonDetails[0].member_id,
                            contactName: audubonDetails[0].contact_details.contactName,
                            contactNumber: audubonDetails[0].contact_details.contactNumber,
                            contactEmail: audubonDetails[0].contact_details.contactEmail,
                            jobTitle: audubonDetails[0].contact_details.jobTitle,
                        }}
                >

                    <FormItem
                        label="Member Number"
                        name="membershipId"
                    >
                        <Input />
                    </FormItem>

                    <Row><Col><b>Membership Contact Details </b></Col></Row>
                    <br />
                    <FormItem
                        label="Name"
                        name="contactName"
                    >
                        <Input />
                    </FormItem>
                    <FormItem
                        label="Contact Number"
                        name="contactNumber"
                    >
                        <Input />
                    </FormItem>
                    <FormItem
                        label="Email"
                        name="contactEmail"
                    >
                        <Input />
                    </FormItem>
                    <FormItem
                        label="Job Title"
                        name="jobTitle"
                    >
                        <Input />
                    </FormItem>
                    <FormItem>
                        <Button data-testid={`update-membership-details`} className="btn btn--primary" onClick={onSubmitClicked} icon={
                            isLoading ? (
                                <Spin size="small" />
                            ) : null
                        }> &nbsp; {mode === MODE.UPDATE ? `Update Membership Details` : 'Add New Membership Details'}</Button>
                    </FormItem>
                </Form>
            </div>
        </Modal>)
}