import { Tag } from 'antd'
import moment from 'moment'
import { isMobile as isMobileBrowser } from "react-device-detect";

import { STATUS, ORG_DETAILS_SECTIONS, ORG_DETAILS_PROGRESS_STATE } from './constants'

export const getUserObj = (loggedUser) => {
    return {
        name: loggedUser.firstName + " " + loggedUser.lastName,
        id: loggedUser.userId
    }
}

export const getTag = (status) => {
    var tag = null;
    switch (status) {
        case STATUS.DONE:
        case STATUS.INSPECTION_COMPLETE:
            tag = <Tag color="success">Complete</Tag>;
            break;
        case STATUS.TODO:
            tag = <Tag color="default">To do</Tag>;
            break;
        case STATUS.FAILED:
            tag = <Tag color='error'>Failed</Tag>;
            break;
        case STATUS.PENDING_REVIEW:
            tag = <Tag color='orange'>Pending Review</Tag>;
            break;
        case STATUS.PENDING_RESPONSE:
            tag = <Tag color='purple'>Pending Response</Tag>;
            break;
        case STATUS.REWORK_INPROGRESS:
            tag = <Tag color='blue'>Rework in progress</Tag>;
            break;
        default:
            tag = <Tag color='processing'>In progress</Tag>;
    }
    return tag;
}

export const isMobile = () => {
    return isMobileBrowser;
}

const findAncestor = (orgUnits, orgunit) => {
    const parent = orgUnits.find(o => o.id === orgunit.parentId);
    if (parent) {
        return parent;
    }
    return null;
}

const buildSubTree = (orgUnits, parent, ous) => {
    const children = orgUnits.filter(o => o.parentId === parent.id);
    for (let o of children) {
        o.disabled = o.isAudubonEnable === 0;
        const ancestor = findAncestor(orgUnits, o);
        if (ancestor) {
            ancestor.children.push(o);
        } else {
            ous.push(o);
        }
        buildSubTree(orgUnits, o, ous);
    }
}

export const buildOUTree = (orgunits, userOuIds, orgId) => {
    const root = orgunits.find(o => o.parentId === orgId);
    root.disabled = root.isAudubonEnable === 0;
    const ous = [root];
    buildSubTree(orgunits, root, userOuIds, ous);
    return ous;
}

export const formatDateTimeToDisplay = (date, format = 'YYYY-MM-DD HH:mm A') => {
    return moment(date).format(format);
}

export const formatDateToDisplay = (dateString) => (dateString) ? moment(dateString).format('MMM DD, YYYY') : '';

export const resolveTemplate = async (url) => {
    try {
        const res = await fetch(url);
        const template = await res.text();
        return template;
    } catch (e) {
        console.log(e);
    }
}

export const getProgress = (tasks) => {
    const completedTask = tasks.filter(x => x.status === STATUS.DONE || x.status === STATUS.INSPECTION_COMPLETE).length;
    const allTasks = tasks.length;
    return allTasks > 0 ? (100 * completedTask / allTasks) : 0;
}

export const getProgressIcon = (progress, size) => {
    
    let width, height = 32;
    if(size){
        width  = size;
        height = size;
    }

    switch(progress){
        case 100:
            return <img alt="Done" src="/icons/done.png"  width={width} height={height}/>;
        case 0:
        case undefined:
            return <img alt="Not Started" src="/icons/not-started.png" width={width} height={height}/>;
        case -1:
            return <img alt="Failed" src="/icons/rejected.png" width={width} height={height} />;
        default:
            return <img alt="In progress" src="/icons/in-progress.png" width={width} height={height}/>;
    }
}

export  const getOrgInfoCompletedTasks = (orgDetails) => {

    let orgDetailsCompletedTasks = 0;
    for (var prop in orgDetails) {
        if (Object.prototype.hasOwnProperty.call(orgDetails, prop)) {
            if(orgDetails[prop].Status === STATUS.DONE) orgDetailsCompletedTasks++;
        }
    }
    
    return orgDetailsCompletedTasks;
}

export const getFileExtension = (filename) => {
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
}

const extensions = new Set([
	'jpeg',
	'jpg',
	'png',
	'svg',
    'jfif'
]);


export const isImage = (filename) => {
   return extensions.has(/[^.]+$/.exec(filename)[0].toLowerCase()); 
}

export const viewQuestions = (field) => {
    if (field.questionId && field.question) {
        return `${field.questionId}. ${field.question}`;
    } else if (!field.questionId && field.question) {
        return field.question;
    } else {
        return null;
    }
}