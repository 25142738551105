import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'antd';
import { getProgressIcon } from '../utils/common-utils'
import { CertificationRecods } from './certifications/CertificationRecods';
import { STATUS } from '../utils/constants';

export const AudubonCertificationListView = (props) => {

    const [inspectionList, setInspectionList] = useState([]);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (props.data) {
            let templateList = props.data.tasks.filter(temp => temp.is_preliminary === 0);
            let assessment = [];
            templateList.map(temp => {
                let assignee = props.data.records.filter(record => record.template_id === temp.template_id);
                assessment.push(...assignee);
            });
            let sortedInspectionList = assessment ? assessment.sort((a, b) => a.name.localeCompare(b.name)) : [];
            setInspectionList(sortedInspectionList);
            setCertProgress(templateList, assessment);
        }
    }, [props]);

    const setCertProgress = (templateList, assessment) => {
        if (inspectionList) {
            let totalTasks = templateList.length;
            let totalRecords = assessment.length;
            let completedRecords = assessment.filter(record => record.status === STATUS.DONE).length;
            let inprogressRecords = assessment.filter(record => record.status > STATUS.TODO && record.status !== STATUS.DONE).length;
            let assignedTasks = 0;
            templateList.map(temp => {
                if (assessment.find(record => record.template_id === temp.template_id)) ++assignedTasks;
            });
            const inspectionsPercent = ((completedRecords + (inprogressRecords * 0.5)) * assignedTasks) / (totalRecords * totalTasks);
            setProgress(inspectionsPercent * 100);
        } else {
            setProgress(0);
        }
    }

    const cardTitle = <Row><Col span={12}>Certification Records</Col><Col span={12} style={{ textAlign: 'right' }}>{getProgressIcon(progress)}</Col></Row>;

    return (
        <Card title={cardTitle}>
            <CertificationRecods orgId={props.orgId} orgUnitId={props.orgUnitId} certType={props.certType} data={inspectionList} orgName={props.orgName} updateCertList={props.updateCertList} />
        </Card>
    )
}