import _ from 'lodash';
import { Empty } from 'antd';

export const getFormProgress = (form) => {
  let formProgress = _.isEmpty(form) ? 0 : form.Status;
  return formProgress;
}

export const emptyContent = <Empty description="No Data Added" />

export const getFieldValue = (field) => field ? field : '';
export const getFieldBoolean = (field) => field ? 'Yes' : 'No';
export const getFieldValueWithUnit = (field, unit) => field ? field + ' ' + unit : '';

export const lblStyle = { labelStyle: { backgroundColor: '#E0EBF5', fontWeight: '500' } };
export const lblStyleLengthyDesc = { labelStyle: { backgroundColor: '#E0EBF5', fontWeight: '500', width: '40%' } };
export const lblStyleMidLengthDesc = { labelStyle: { backgroundColor: '#E0EBF5', fontWeight: '500', width: '50%' } };
export const lblStyleShortLengthLabels = { labelStyle: { backgroundColor: '#E0EBF5', fontWeight: '500', width: '25%' } };