import { message } from 'antd'
import { getApi, postApi, putApi, APIS } from "../utils/api-utils";
import { getUserObj } from '../utils/common-utils';
import { SITE_VALUE_RECORD_TYPE, ATTACHMENT_URL_TYPES, USER_TYPE, STATUS, TASK_TYPE } from '../utils/constants'

export const getSiteValueLocations = async (orgId, orgUnitId, siteValueType = SITE_VALUE_RECORD_TYPE.AUDUBON, siteValueCategory) => {
    try {
        const result = await getApi(APIS.SITE_VALUE_API, `orgs/${orgId}/site_value/locations/${orgUnitId}`, { queryStringParameters: { type: siteValueType, catagory: siteValueCategory } });
        if (result.error) {
            throw result.error;
        }
        return result.data;
    } catch (e) {
        console.error(e);
        message.error('Error in getting site value map details. Please retry.');
    }
}

export const getOrgsList = async () => {
    const orgsList = await getApi(APIS.CONNECT_ADMIN_API, `org_list/AUDUBON`);
    const activeOrgsList = orgsList.data.filter(x => x.status === 1);
    return activeOrgsList;
}

export const getOrgUnitsList = async (orgId) => {
    const orgUnitsList = await getApi(APIS.CONNECT_ADMIN_API, `/orgs/${orgId}/orgunits`);
    const formattedOUs = orgUnitsList.map(i => {
        return {
            id: i.org_unit_id,
            key: i.org_unit_id,
            value: i.org_unit_id,
            title: i.name,
            children: [],
            parentId: ((i.parent_org_unit_id) && (i.parent_org_unit_id !== '')) ? i.parent_org_unit_id : orgId,
            countryCode: i.country_code,
            isAudubonEnable: i.is_audubon_enabled
        }
    });
    return formattedOUs;
}

export const getAudubonOrgDetails = async (orgId, orgUnitId, certType) => {
    try {
        const audubonOrgDetails = await getApi(APIS.EXTERNAL_CERTIFICATION_API, `orgs/${orgId}/audubon_details/${orgUnitId}`, {queryStringParameters : {certType}});
        if (audubonOrgDetails.error) {
            throw audubonOrgDetails.error;
        }
        // always service reponse return one data element
        return audubonOrgDetails.data;
    } catch (e) {
        console.error(e);
        message.error('Error in getting Audubon organization details. Please retry.');
    }

}

export const getAudubonDocumentDetails = async (orgId, orgUnitId, type) => {
    try {
        const docProgressDetails = await getApi(APIS.EXTERNAL_CERTIFICATION_API, `orgs/${orgId}/doc_progress/${orgUnitId}`, { queryStringParameters: { docType: type } });
        return docProgressDetails.data;
    } catch (e) {
        console.error(e);
        message.error('Error in getting Audubon document progress details. Please retry.');
    }
}

export const getAudubonCertificationDetails = async (orgId, orgUnitId, type) => {
    try {
        const inspectionProgressDetails = await getApi(APIS.EXTERNAL_CERTIFICATION_API, `orgs/${orgId}/inspection_progress/${orgUnitId}`, { queryStringParameters: { templateType: type } });
        return inspectionProgressDetails.data;
    } catch (e) {
        console.error(e);
        message.error('Error in getting Audubon inspection progress details. Please retry.');
    }
}

export const getDocumentReviewRecordURL = async (orgId, taskId) => {
    try {
        const result = await getApi(APIS.DOCUMENT_API, `orgs/${orgId}/docs/review/${taskId}/download`);
        if (result.error) {
            message.error('Error in getting Audubon Document record. Please retry.');
        }
        return result.url;
    } catch (e) {
        console.error(e);
        message.error('Error in getting Audubon Document record. Please retry.');
    }
}

export const getAttachmentSignedUrl = async (orgId, taskId, filename) => {
    try{
        const response = await getApi(APIS.TASK_API, `orgs/${orgId}/task_attachment_url/${ATTACHMENT_URL_TYPES.FILE}/${taskId}`, { queryStringParameters: { fileName: filename, type: 2, isJsonFormat: true } });
        return response;
    } catch (e) {
        console.error(e);
        message.error('Error in getting Audubon Certification record. Please retry.');
    }
    
}

export const getTaskInfor = async (orgId, taskId, isAttachmentSignedURL = false) => {
    try {
        const taskResponse = await getApi(APIS.TASK_API, `orgs/${orgId}/task/task_info/${taskId}`,{queryStringParameters: {
            isSignedURL: isAttachmentSignedURL
        }});
        const taskInfo = taskResponse.data;

        if (taskInfo.error) {
            message.error('Error in getting Certification record. Please retry.');
        }

        if (taskInfo.length > 0) {
            return taskInfo[0];
        } else {
            return null;
        }
    } catch (e) {
        console.error(e);
        message.error('Error in getting Audubon Certification record. Please retry.');
    }
}

export const getCertificationTemplate = async (orgId, templateId) => {
    try {
        // Load template questions
        let inspectionTemplateUrl = `orgs/${orgId}/inspection-template/${templateId}`

        const insTemplateUrlResponse = await getApi(APIS.EXTERNAL_CERTIFICATION_API, inspectionTemplateUrl);
        const inspectionResponse = await fetch(insTemplateUrlResponse.data);
        const inspectionTemplateData = await inspectionResponse.json();
        return inspectionTemplateData
    } catch (e) {
        console.error(e);
        message.error('Error in getting Certification record. Please retry.');
    }
}

export const getSiteValueInfo = async (orgId, ouId, siteValueId, siteValueCategory ) => {
    try {
        const result = await getApi(APIS.SITE_VALUE_API, `orgs/${orgId}/site_value/${siteValueId}`, { queryStringParameters: { ouId: ouId, catagory: siteValueCategory } });
        if (result.error) {
            throw result.error;
        }
        return result.data;
    } catch (e) {
        console.error(e);
        message.error('Error in getting site value map details. Please retry.');
    }
}


export const getComments = async (orgId, taskId) => {
    try {
        const result = await getApi(APIS.COMMENTS_API, `orgs/${orgId}/comments/${taskId}`, { queryStringParameters: { includeUserName: true } });
        if (result.error) {
            throw result.error;
        }
        return result.data;
    } catch (e) {
        console.error(e);
        message.error('Error in getting feedback. Please retry.');
    }
}

export const getCommentsForOUAndTaskType  = async (orgId, ouId, certType, taskType ) => {
    try {
        const result = await getApi(APIS.EXTERNAL_CERTIFICATION_API, `orgs/${orgId}/get-ou-certification-comments/ou/${ouId}/type/${certType}`);
        if (result.error) {
            throw result.error;
        }

        const taskTypeFilteredComments = result.data.filter(item => item.taskType === taskType );
        
        if (taskTypeFilteredComments){
            const result = new Map(taskTypeFilteredComments.map(item => [item.taskId, item.comments]));  
            return result;  
        }

        return new Map();
    } catch (e) {
        console.error(e);
        message.error('Error in getting feedback. Please retry.');
    }
}

export const addComment = async (orgId, ouId, certType, taskId, taskType, comment, user, orgName) => {
    try {
        const commentedUser = getUserObj(user)
        const data = {
            comment: comment,
            taskId: taskId,
            userType: USER_TYPE.AUDUBON,
            taskType: taskType,
            certType: certType,
            orgName: orgName
        };

        const result = await postApi(APIS.EXTERNAL_CERTIFICATION_API, `orgs/${orgId}/certification-comment/ou/${ouId}/user/${commentedUser.id}`, data);
        if (result.error) {
            throw result.error;
        }
        message.success('Feedback added');
    } catch (e) {
        console.error(e);
        message.error('Error in adding a feedback. Please retry.');
    }
}

export const addCertificate = async (orgId, data) => {

    try{
        await postApi(APIS.EXTERNAL_CERTIFICATION_API, `orgs/${orgId}/ou-credential`, data);
    }catch(e){
        console.error(e);
        message.error('Error adding certificate. Please retry.');
    }
    
}

export const updateUserTask = async (orgId, taskId, user, newStatus) => {
    const userObj = getUserObj(user);
    const data = {
        status : newStatus,
        updatedBy : userObj.name,
        updatedById : userObj.id,
        taskId    
    };
    try{
        await putApi(APIS.TASK_API, `orgs/${orgId}/task/${taskId}`, data);
    }catch(e){
        console.error(e);
        message.error('Error completing the certification. Please retry.');
    }  
}

export const getS3SignedURL = async (orgId, key) => {

    try{
        const putURL = await getApi(APIS.EXTERNAL_CERTIFICATION_API, `orgs/${orgId}/ou_credential_put_url/${key}`);
        return putURL;
    }catch(e){
        console.error(e);
        message.error('Error adding certificate. Please retry.');
    }
}

export const getCertDetails = async (orgId, orgUnitId, certType) => {
    try{
        const certDetails = await getApi(APIS.EXTERNAL_CERTIFICATION_API, `orgs/${orgId}/ou-credential/${orgUnitId}/${certType}`);
        if (certDetails.error) {
            throw certDetails.error;
        }
        return certDetails.data;
    }catch(e){
        console.error(e);
        message.error('Error adding certificate. Please retry.');
    }
}

export const getActivities = async (orgId, taskId) => {
    try {
        const result = await getApi(APIS.COMMENTS_API, `orgs/${orgId}/activities/${taskId}`, { queryStringParameters: { includeUserName: true } });
        if (result.error) {
            throw result.error;
        }
        return result.data;
    } catch (e) {
        console.error(e);
        message.error('Error in getting activities. Please retry.');
    }
}

export const updateCertDetails = async (orgId, data) => {
    try{
        const certDetails = await putApi(APIS.EXTERNAL_CERTIFICATION_API, `orgs/${orgId}/ou-credential`, data);
        if (certDetails.error) {
            throw certDetails.error;
        }
        return certDetails;
    }catch(e){
        console.error(e);
        message.error('Error updating ou credentials. Please retry.');
    }
}

export const addMembershipDetials = async (orgId, data) => {
    try{
        await postApi(APIS.EXTERNAL_CERTIFICATION_API, `orgs/${orgId}/member-details`, data);
    }catch(e){
        console.error(e);
        message.error(e.message);
    }
}

export const getMemberDetails = async (orgId, type, ouId = undefined, memberId = undefined ) => {
    try {
        const result = await getApi(APIS.EXTERNAL_CERTIFICATION_API, `orgs/${orgId}/member-details/`, { queryStringParameters: { ouId: ouId, type: type, memberId: memberId }});
        return result.data;
    } catch (e) {
        console.error(e);
        message.error(e.message);
    }
}

export const getAudubonCaseStudy = async (orgId, orgUnitId, type) => {
    try {
        const response = await getApi(APIS.EXTERNAL_CERTIFICATION_API, `orgs/${orgId}/certification_case_study/ou/${orgUnitId}`, { queryStringParameters: { certType: type } });

        if (response.error) {
            throw response.error;
        }
        return response.data && response.data.Items[0] && response.data.Items[0].Details ? response.data.Items[0].Details : [];
    } catch (e) {
        console.error(e);
        message.error('Error in getting Audubon case study details. Please retry.');
    }

}

export const addSiteVisitReport = async (orgId, orgUnitId, data) => {
    try {
        const response = await putApi(APIS.EXTERNAL_CERTIFICATION_API, `orgs/${orgId}/certification_site_vist/ou/${orgUnitId}`,data)
        if (response.error) {
            throw response.error;
        }
        return response;
    } catch (e) {
        console.error(e);
        message.error('Error in adding Audubon site visit report. Please retry.');
    }
}

export const getSiteVisitS3SignedUrl = async (orgId, orgUnitId, fileKey, fileType, isGetUrl) => {
    const param = isGetUrl ? {fileKey, fileType, isGetUrl} : {fileKey, fileType}
    try {
        const response = await getApi(APIS.EXTERNAL_CERTIFICATION_API, `orgs/${orgId}/certification_attachment_url/ou/${orgUnitId}`, {queryStringParameters: param})
        if (response.error) {
            throw response.error;
        }
        return response;
    } catch (e) {
        console.error(e);
        message.error('Error in getting signed Url. Please retry.');
    }
}

export const getSiteVisitDetails = async (orgId, orgUnitId, certType) => {
    try {
        const response = await getApi(APIS.EXTERNAL_CERTIFICATION_API, `orgs/${orgId}/certification_site_vist/ou/${orgUnitId}`, { queryStringParameters: { certType } });
        if (response.error) {
            throw response.error;
        }
        return response.data && response.data.Items[0] ? response.data.Items[0] : [];
    } catch (e) {
        console.error(e);
        message.error('Error in getting Audubon site visit report. Please retry.');
    }
}

export const getAttachmentDownloadSignedUrl = async (orgId, orgUnitId, fileKey, fileType, isGetUrl) => {
    const param = {fileKey, fileType, isGetUrl : true}; 
    try {
        const response = await getApi(APIS.EXTERNAL_CERTIFICATION_API, `orgs/${orgId}/certification_attachment_url/ou/${orgUnitId}`, {queryStringParameters: param})
        if (response.error) {
            throw response.error;
        }
        return response;
    } catch (e) {
        console.error(e);
        message.error('Error in getting signed Url. Please retry.');
    }
}