import React from 'react';
import { Descriptions } from 'antd';
import _ from 'lodash';
import { emptyContent, getFieldValue, lblStyle, getFieldBoolean } from '../form-actions/FormActions';

export const OutreachEducation = ({ outreachEducationForm }) => {

  return (
    <>
      {_.isEmpty(outreachEducationForm) && emptyContent}
      {!_.isEmpty(outreachEducationForm) &&
        <Descriptions title='Outreach & Education Details' {...lblStyle} bordered style={{ paddingTop: 24 }} >
          <Descriptions.Item label='Which of the following educational projects are currently in place?' span={3} labelStyle={{ width: '50%' }}>
            No projects at present : {getFieldBoolean(outreachEducationForm.noProjectsAtPresent)} <br />
            Display(s) of ACSP Membership or Environmental Activities : {getFieldBoolean(outreachEducationForm.displayASCPMembershipOrEnvActivities)} <br />
            Butterfly/Hummingbird/Display Garden(s) : {getFieldBoolean(outreachEducationForm.natureTrails)} <br />
            Nature Trail(s) : {getFieldBoolean(outreachEducationForm.natureTrailsCheckBox)} <br />
            Educational Sign(s) : {getFieldBoolean(outreachEducationForm.educationalSigns)} <br />
            Brochure(s) : {getFieldBoolean(outreachEducationForm.brochures)} <br />
            Volunteer Opportunities : {getFieldBoolean(outreachEducationForm.volunteerOpportunities)} <br />
            Newsletter Articles : {getFieldBoolean(outreachEducationForm.newsletterArticles)} <br />
            Other : {getFieldBoolean(outreachEducationForm.other)}
          </Descriptions.Item>
          <Descriptions.Item label='Total number of community organizations/experts involved in your environmental or outreach activities:'>{getFieldValue(outreachEducationForm.totalInvolvingResources)}</Descriptions.Item>
        </Descriptions>
      }
    </>
  )
}