import React, { useEffect, useState } from 'react';

import { Modal, Button, Form, Select, message, Upload, Input, Space } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Auth from "@aws-amplify/auth";
import { ulid } from 'ulid';

import { CERTIFICATION_STATUS_BY_NAME, CERT_ARRAY, QUALIFICATION_VALID_PERIODS } from '../utils/constants'
import { getS3SignedURL, updateCertDetails } from './actions'
import { uploadFile } from '../utils/api-utils'

const FormItem = Form.Item;
const { Option } = Select;

export const IssueCertificateModal = ({ orgId, orgUnitId, onClose, onCertIssue, certificate, s3FileKey, selectedOrgName }) => {

    const [form] = Form.useForm();
    const [ouCredentialId, setOuCredentialId] = useState(s3FileKey);
    const [certName, setCertName] = useState();

    useEffect(() => {
        const name = CERT_ARRAY.find(cert => cert.id === certificate).name;
        setCertName(name);
    }, [orgId, orgUnitId]);

    const validPeriods = (
        QUALIFICATION_VALID_PERIODS.map(validPeriod => <Option key={validPeriod.id} value={validPeriod.name}>{validPeriod.name}</Option>)
    );

    const calculateExpireDate = (attainDate, validPeriod) => {
        const periodConfig = QUALIFICATION_VALID_PERIODS.find(item => item.name === validPeriod);
        // above  should always returns one 
        const monthsToAdd = periodConfig.months;
        var expiryDate = new Date(attainDate.setMonth(attainDate.getMonth() + monthsToAdd));

        return expiryDate;
    }

    const onSubmitClicked = async () => {

        try {
            const values = await form.validateFields();
            const user = await Auth.currentAuthenticatedUser();
            const attainDate = new Date();
            const expireDate = calculateExpireDate(attainDate, values.validPeriod);
            let params = {
                ou_id: orgUnitId,
                type: certificate,
                attained_date: new Date(),
                expire_date: expireDate,
                validity_period: values.validPeriod,
                org_name: selectedOrgName,
                status: CERTIFICATION_STATUS_BY_NAME.CERTIFIED
            }
            await updateCertDetails(orgId, params);
            message.success("Certificate added successfully");
            await onCertIssue();
            onClose();
        } catch (e) {
            if (e.errorFields) {
                return;
            }
            console.error(e);
            message.error("Error while adding certification");
        }
    }

    const uploadProps = {
        name: 'file',
        accept: '.pdf', 
        multiple: false,
        maxCount: 1,
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        beforeUpload: async (file, fileList) => {
            if(file.type !== 'application/pdf'){
                file.status = 'error';
                message.error(`${file.name} is not a PDF file. Please upload the certificate in PDF format.`);
                return false;
            }
            const ouCredId = ouCredentialId ? ouCredentialId : ulid();
            setOuCredentialId(ouCredId);
            uploadCert(file, ouCredId)
            return false;
        }
    };

    const uploadCert = async (file, key) => {

        try {
            const putURL = await getS3SignedURL(orgId, key);
            await uploadFile(putURL.url, file, file.type);
        } catch (e) {
            console.log(e);
        }

    }

    return (
        <Modal
            visible={true}
            footer={null}
            onCancel={onClose}
            wrapClassName=''
            maskClosable={false}
            centered
            title='Issue New Certificate'
            width='500px'
        >

            <div>
                <Form
                    layout="vertical"
                    form={form}
                >

                    <FormItem
                        label="Certificate Name"
                    >
                        <Input value={certName} disabled/>
                    </FormItem> 

                    <FormItem
                        label="Valid For"
                        name="validPeriod"
                        rules={[{ required: true, message: 'Please select a valid period' }]}
                    >
                        <Select placeholder="Select a valid period">
                            {validPeriods}
                        </Select>
                    </FormItem>
                    <FormItem
                        label="Certificate Upload"
                        name="certificateUpload"
                        rules={[{ required: true, message: 'Please upload the certificate' }]} >
                        <Upload {...uploadProps}>
                            <Button data-testid={`upload-certificate`} icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload>
                    </FormItem>
                    <FormItem>
                        <Button data-testid={`issues-certificate`} className="btn btn--primary" onClick={onSubmitClicked}>Issue Certificate</Button>
                    </FormItem>
                </Form>
            </div>
        </Modal>)
}