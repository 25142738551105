import React, { useEffect, useState } from 'react';
import { Row, Col, Spin, Card, Collapse, Empty, Button, message } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { getOrgInfoCompletedTasks, getProgressIcon } from '../utils/common-utils'
import { IrrigationDetails } from './site-assessment/Irrigation';
import { EnvironmentalContext } from './site-assessment/EnvironmentalContext';
import { LandscapeInformation } from './site-assessment/LandscapeInformation';
import { PlantWildlifeInformation } from './site-assessment/PlantWildlifeInformation';
import { SupplementalStructures } from './site-assessment/SupplementalStructures';
import { OutreachEducation } from './site-assessment/OutreachEducation';
import { LocalResources } from './site-assessment/LocalResources';
import { AdditionalInformation } from './site-assessment/AdditionalInformation';
import { CONFIG } from '../../src/config/app-config';
import { openS3DocumentByURL, openFile } from '../utils/doc-utils';
import { getFormProgress } from './form-actions/FormActions';
import { CertificationRecods } from './certifications/CertificationRecods';
import { SITE_ASSESSMENT_ORG_DETAILS_SECTIONS, DOC_TYPE } from '../utils/constants';
import { ManagementContex } from './site-assessment/ManagementContex';
import { getAttachmentDownloadSignedUrl } from './actions'

const { Panel } = Collapse;
export const AudubonSiteAssessmentListView = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [orgDetails, setOrgDetails] = useState({});
  const [inspectionList, setInspectionList] = useState([]);
  const [priliminaryDocList, setDocList] = useState([]);

  const [managementContextForm, setManagementContextForm] = useState({});
  const [irrigationForm, setIrrigationForm] = useState({});
  const [environmentalContextForm, setEnvironmentalContextForm] = useState({});
  const [plantAndWildlifeForm, setPlantAndWildlifeForm] = useState({});
  const [outreachEducationForm, setOutreachEducationForm] = useState({});
  const [additionalInfoForm, setAdditionalInfoForm] = useState({});
  const [landscapeInfoForm, setLandscapeInfoForm] = useState({});
  const [supplementalStructuresForm, setSupplementalStructuresForm] = useState({});
  const [localResourcesForm, setLocalResourcesForm] = useState({});
  const [statementOfSupport, setStatementOfSupport] = useState(undefined);

  useEffect(() => {
    setInspectionList([]);
    setOrgDetails({});
    setIsLoading(true);
    if (props.orgDetails && props.orgDetails.audubon_details) {
      const audubonData = props.orgDetails.audubon_details;
      let orgDetails = {}
      if (audubonData.IrrigationForm) {
        _.merge(orgDetails, { IrrigationForm: audubonData.IrrigationForm });
        setIrrigationForm(audubonData.IrrigationForm.FormDetails);
      }
      if (audubonData.EnvironmentalContextForm) {
        _.merge(orgDetails, { EnvironmentalContextForm: audubonData.EnvironmentalContextForm });
        setEnvironmentalContextForm(audubonData.EnvironmentalContextForm.FormDetails);
      }
      if (audubonData.PlantAndWildlifeForm) {
        _.merge(orgDetails, { PlantAndWildlifeForm: audubonData.PlantAndWildlifeForm });
        setPlantAndWildlifeForm(audubonData.PlantAndWildlifeForm.FormDetails);
      }
      if (audubonData.OutreachEducationForm) {
        _.merge(orgDetails, { OutreachEducationForm: audubonData.OutreachEducationForm });
        setOutreachEducationForm(audubonData.OutreachEducationForm.FormDetails);
      }
      if (audubonData.AdditionalInfoForm) {
        _.merge(orgDetails, { AdditionalInfoForm: audubonData.AdditionalInfoForm });
        setAdditionalInfoForm(audubonData.AdditionalInfoForm.FormDetails);
      }
      if (audubonData.LandscapeInfoForm) {
        _.merge(orgDetails, { LandscapeInfoForm: audubonData.LandscapeInfoForm });
        setLandscapeInfoForm(audubonData.LandscapeInfoForm.FormDetails);
      }
      if (audubonData.SupplementalStructuresForm) {
        _.merge(orgDetails, { SupplementalStructuresForm: audubonData.SupplementalStructuresForm });
        setSupplementalStructuresForm(audubonData.SupplementalStructuresForm.FormDetails);
      }
      if (audubonData.LocalResourcesForm) {
        _.merge(orgDetails, { LocalResourcesForm: audubonData.LocalResourcesForm });
        setLocalResourcesForm(audubonData.LocalResourcesForm.FormDetails);
      }
      if (audubonData.ManagementContextForm) {
        _.merge(orgDetails, { ManagementContextForm: audubonData.ManagementContextForm });
        setManagementContextForm(audubonData.ManagementContextForm.FormDetails);
      }

      if(audubonData.StatementOfSupport){
        setStatementOfSupport(audubonData.StatementOfSupport.FormDetails);
      }
      setOrgDetails(orgDetails);
    }

    if (props.certDetails) {
      let templateList = props.certDetails.tasks.filter(temp => temp.is_preliminary === 1);
      let assessment = [];
      templateList.forEach(temp => {
        let assignee = props.certDetails.records.filter(record => record.template_id === temp.template_id);
        assessment.push(...assignee);
      });
      let sortedInspectionList = assessment ? assessment.sort((a, b) => {
        let aSortKey = a.name === 'Environmental Planning' ? '1' + a.name:'2' + a.name;
        let bSortKey = b.name === 'Environmental Planning' ? '1' + b.name:'2' + b.name;;
        return aSortKey.localeCompare(bSortKey);
      }) : [];
      setInspectionList(sortedInspectionList);
    }

    if (props.docList) {
      let documentList = props.docList.tasks.filter(doc => doc.is_preliminary === DOC_TYPE.PRELIMINARY);
      let assessment = [];
      documentList.map(doc => {
        let assignee = props.docList.records.filter(record => record.doc_id === doc.doc_id);
        assessment.push(...assignee);
      });
      setDocList(assessment);
    }

    setIsLoading(false);
  }, [props]);

  const checkFormProgress = () => {
    if (inspectionList.length > 0 || orgDetails || priliminaryDocList.length > 0) {
      let isCertificationsCompleted = false;
      let isOrgDetailsCompleted = false;
      let isDocumentCompleted = false;
      if (priliminaryDocList.length > 0 && (priliminaryDocList.length === priliminaryDocList.filter(doc => doc.status === 100).length)) {
        isDocumentCompleted = true;
      }
      if (inspectionList.length > 0 && (inspectionList.length === inspectionList.filter(cert => cert.status === 100).length)) {
        isCertificationsCompleted = true;
      }
      if (orgDetails) {
        let result = getOrgInfoCompletedTasks(orgDetails);
        if (result === Object.keys(SITE_ASSESSMENT_ORG_DETAILS_SECTIONS).length) isOrgDetailsCompleted = true;

        let siteData = {allRecords : Object.keys(SITE_ASSESSMENT_ORG_DETAILS_SECTIONS).length, completedRecords : result}
        if(!_.isEqual(props.siteAssessmentCount, siteData)) props.setSiteAssessmentCount(siteData)
      }

      if (isCertificationsCompleted && isOrgDetailsCompleted && isDocumentCompleted) {
        setProgress(100);
      } else {
        setProgress(50);
      }
    } else {
      setProgress(0);
    }
  }

  useEffect(() => {
    checkFormProgress();
  }, [inspectionList, orgDetails])

  const downloadFileFromS3 = async (file, setLoadingState) => {
    setLoadingState(true);
    await openS3DocumentByURL(`${CONFIG.s3HostUrl}/${CONFIG.externalCertificationDataBucket}/${props.orgUnitId}/${file}.pdf`);
    setLoadingState(false);
  }

  const downloadSoS = async () => {
    setIsLoading(true);
    const { orgId, orgUnitId } = props;
    
    if(statementOfSupport){
      const signedUrl = await getAttachmentDownloadSignedUrl(orgId, orgUnitId, statementOfSupport.sosFileKey, statementOfSupport.sosFileType, true);

      if(signedUrl.error){
        message.error("Error while downloading the Statement of Support copy");
        setIsLoading(false);
        return;
      }
      await openFile(signedUrl.data);
    }
    
    setIsLoading(false);
  }

  const cardTitle = <Row><Col span={12}>Site Assessment Details</Col><Col span={12} style={{ textAlign: 'right' }}>{getProgressIcon(progress)}</Col></Row>;

  return (
    <Card title={cardTitle}>
      {isLoading && (<Spin tip='Loading Site Assessment Details...' />)}
      {!isLoading && !orgDetails && !inspectionList[0](<div><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>)}
      <div>
        {orgDetails && <div>
          <p><b>Site Information</b></p>
          <Collapse accordion>
            <Panel header='Management Context' key='0' extra={getProgressIcon(getFormProgress(orgDetails.ManagementContextForm), 24)}>
              <ManagementContex managementContextForm={managementContextForm} />
            </Panel>
            <Panel header='Irrigation' key='1' extra={getProgressIcon(getFormProgress(orgDetails.IrrigationForm), 24)}>
              <IrrigationDetails irrigationForm={irrigationForm} />
            </Panel>
            <Panel header='Environmental Context' key='2' extra={getProgressIcon(getFormProgress(orgDetails.EnvironmentalContextForm), 24)}>
              <EnvironmentalContext environmentalContextForm={environmentalContextForm} />
            </Panel>
            <Panel header='Landscape Information' key='3' extra={getProgressIcon(getFormProgress(orgDetails.LandscapeInfoForm), 24)}>
              <LandscapeInformation landscapeInfoForm={landscapeInfoForm} />
            </Panel>
            <Panel header='Plant and Wildlife Information' key='4' extra={getProgressIcon(getFormProgress(orgDetails.PlantAndWildlifeForm), 24)}>
              <PlantWildlifeInformation plantAndWildlifeForm={plantAndWildlifeForm} downloadFileFromS3={downloadFileFromS3} />
            </Panel>
            <Panel header='Supplemental Structures for Wildlife' key='5' extra={getProgressIcon(getFormProgress(orgDetails.SupplementalStructuresForm), 24)}>
              <SupplementalStructures supplementalStructuresForm={supplementalStructuresForm} />
            </Panel>
            <Panel header='Outreach & Education' key='6' extra={getProgressIcon(getFormProgress(orgDetails.OutreachEducationForm), 24)}>
              <OutreachEducation outreachEducationForm={outreachEducationForm} />
            </Panel>
            <Panel header='Local Resources: People and Organizations' key='7' extra={getProgressIcon(getFormProgress(orgDetails.LocalResourcesForm), 24)}>
              <LocalResources localResourcesForm={localResourcesForm} />
            </Panel>
            <Panel header='Additional Information' key='8' extra={getProgressIcon(getFormProgress(orgDetails.AdditionalInfoForm), 24)}>
              <AdditionalInformation additionalInfoForm={additionalInfoForm} />
            </Panel>
          </Collapse>
        </div>}
        {inspectionList && <div>
          <p style={{ marginTop: 30 }}><b>Environmental Planning Records</b></p>
          <Collapse accordion>
            <CertificationRecods orgId={props.orgId} orgUnitId={props.orgUnitId} certType={props.certType} data={inspectionList} orgName={props.orgName} updateCertList={props.updateCertList} />
          </Collapse>
        </div>}
        <div>
          <p style={{ marginTop: 30 }}><b>Statement of Suppport</b></p>
          { statementOfSupport && <div className="pure-u-1" style={{ backgroundColor: '#eeeeee', padding: '15px' }}>
            <p>Download the signed Statement of Suppport document using <b>Download File</b> button below.</p>
            <Button onClick={() => downloadSoS()}>
                <DownloadOutlined /> Download File
              </Button>
          </div> }
          { !statementOfSupport && <div className="pure-u-1" style={{ backgroundColor: '#eeeeee', padding: '15px' }}>
            <p>Signed Statement of Suppport document has not been uploaded yet.</p>
          </div> }
        </div>
      </div>
    </Card>
  )
}