import React, { useEffect, useState, useContext } from 'react';
import { Spin } from 'antd';
import { Switch, Route, Redirect, BrowserRouter as Router } from "react-router-dom";
import { LoginPage } from './login/LoginPage';
import { DashBoardPage } from './dashboard/DashBoardPage';
import { sessionStore } from "./store/session";
import { isSessionAuthenticated } from './login/actions'

import './App.scss';

const App = () => {

  const [initialDataLoading, setInitialDataLoading] = useState(true);
  const [isAuthenticatedUser, setIsAuthenticatedUser] = useState(false);
  const { dispatch } = useContext(sessionStore);

  useEffect(() => {
    setInitialDataLoading(true);
    isSessionAuthenticated(dispatch).then(authenticated => {
      setIsAuthenticatedUser(authenticated);
      setInitialDataLoading(false);
    });
  }, [dispatch]);

  const loadInitialData = async () => {
    setInitialDataLoading(false);
  }

  if (initialDataLoading) {
    return (<div className="banner-body">
      <Spin tip="Loading..." />
    </div>);
  }

  return (
    <Router>
      <Switch>
        {!isAuthenticatedUser && <Route exact path="/login"> <LoginPage setIsAuthenticatedUser={setIsAuthenticatedUser} /> </Route>}
        {!isAuthenticatedUser && <Redirect to="/login" />}
        {isAuthenticatedUser && <Route path="/:mainMenu"> <DashBoardPage setIsAuthenticatedUser={setIsAuthenticatedUser} /> </Route>}
        {isAuthenticatedUser && <Route path="/"> <DashBoardPage setIsAuthenticatedUser={setIsAuthenticatedUser} /> </Route>}
      </Switch>
    </Router>
  );
}

export default App;
