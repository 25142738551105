import React from 'react';
import { Descriptions } from 'antd';
import _ from 'lodash';
import { emptyContent, getFieldValue, lblStyle } from '../form-actions/FormActions';

export const LocalResources = ({localResourcesForm}) => {

  return (
    <>
      {_.isEmpty(localResourcesForm) ? emptyContent
        : <Descriptions title='People and Organizations' {...lblStyle} bordered style={{ paddingTop: 24 }} column={2}>
          {localResourcesForm.tableData && localResourcesForm.tableData.map((obj, i) => (
            <Descriptions.Item label={`${obj.name}`} key={`localResourcesFields${i}`} >
              Title or Relationship to Course : {getFieldValue(obj.relationshipCourse)}<br />
              Area of Expertise : {getFieldValue(obj.areaExpertise)}<br />
              Address or e-mail : {getFieldValue(obj.address)}<br />
              Send Info ? : {getFieldValue(obj.sendInfo)}<br />
            </Descriptions.Item>
          ))}
        </Descriptions>
      }
    </>
  )
}