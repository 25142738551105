import React from 'react';
import { Descriptions, Row } from 'antd';
import _ from 'lodash';
import { emptyContent, getFieldBoolean, getFieldValue, lblStyle } from '../form-actions/FormActions';

export const SupplementalStructures = ({ supplementalStructuresForm }) => {
  const { brushPile, water, food } = supplementalStructuresForm ? supplementalStructuresForm : {}

  return (
    <>
      {_.isEmpty(supplementalStructuresForm) ? emptyContent
        : <>
          <Descriptions title='Shelter' {...lblStyle} bordered style={{ paddingTop: 24 }} column={1}>
            {supplementalStructuresForm.shelter && supplementalStructuresForm.shelter.map((obj, key) =>
              <Descriptions.Item label={obj.speciesSpecific} key={key} >
                1.#of Structures : {getFieldValue(obj.structures)} <br />
                2.Are they successful? : {getFieldValue(obj.areTheySuccessful)}<br />
                3.How often are they monitored? : {getFieldValue(obj.howOftenAreTheyMonitored) === "Other (Please describe)" ? getFieldValue(obj.other) : getFieldValue(obj.howOftenAreTheyMonitored)}<br />
                4.Who monitors them? : {getFieldValue(obj.whoMonitorsThem)}<br />
              </Descriptions.Item>
            )
            }
          </Descriptions>
          <Descriptions {...lblStyle} bordered style={{ paddingTop: 24 }} column={1}>
            <Descriptions.Item label='Brush pile(s)' >
              Brush pile(s) : {getFieldBoolean(brushPile.brushPile)} <br />
              How many? : {getFieldValue(brushPile.howManyBrushPile)}<br />
            </Descriptions.Item>
          </Descriptions>
          <Descriptions title='Water'  {...lblStyle} bordered style={{ paddingTop: 24 }}>
            <Descriptions.Item label='Birdbath(s)' >
              Birdbath(s) : {getFieldBoolean(water.birdbath)} <br />
              How many? : {getFieldValue(water.birdbathHowMany)}<br />
            </Descriptions.Item>
            <Descriptions.Item label='Garden Pond(s)' >
              Garden Pond(s) : {getFieldBoolean(water.gardenPond)} <br />
              How many? : {getFieldValue(water.gardenPondHowMany)}<br />
            </Descriptions.Item>
            <Descriptions.Item label='Other' >
              Other : {getFieldBoolean(water.waterOther)} <br />
              {getFieldValue(water.otherText)}<br />
            </Descriptions.Item>
          </Descriptions>
          <Descriptions title='Food'  {...lblStyle} bordered style={{ paddingTop: 24 }}>
            <Descriptions.Item label='Bird feeder(s)' >
              Bird feeder(s) : {getFieldBoolean(food.birdFeeder)} <br />
              <Row>
                {`How many - `}
                <Descriptions.Item>
                  Seed : {getFieldValue(food.foodSeed)}
                </Descriptions.Item> &nbsp;
                <Descriptions.Item>
                  Suet : {getFieldValue(food.foodSuet)}
                </Descriptions.Item> &nbsp;
                <Descriptions.Item>
                  Hummingbird : {getFieldValue(food.foodHummingbird)}
                </Descriptions.Item>
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label='Other' >
              Other : {getFieldBoolean(food.foodOther)} <br />
              {getFieldValue(water.foodOtherText)}<br />
            </Descriptions.Item>
          </Descriptions>
        </>
      }
    </>
  )
}