import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Progress, Collapse } from 'antd';
import { Pie, measureTextWidth } from '@ant-design/charts';
import { STATUS, DOC_TYPE } from '../../utils/constants';
import { checkCaseStudyOverollProcess } from '../case-study/caseStudyActions';
import { CASE_STUDY_PROPERTIES } from '../constants';

const { Panel } = Collapse;
const chartInitStatus = [
  { type: 'Not Assigned', value: 0 },
  { type: 'To do', value: 0 },
  { type: 'Pending Review', value: 0 },
  { type: 'Pending Response', value: 0 },
  { type: 'Rework In Progress', value: 0 },
  { type: 'Completed', value: 0 },
];

const chartInitStatusDoc = [
  { type: 'Not Assigned', value: 0 },
  { type: 'To do', value: 0 },
  { type: 'Completed', value: 0 },
];

export const CompleteCertification = ({ certDetails, docDetails, audubonCaseStudy }) => {
  const [certStatus, setCertStatus] = useState(chartInitStatus);
  const [docStatus, setDocStatus] = useState(chartInitStatusDoc);
  const [caseStudyStatus, setCaseStudyStatus] = useState({ completedRecords: 0, allRecords: 0 });

  useEffect(() => {
    if (audubonCaseStudy) {
      let completedRecords = checkCaseStudyOverollProcess(audubonCaseStudy);
      let allRecords = Object.keys(audubonCaseStudy).length;
      setCaseStudyStatus({ completedRecords: completedRecords, allRecords: allRecords })
    }
  }, [audubonCaseStudy]);

  useEffect(() => {
    if (certDetails) {
      let templateList = certDetails.tasks.filter(temp => temp.is_preliminary === 0);
      let assessment = [];
      let notAssingCertCount = 0;
      templateList.map(temp => {
        let assignee = certDetails.records.filter(record => record.template_id === temp.template_id);
        if (assignee.length === 0) notAssingCertCount++;
        assessment.push(...assignee);
      });
      let certDataStatus = caculateProgress(assessment, notAssingCertCount)
      setCertStatus(certDataStatus);
    }
  }, [certDetails]);

  useEffect(() => {
    if (docDetails && docDetails.records) {
      const docRecords = docDetails.records.filter(doc => doc.is_preliminary !== DOC_TYPE.PRELIMINARY);
      let docDataStatus = caculateDocProgress(docRecords, (docDetails.totalTasks - docDetails.assignedTasks))
      setDocStatus(docDataStatus);
    }
  }, [docDetails]);

  function caculateProgress(assessment, notAssingCertCount) {
    let todo = 0;
    let pendingReview = 0;
    let pendingResponse = 0;
    let reworkInProgress = 0;
    let completedRecords = 0;
    assessment.map(record => {
      if (record.status === STATUS.TODO) todo++;
      if (record.status === STATUS.PENDING_REVIEW) pendingReview++;
      if (record.status === STATUS.PENDING_RESPONSE) pendingResponse++;
      if (record.status === STATUS.REWORK_INPROGRESS) reworkInProgress++;
      if (record.status === STATUS.DONE) completedRecords++;
    });

    let dataStatus = [
      { type: 'Not Assigned', value: notAssingCertCount, color: '#EC1E28' },
      { type: 'To do', value: todo, color: '#FB8C16' },
      { type: 'Pending Review', value: pendingReview, color: '#13C2C2' },
      { type: 'Pending Response', value: pendingResponse, color: '#1890FF' },
      { type: 'Rework In Progress', value: reworkInProgress, color: '#722ED1' },
      { type: 'Completed', value: completedRecords, color: '#52C41A' },
    ];
    return dataStatus;
  }

  function caculateDocProgress(assessment, notAssingCertCount) {
    let todo = 0;
    let completedRecords = 0;
    assessment.map(record => {
      if (record.status === STATUS.TODO) todo++;
      if (record.status === STATUS.DONE) completedRecords++;
    });

    let dataStatus = [
      { type: 'Not Assigned', value: notAssingCertCount, color: '#EC1E28' },
      { type: 'To do', value: todo, color: '#FB8C16' },
      { type: 'Completed', value: completedRecords, color: '#52C41A' },
    ];
    return dataStatus;
  }

  function renderStatistic(containerWidth, text, style, color = '#000000') {
    const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
    const R = containerWidth / 2;
    let scale = 1;
    if (containerWidth < textWidth) {
      scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
    }
    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};color:${color};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
  }

  var chartConfig = {
    appendPadding: 0,
    angleField: 'value',
    colorField: 'type',
    radius: 0.75,
    innerRadius: 0.64,
    label: {
      content: '',
    },
    statistic: {
      title: {
        offsetY: 10,
        style: {
          fontSize: '18px',
          fontWeight: 'bold',
        },
        customHtml: (container, view, datum, data) => {
          const color = datum ? datum.color : `#EC1E28`;
          const { width } = container.getBoundingClientRect();
          const text = datum ? ` ${~~(datum.value / data.reduce((r, d) => r + d.value, 0) * 100)} %` : ` `;
          return renderStatistic(width, text, { fontSize: 14 }, color);
        }
      },
      content: {
        offsetY: 2,
        offsetX: -2,
        style: {
          fontSize: '11px',
          fontWeight: 'normal',
        },
        customHtml: (container, view, datum, data) => {
          const { width, height } = container.getBoundingClientRect();
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          return renderStatistic(d, '', { fontSize: 12 });
        },
      }
    },
    interactions: [
      { type: 'element-selected',enable: false },
      { type: 'element-active' },
      { type: 'tooltip', enable: false },
      { type: 'legend-filter', enable: false },
      {
        type: 'pie-statistic-active',
        cfg: {
          start: [
            { trigger: 'element:mouseenter', action: 'pie-statistic:change' },
            { trigger: 'legend-item:mouseenter', action: 'pie-statistic:change' },
            { trigger: 'element:mouseleave', action: 'pie-statistic:reset' },
            { trigger: 'legend-item:mouseleave', action: 'pie-statistic:reset' }
          ]
        }
      }
    ],
  };

  var certConfig = {
    ...chartConfig,
    color: ['#EC1E28', '#FB8C16', '#13C2C2', '#1890FF', '#722ED1', '#52C41A'],
    data: certStatus,
    legend: {
      position: 'right',
      offsetX: -50,
      offsetY: 0,
      itemWidth: 150,
      itemValue: {
        alignRight: true,
        formatter: (text, item) => {
          const items = certStatus.filter((d) => d.type === item.value);
          return items.length ? items.reduce((a, b) => a + b.value, 0) / items.length : '-';
        },
        style: {
          opacity: 0.65,
        },
      },
    },
  }

  var docConfig = {
    ...chartConfig,
    color: ['#EC1E28', '#FB8C16', '#52C41A'],
    data: docStatus,
    legend: {
      position: 'right',
      offsetX: -50,
      offsetY: 0,
      itemWidth: 150,
      itemValue: {
        alignRight: true,
        formatter: (text, item) => {
          const items = docStatus.filter((d) => d.type === item.value);
          return items.length ? items.reduce((a, b) => a + b.value, 0) / items.length : '-';
        },
        style: {
          opacity: 0.65,
        },
      },
    },
  }

  return (
    <Collapse accordion defaultActiveKey={1}>
      <Panel className="certification-summary-view" header='Certification' key='1'>
        <Row>
          <Col span={9} style={{ marginRight: 20, minWidth: 420, backgroundColor: '#ffffff', marginTop: 10 }}>
            <Row justify="start"><Pie {...certConfig} style={{ height: 200 }} /> </Row>
            <Row justify="center" style={{ paddingBottom: 10 }}> Certification Records </Row>
          </Col>
          <Col span={9} style={{ marginRight: 20, minWidth: 420, backgroundColor: '#ffffff', marginTop: 10 }}>
            <Row justify="start"><Pie {...docConfig} style={{ height: 200 }} /></Row>
            <Row justify="center" style={{ paddingBottom: 10 }}> Document Records </Row>
          </Col>
          <Col span={5} style={{ paddingTop: 50, paddingRight: 20, minWidth: 200 }}>
            <Row justify="center">{<div><Progress type="dashboard" percent={100 * (caseStudyStatus.completedRecords / CASE_STUDY_PROPERTIES.length)} format={() => `${caseStudyStatus.completedRecords}  / ${CASE_STUDY_PROPERTIES.length}`} /></div>} </Row>
            <Row justify="center"> Case Study </Row>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
}