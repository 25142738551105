import React, { useEffect, useState, useContext } from 'react';

import { Modal, Button, Spin, Comment, Input, List, Form, Empty } from 'antd';
import moment from 'moment';
import { sessionStore, SESSION_ATTRS } from "../store/session";
import { addComment, updateUserTask } from "./actions";
import { STATUS, USER_TYPE } from "./../utils/constants"
import { audubonLogo, connectLogo } from '../utils/icons';


const { TextArea } = Input;

export const CommentsViewerModal = (props) => {

    const [comments, setComments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [addingComment, setAddingComment] = useState(false);
    const [addingCommentAndAccept, setAddingCommentAndAccept] = useState(false);
    const [comment, setComment] = useState("");
    const [orgId, setOrgId] = useState("");
    const [orgUnitId, setOrgUnitId] = useState("");
    const [certType, setCertType] = useState("");
    const [taskType, setTaskType] = useState("");
    const [taskId, setTaskId] = useState("");
    const [newCommentsAdded, setNewCommentsAdded] = useState(false);
    const [orgName, setOrgName] = useState();

    const { state } = useContext(sessionStore);
    const user = state.get(SESSION_ATTRS.LOGGED_USER);


    useEffect(() => {
        setIsLoading(true);
        setOrgId(props.orgId);
        setTaskId(props.taskId);
        loadComments(props.orgId, props.taskId);
        setIsLoading(false);
        setOrgUnitId(props.orgUnitId);
        setCertType(props.certType);
        setTaskType(props.taskType);
        setOrgName(props.orgName);
    }, [props]);

    const timeFormat = (time) => moment(time).calendar(null,{
        sameDay: `[${moment(time).fromNow()}]`,
        lastDay: '[Yesterday] hh:mm A',
        sameElse: 'YYYY-MM-DD hh:mm A'
    })

    const loadComments = async () => {

        const commentsList = props.commentsThread ? props.commentsThread : [];
        const commentsForUI = commentsList.map(c => { 
            const displayUser = (c.userType === USER_TYPE.AUDUBON) ? c.createdByName + ' (Audubon)' : c.createdByName ;
            return {
                author: displayUser,
                avatar: c.userType === USER_TYPE.AUDUBON ? audubonLogo : connectLogo,
                content: c.comment,
                datetime: timeFormat(c.createdAt),
            }
        });
        if (commentsForUI.length > 0) {
            setComments(commentsForUI);
        }

    };

    const CommentList = ({ comments }) => (
        <List
            dataSource={comments}
            header={`${comments.length} ${comments.length > 1 ? 'replies' : 'reply'}`}
            itemLayout="horizontal"
            renderItem={props => <Comment {...props} />}
        />
    );

    const handleSubmit = async () => {
        if (!comment) {
            return;
        }
        setAddingComment(true);
        await addCommentAndUpdateTask(STATUS.PENDING_RESPONSE);
        setAddingComment(false);
    };

    const handleSubmitAndAccept = async () => {
        if (!comment) {
            return;
        }
        setAddingCommentAndAccept(true);
        await addCommentAndUpdateTask(STATUS.DONE);
        await props.updatRecordList(taskId);
        setAddingCommentAndAccept(false);
        props.onClose(STATUS.DONE);
    };

    const addCommentAndUpdateTask= async (status) => {
        await addComment(orgId, orgUnitId, certType, taskId, taskType, comment, user, orgName);
        setComments([
            ...comments,
            {
                author: user.name,
                avatar: audubonLogo,
                content: <p>{comment}</p>,
                datetime: moment().fromNow(),
            },
        ]);
        await updateUserTask(orgId, taskId, user, status);
        setNewCommentsAdded(true);
        setComment("");
    };

    const handleChange = e => {
        setComment(e.target.value);
    };

    const onCloseModal = async() => {
        if(newCommentsAdded) {
            await props.updatRecordList();
            props.onClose(STATUS.PENDING_RESPONSE)
        }else{
            props.onClose();
        }

    }

    return (
        <Modal
            visible={true}
            footer={null}
            onCancel={onCloseModal}
            wrapClassName=''
            maskClosable={false}
            centered
            title='Feedback'
            width='700px'
        >
            {isLoading && (<div className="banner-body">
                <Spin tip="Loading..." size="small" />
            </div>)}

            {!isLoading &&
                <>
                    {comments.length > 0 && <CommentList comments={comments} />}
                    {comments.length === 0 && (props.taskStatus === STATUS.DONE) && <Empty description="No Feedback" />}
                    {(props.taskStatus !== STATUS.DONE) && 
                    <Comment
                        avatar={
                            audubonLogo
                        }
                        content={
                            <>
                                <Form.Item>
                                    <TextArea rows={4} onChange={handleChange} value={comment} />
                                </Form.Item>
                                <Form.Item>
                                    <Button data-testid={`add-feedback`} htmlType="submit" loading={addingComment} disabled={addingCommentAndAccept} onClick={handleSubmit} type="primary">Add Feedback</Button>
                                    { props.taskStatus === STATUS.PENDING_REVIEW && <Button style={{marginLeft:'10px'}} data-testid={`add-feedback-approve`} htmlType="submit" loading={addingCommentAndAccept} disabled={addingComment} onClick={handleSubmitAndAccept} type="primary">Add Feedback and Accept</Button>}
                                </Form.Item>
                            </>
                        }
                    />}
                </>}

        </Modal>)
}