import React from 'react';
import { Descriptions } from 'antd';
import _ from 'lodash';
import { emptyContent, getFieldValue, lblStyle, lblStyleShortLengthLabels } from '../form-actions/FormActions';

export const EnvironmentalContext = ({ environmentalContextForm }) => {

  return (
    <>
      {_.isEmpty(environmentalContextForm) ? emptyContent
        : <Descriptions title='Environmental Context Details' {...lblStyle} bordered style={{ paddingTop: 24 }} column={2}>
          <Descriptions.Item {...lblStyleShortLengthLabels} label='What is the nearest water body where the water from your course drains after it leaves your course?'>{getFieldValue(environmentalContextForm.nearestWaterBody)}</Descriptions.Item>
          <Descriptions.Item {...lblStyleShortLengthLabels} label='From what water body does your course receive natural flow?'>{getFieldValue(environmentalContextForm.waterBodyForNaturalFlow)}</Descriptions.Item>
          <Descriptions.Item label='What is your regional watershed?'>{getFieldValue(environmentalContextForm.regionalWatershed)}</Descriptions.Item>
          <Descriptions.Item label='What is the predominant natural vegetation (plant community) in your area?'>{getFieldValue(environmentalContextForm.naturalVegetationInArea)}</Descriptions.Item>
          <Descriptions.Item label='Surrounding Land Use'>North : {getFieldValue(environmentalContextForm.surroundingLandUseNorth)} <br />
            South : {getFieldValue(environmentalContextForm.surroundingLandUseSouth)} <br />
            East : {getFieldValue(environmentalContextForm.surroundingLandUseEast)} <br />
            West : {getFieldValue(environmentalContextForm.surroundingLandUseWest)} </Descriptions.Item>
        </Descriptions>
      }
    </>
  )
}