import React, { useEffect, useState, useContext } from 'react';
import { Table, message, Spin, Button, Modal } from 'antd';
import handlebars from 'handlebars/dist/handlebars.min.js';
import { getTag, resolveTemplate, formatDateToDisplay } from '../../utils/common-utils'
import { getTaskInfor, getCertificationTemplate, getAttachmentSignedUrl, getCommentsForOUAndTaskType, updateUserTask } from '../actions'
import { RecordViewerModal } from '../RecordViewerModal';
import { CommentsViewerModal } from '../CommentsViewerModal';
import { QUESTIONS_CATEGORY, STATUS, TASK_TYPE } from '../../utils/constants'
import { sessionStore, SESSION_ATTRS } from "../../store/session";
import { CERTIFICATION_CATEGORY } from '../constants';
import { generatePrintView } from '../../utils/dynamic_inspection_utills';

export const CertificationRecods = (props) => {
  const [inspectionList, setInspectionList] = useState([]);
  const [orgId, setOrgId] = useState();
  const [orgUnitId, setOrgUnitId] = useState();
  const [certType, setCertType] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [recordViewInner, setRecordViewInner] = useState('<div/>');
  const [recordViewerVisible, setRecordViewerVisible] = useState(false);
  const [commentsViewerVisible, setCommentsViewerVisible] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState();
  const [commentsMap, setCommentsMap] = useState(new Map());
  const [orgName, setOrgName] = useState();
  const [templateCategory, setTemplateCategory] = useState("");

  const { state } = useContext(sessionStore);
  const user = state.get(SESSION_ATTRS.LOGGED_USER);

  useEffect(() => {
    setInspectionList(props.data);
    setOrgId(props.orgId);
    setOrgUnitId(props.orgUnitId);
    setCertType(props.certType);
    setOrgName(props.orgName);
  }, [props]);

  const columns = [{
    title: 'Name',
    dataIndex: 'name',
    render: (_, record) => record.name
  },
  {
    title: 'User',
    dataIndex: 'user_name',
    render: (_, record) => record.user_name
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (_, record) => getTag(record.status)
  },
  {
    title: 'Assigned Date',
    dataIndex: 'created_at',
    key: 'task_id',
    render: (_, record) => { if (record.created_at) { return formatDateToDisplay(record.created_at) } }
  },
  {
    title: 'Completed Date',
    dataIndex: 'completed_at',
    render: (_, record) => { if (record.completed_at && record.status === STATUS.DONE) { return formatDateToDisplay(record.completed_at) } }
  },
  {
    title: '',
    dataIndex: 'task_id',
    key: 'task_id',
    render: (text, record) => {
      if (record.status === STATUS.DONE || record.status === STATUS.INSPECTION_COMPLETE ||
        record.status === STATUS.PENDING_RESPONSE || record.status === STATUS.REWORK_INPROGRESS) {
        return <><Button data-testid={`${record.task_id}-cert-view`} type="link" onClick={() => downloadReviewRecordURL(record)}
          icon={
            isLoading && selectedTaskId === record.task_id ? (
              <Spin size="small"
              />
            ) : null
          }> &nbsp;View</Button> |
          <Button data-testid={`${record.task_id}-cert-feedback`} type="link" onClick={() => getCommentsView(record)} > &nbsp;Feedback</Button></>

      } else if (record.status === STATUS.PENDING_REVIEW) {
        return <><Button data-testid={`${record.task_id}-cert-view`} type="link" onClick={() => downloadReviewRecordURL(record)}
          icon={
            isLoading && selectedTaskId === record.task_id ? (
              <Spin size="small"
              />
            ) : null
          }>&nbsp;View</Button> |
          <Button data-testid={`${record.task_id}-cert-feedback`} type="link" onClick={() => getCommentsView(record)} >&nbsp;Feedback</Button>|
          <Button data-testid={`${record.task_id}-cert-accept`} type="link" onClick={() => updateTaskConfirmPopup(record.task_id)}>Accept</Button>
        </>

      } else {
        return <Button type="link" disabled> Not Ready</Button>
      }
    }
  }];

  const updateTaskAsComplete = async (taskId) => {
    await updateUserTask(orgId, taskId, user, STATUS.DONE);
    await props.updateCertList(taskId);
  }

  const updateTaskConfirmPopup = (taskId) => {
    return new Promise(() => {
      Modal.confirm({
        title: "Are you sure?",
        content: "Please click Ok to approve this certification item. This will mark the record as approved and completed.",
        onOk: async() => await updateTaskAsComplete(taskId),
        onCancel() { },
      });
    });
  }

  const filterAttachment = async (questionId, answers, taskId, orgId, index = null) => {
    const answer = index ? answers[`${index}_${questionId}`] : answers[questionId];
    if (answer && answer.attachments) {

      const proms = answer.attachments.map(async (img) => {
        const attachment = await getAttachmentSignedUrl(orgId, taskId, img);
        if (attachment) {
          const image = attachment.url;
          const im = {
            image
          }
          return im;
        }
      });
      return await Promise.all(proms);

    }
    else {
      return null;
    }
  }

  const filterAnswer = (questionId, answers, index = null) => {
    if(!answers[questionId] && !answers[`${index}_${questionId}`]){
      return null;
    }
    return index ? answers[`${index}_${questionId}`].answer : answers[questionId].answer;
  }

  const filterComment = (questionId, answers, index = null) => {
    if (!answers[questionId] && !answers[`${index}_${questionId}`]) {
      return null;
    }
    return index ? answers[`${index}_${questionId}`].comment : answers[questionId].comment
  }

  const buildPrintData = async (orgId, template, taskInfo, assignee) => {
    const { title } = template;
    const name = assignee;
    const assignDate = taskInfo.CreatedOn;
    const completedDate = taskInfo.CompletedAt ? taskInfo.CompletedAt : undefined;
    const taskId = taskInfo.TaskId;

    const taskDescription = JSON.parse(taskInfo.TaskDescription)
    let quizBody = '<div></div>';
    let questionProms = [];

    if (template.templateCatagory === CERTIFICATION_CATEGORY.DYNAMIC_CERTIFICATION) {
      const taskAnsers = taskDescription.answers;
      quizBody = await generatePrintView(template, taskAnsers ? taskAnsers : {});
    } else if (template && template.questionsType === QUESTIONS_CATEGORY) {
      questionProms = template.questions.map(async (cat, index) => {
        const categoryQuestions = [];
        let questionMap = cat.questions.map(async (q) => {
          let answer = filterAnswer(q.questionId, taskDescription.answers, index+1)
          let comment = filterComment(q.questionId, taskDescription.answers, index+1)
          let attachment = await filterAttachment(q.questionId, taskDescription.answers, taskId, orgId, index+1);
          const details = {
            id: q.questionId,
            question: q.question,
            answer,
            comment,
            attachment
          }
          categoryQuestions.push(details);
        })
        await Promise.all(questionMap);

        let categoryDetails = {
          category: cat.category,
          questions: categoryQuestions.sort((a, b) => (a.id > b.id) ? 1 : -1)
        }
        return categoryDetails;
      });

    } else {
      questionProms = template.questions.map(async (q) => {

        let answer = filterAnswer(q.questionId, taskDescription.answers)
        let comment = filterComment(q.questionId, taskDescription.answers)
        let attachment = await filterAttachment(q.questionId, taskDescription.answers, taskId, orgId);

        const details = {
          id: q.questionId,
          question: q.question,
          answer,
          comment,
          attachment
        }
        return details;
      });
    }

    const questions = await Promise.all(questionProms);

    const questionArray = template && template.questionsType === QUESTIONS_CATEGORY ? questions : questions.sort((a, b) => (a.id > b.id) ? 1 : -1);

    const printData = {
      questionsType: template && template.questionsType ? template.questionsType : '',
      inspectionSummary: {
        title,
        name,
        assignDate: formatDateToDisplay(assignDate),
        completedDate: formatDateToDisplay(completedDate),
      },
      questions: questionArray,
      quizBody,
      templateCategory : template.templateCatagory
    }
    return printData;
  }

  const getCertificationReviewRecordURL = async (orgId, taskId, userName) => {
    try {
      const taskResponse = await getTaskInfor(orgId, taskId, true);

      if (taskResponse) {

        const templateId = JSON.parse(taskResponse.TaskDescription).templateId;
        // Load template questions
        const inspectionTemplateData = await getCertificationTemplate(orgId, templateId);
        console.log('inspectionTemplateData', inspectionTemplateData)
        setTemplateCategory(inspectionTemplateData.templateCatagory);
        const printData = await buildPrintData(orgId, inspectionTemplateData, taskResponse, userName);

        return printData;
      }
      return null;
    } catch (e) {
      console.error(e);
      message.error('Error in getting Audubon Certification record. Please retry.');
    }
  }

  const downloadReviewRecordURL = async (inspectionRecord) => {
    try {
      setSelectedTaskId(inspectionRecord.task_id);
      setIsLoading(true);
      setRecordViewerVisible(true)
      const data = await getCertificationReviewRecordURL(orgId, inspectionRecord.task_id, inspectionRecord.user_name);
      await downloadDocument(data);
      setIsLoading(false);
      setSelectedTaskId();
    } catch (e) {
      console.error(e)
      setIsLoading(false);
      message.error('Error in getting document content. Retry later');
    }
  }

  const getCommentsView = async (inspectionRecord) => {
    try {
      const certCommentsMap = await getCommentsForOUAndTaskType(orgId, orgUnitId, certType, TASK_TYPE.CERTIFICATION);
      if (certCommentsMap) {
        setCommentsMap(certCommentsMap);
      }
      setSelectedTaskId(inspectionRecord.task_id);
      setCommentsViewerVisible(true)
    } catch (e) {
      console.error(e)
      setIsLoading(false);
      message.error('Error in getting Feedback content. Retry later');
    }
  }

  const downloadDocument = async (data) => {
    let path = data.questionsType === QUESTIONS_CATEGORY ? data.templateCategory === CERTIFICATION_CATEGORY.DYNAMIC_CERTIFICATION ? `reports/inspections/dynamic_inspections/inspection_report_en.html` : `reports/inspections/new_inspections/inspection_report_en.html` : `reports/inspections/inspection_report_en.html`;
    const template = await resolveTemplate(path);
    const compiledTemplate = handlebars.compile(template);
    let input = compiledTemplate(data);

    if (data.templateCategory === CERTIFICATION_CATEGORY.DYNAMIC_CERTIFICATION) {
      const parser = new DOMParser()
      const HTMLDoc = parser.parseFromString(input, 'text/html');
      const selectedTag = HTMLDoc.getElementById('question_body');
      selectedTag.innerHTML = data.quizBody;
      input = HTMLDoc.documentElement.innerHTML;
    }

    setRecordViewInner(input);
  }

  const onCommentModalClose = (newStatus) => {
    //update the tasks list
    if (newStatus) {

      let dataToUpdate = (newStatus === STATUS.DONE) ? {status: newStatus, completed_at : new Date().toISOString()} : {status: newStatus} ;

      const updatedInspections = inspectionList.map(item =>
        item.task_id !== selectedTaskId ? item : { ...item, ...dataToUpdate }
      )
      setInspectionList(updatedInspections);
    }
    setCommentsViewerVisible(false);
    setSelectedTaskId();
  }

  const selectedTaskStatus = inspectionList.find(item => item.task_id === selectedTaskId) ? inspectionList.find(item => item.task_id === selectedTaskId).status : "";

  return (
    <>
      {
        recordViewerVisible && <RecordViewerModal
          onClose={() => setRecordViewerVisible(false)}
          reportData={recordViewInner}
          isLoading={isLoading}
          reportName="certification_report"
          title={'Certification Record'}
        />
      }
      {
        commentsViewerVisible && <CommentsViewerModal
          onClose={onCommentModalClose}
          isLoading={isLoading}
          orgId={orgId}
          orgUnitId={orgUnitId}
          certType={certType}
          taskType={TASK_TYPE.CERTIFICATION}
          taskId={selectedTaskId}
          commentsThread={commentsMap.get(selectedTaskId)}
          taskStatus={selectedTaskStatus}
          orgName={orgName}
          updatRecordList={props.updateCertList}
        />
      }
      <div>
        <div className="table-wrapper">
          <Table dataSource={inspectionList} columns={columns} />
        </div>
      </div>
    </>
  )
}