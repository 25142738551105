import React from 'react';
import { Descriptions } from 'antd';
import _ from 'lodash';
import { emptyContent, getFieldValue, lblStyleLengthyDesc } from '../form-actions/FormActions';

export const AdditionalInformation = ({ additionalInfoForm }) => {

  return (
    <>
      {_.isEmpty(additionalInfoForm) ? emptyContent
        : <Descriptions title='Additional Details' bordered {...lblStyleLengthyDesc}>
          <Descriptions.Item label='What do you hope to gain, either for yourself or your golf course, as a result of participating in the ACSP?' span={3}>
            {getFieldValue(additionalInfoForm.gain)} <br />
          </Descriptions.Item>
          <Descriptions.Item label='What special conditions are required by law/permit that affect the golf course (e.g., endangered species protection, wetland mitigation, pond lining, no pesticide use)?' span={3}>
            {getFieldValue(additionalInfoForm.specialConditions)}
          </Descriptions.Item>
          <Descriptions.Item label='Are there any pressing local or regional environmental issues or concerns that you feel need to be addressed as part of your participation in the program? What are they?' span={3}>
            {getFieldValue(additionalInfoForm.externalConcerns)} <br />
          </Descriptions.Item>
          <Descriptions.Item label='If there is any controversy surrounding the impacts of building or maintenance of the golf course on the local environment, founded or unfounded, please provide a brief 
                  description of the situation. (This information helps us provide you with appropriate recommendations.)' span={3}>
            {getFieldValue(additionalInfoForm.controversySurrounding)}
          </Descriptions.Item>
        </Descriptions>
      }
    </>
  )
}