import React, { createContext, useReducer } from 'react';
import { Map as IMap } from 'immutable';

const ACTIONS = {
    LOGGED_IN: "logged_in"
}

export const SESSION_ATTRS = {
    LOGGED_USER: "loggedUser"
}

const initialState = IMap({
    [SESSION_ATTRS.LOGGED_USER]: {}
});


const sessionStore = createContext({ state: initialState, dispatch: () => null });
const { Provider } = sessionStore;

const SessionStateProvider = ({ children }) => {

    const [state, dispatch] = useReducer((state, action) => {
        const { type, payload } = action;
        switch (type) {
            case ACTIONS.LOGGED_IN:
                return state.merge(IMap({
                    [SESSION_ATTRS.LOGGED_USER]: payload
                }));
            default:
                throw new Error("action is not defined");
        }
    }, initialState);

    return <Provider value={{ state, dispatch }}  >{children}</Provider>;
};

export { sessionStore, SessionStateProvider, ACTIONS };