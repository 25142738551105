import React from 'react';
import ReactDOM from 'react-dom';
import { Divider } from 'antd';
import { DYNAMIC_FIELD_QUESTION_TYPES, DYNAMIC_FORM_FIELDS } from '../review/constants';
import { isImage, viewQuestions } from '../utils/common-utils'
import _ from 'lodash';

export const generatePrintView = async (template, answers) => {
    const view = template.questions.map((list, i) => (
        renderQuestion(list, i, answers)
    ));
    const hideElement = document.createElement('div');
    ReactDOM.render(<div>{view}</div>, hideElement);
    return hideElement.innerHTML;
}

const renderQuestion = (field, i, answers) => {
    if(field.questionType === DYNAMIC_FIELD_QUESTION_TYPES.STRUCTURED) {
        return renderComplexStructure(field, i, answers);
    }
    else {
        return generateDynamicQuestion(field, answers);
    }
}

const renderComplexStructure = (field, index, answers) => {
   if(field.category) {
        return( 
            <div className="panel panel-primary" key={index}>
                <div className="row">
                    <div className="col-md-12">
                        <h4 style={{fontWeight: 600, fontSize: 20, whiteSpace: 'nowrap', width:"100%"}} class="text-left">{field.category}</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <p style={{fontWeight: 600, fontSize: 14, whiteSpace: 'wrap'}} class="text-left">{field.description && field.description}</p>
                    </div>
                </div>
                    {field.questions.map((subQuestion, i) => (
                        renderComplexStructure(subQuestion, i, answers)
                    ))}
                </div> 
            );
    }
    else {
        switch (field.type) {
            case DYNAMIC_FORM_FIELDS.SECTION:
                return renderSectionComponent(field, index, answers);
            case DYNAMIC_FORM_FIELDS.INLINE_STRUCTURED:
                return renderInlineStructue(field, answers);
            case DYNAMIC_FORM_FIELDS.MULTI_ROW_STRUCTURED:
                return renderMultiRowStructure(field, answers);
            default:
                return renderSubQuestions(field, index, answers);
        }
    }
}

const showComment = (field, response, id = null) => (
    <div className="row">
        <div class="col-md-3">
            <b>Comments :</b>
        </div>
        <div>
            <div class="col-md-9">
                {response[id ? id : field.id] && response[id ? id : field.id].comment}
            </div>
        </div>
    </div>
)

const showAttachments = (field, response) => (
    <div className="row">
        <div class="col-md-3">
            <b>Attachments :</b>
        </div>
        <div>
            <div class="col-md-9">
                {response[field.id] && response[field.id].attachmentKeys && renderAttachments(response[field.id].attachmentKeys)}
            </div>
        </div>
    </div>
)

const renderSubQuestions = (field, i, answers) => {
    return <div>{renderQuestion(field, i, answers)}</div>;
}

const renderSectionComponent = (field, i, answers) => {
    if(field.questions) {
        return (
            <div className="panel panel-primary" key={i} style={{marginRight: 5}}>
                <div className="row">
                    <div className="col-md-12">
                        <h4>{field.question && field.question}</h4>
                        <div>{field.description && field.description}</div> 
                    </div>
                </div> 
                {field.questions.map(group=> {
                    return renderSubQuestions(group, i, answers);
                })}
                {answers[field.id] && answers[field.id].comment && showComment(field, answers)}
                {answers[field.id] && answers[field.id].attachments && showAttachments(field, answers)}
            </div>
        );
    }
}

const renderInlineStructue = (field, answer) => {
    return (
        <div key={field.questionId} className="panel panel-primary" style={{marginRight: 5}}>
            <div key={field.questionId} className="row">
                <div className="col-md-12">
                    <i>{viewQuestions(field)}</i>
                    <p>{field.description && field.description}</p>
                </div>
            </div>
            <div className="row">
            {field.questions.map((question, i) => (
                renderQuestion(question, i, answer)
            ))}
            </div>
            {answer[field.id] && answer[field.id].comment && showComment(field, answer)}
            {answer[field.id] && answer[field.id].attachments && showAttachments(field, answer)}
        </div>
    );
}

const renderMultiRowStructure = (field, answer) => {
    const answers = answer[field.id] ? answer[field.id] : [{}];
    return (
        <div key={field.questionId} className="panel panel-primary" style={{marginRight: 5}}>
            <div className="row">
                <div className="col-md-12">
                    <i>{viewQuestions(field)}</i>
                    <div>{field.description && field.description}</div>
                </div>
            </div>
            { answers.map((answer, i) => (
                <div className="row">
                    {generateMultiRow(field.questions, answer, i+1)}
                    {answers.length !== (i + 1) && <Divider/>}
                </div>
            ))}
            {answer[field.id] && answer[field.id].comment && showComment(field, answer)}
            {answer[field.id] && answer[field.id].attachments && showAttachments(field, answer)}
        </div>
    );
}

const generateDynamicQuestion = (field, answer) => {
    switch (field.type) {
        case DYNAMIC_FORM_FIELDS.DIVIDER:        
            break;
        case DYNAMIC_FORM_FIELDS.UPLOAD:
            return renderUploadItem(field, answer);
        case DYNAMIC_FORM_FIELDS.POINT_SECTION:
            return renderPointSection(field);
        default:
            return renderQuizItem(field, answer);
    }
}

const renderUploadItem = (field, answer) => (
    <div className="row">
        <div className="col-md-12">
            <i>{viewQuestions(field)}</i>
            <p>{field.description && field.description}</p>
            {answer[field.id] && answer[field.id].attachmentKeys && renderAttachments(answer[field.id].attachmentKeys)}
        </div>
    </div>
)

const renderAttachments = (attachments) => {
    if(!attachments || !attachments.length) {
        return;
    }
    return attachments.map(function(attachment) { 
        if (isImage(attachment.name)) {
            return (
                <div className="image-row">
                    <div>
                        <img alt="" class="p-img" src={attachment.url} crossOrigin="Anonymous" />
                    </div>
                </div>
            )
        } else {
            return ( 
                <div>
                    <a href={attachment.url} target="_blank" rel="noreferrer" style={{color: '#40a9ff'}}>{attachment.name}</a>
                </div> 
            )
        }
    })
}

const  renderPointSection = (field) => (
    <div key={field.questionId} className="panel panel-primary" style={{marginRight: 5}}>
        <div className="row">
            <div className="col-md-12">
                <i>{field.question}</i>
                <p>{field.description && field.description}</p>
                <ul> 
                    {field.points.map((point, index)=> {
                        return <li key={index}>{point}</li>
                    })}
                </ul> 
            </div>
        </div>   
    </div>
);

const renderQuizItem = (field, answer) => (
    <div key={field.questionId} className="panel panel-primary" style={{marginRight: 5, paddingTop: 10, paddingBottom: 10}}>
        <div className="row">
            <div className="col-md-12">
                <i>{viewQuestions(field)}</i>
                {selectResponse(field, answer)}
                {answer[field.id] && answer[field.id].comment && showComment(field, answer)}
                {answer[field.id] && answer[field.id].attachments && showAttachments(field, answer)}
            </div>
        </div>
    </div>
);

const selectResponse = (field, answer) => {
    switch (field.type) {
        case DYNAMIC_FORM_FIELDS.INPUT:
        case DYNAMIC_FORM_FIELDS.TEXT_AREA:
        case DYNAMIC_FORM_FIELDS.BUTTON_GROUP:
            return inputView(answer, field.id);
        case DYNAMIC_FORM_FIELDS.INPUT_GROUP:
        case DYNAMIC_FORM_FIELDS.TOGGLE_GROUP:
        case DYNAMIC_FORM_FIELDS.SELECT_GROUP:
            return inputGroupView(answer, field);
        case DYNAMIC_FORM_FIELDS.CHECKBOX_GROUP:
            return checkBoxGroupView(answer, field);
        case DYNAMIC_FORM_FIELDS.MULTI_SELECT_GROUP:
            return renderMultiRowList(answer[field.id] ? answer[field.id] : [], field);
        default:
            break;
    }
}

const inputView = (response, id) => (
    <div class="row">
        <div class="col-md-3">
            <b>Response :</b>
        </div>
        <div class="col-md-9">
            {response[id] && response[id].answer ? response[id].answer: 'No'}
        </div>
    </div>
);
const toggleAnswer = (answer, response) => {
    return( response[answer.id]  && response[answer.id].answer)
        ? 'Yes' : 'No';
}

const inputGroupView = (response, field) => (
    <div className="row">
        <div className="col-md-3">
            <b>Response :</b>
        </div>
        <div className="multi-answer-container">
        {
            field.answers.map(answer => (
                <div className="col-md-9 input-group-container">
                    <div>{answer.label} - </div>
                    <div className="answer-field">
                        {
                            answer.type === "toggle" ? toggleAnswer(answer, response) :
                                response[answer.id] && response[answer.id].answer
                        }
                    </div>
                </div>
            ))
        }
        </div>
    </div>
);

const checkBoxGroupView = (response, field) => (
    <div className="row">
        <div className="col-md-3">
            <b>Response :</b>
        </div>
        <div>
            <ul className="multi-answer-container">
                { filterCheckBoxValues(response[field.id] ? response[field.id].answer : [], field.answers) }
            </ul>
        </div>
    </div>
);

const filterCheckBoxValues = (answers, rows) => {
    const selectedRows = rows.filter(row => answers.includes(row.id));
    return selectedRows.map((row, i)=> (
        <li key={i}>{row.label}</li>
    ));
}

const generateMultiRow = (questions, answers, i) => {
    return (
        <div>
            { questions.map((field, index) => (
                <div>
                    <div key={index} className="row" style={{marginTop: 5}}>
                        <div className="col-md-6">
                            <i>{viewQuestions(field)}</i>
                        </div>
                        <div className="col-md-6">
                            {answers[`${field.id}_${i}`] && SelectMultiRowQuizAnswers(field, answers[`${field.id}_${i}`])}
                        </div>
                    </div>
                    {answers[`${field.id}_${i}`] && answers[`${field.id}_${i}`].comment && showComment(field, answers, `${field.id}_${i}`)}
                </div>
            )) }
        </div>
    );
}

const SelectMultiRowQuizAnswers = (field, answer) => {
    switch (field.type) {
        case DYNAMIC_FORM_FIELDS.INPUT:
        case DYNAMIC_FORM_FIELDS.TEXT_AREA:
        case DYNAMIC_FORM_FIELDS.BUTTON_GROUP:
            return answer  ? answer.answer : "";
        case DYNAMIC_FORM_FIELDS.INPUT_GROUP:
        case DYNAMIC_FORM_FIELDS.TOGGLE_GROUP:
        case DYNAMIC_FORM_FIELDS.SELECT_GROUP:
            return renderMultiAnswers(answer, field);
        case DYNAMIC_FORM_FIELDS.CHECKBOX_GROUP:
        case DYNAMIC_FORM_FIELDS.MULTI_SELECT_GROUP:
            return renderMultiRowList(answer, field);
        default:
            break;
    }
}

const renderMultiAnswers = (response, field) => (
    field.answers.map(answer => (
        <div className="input-group-container">
            <div>{answer.label} - </div>
            <div className="answer-field">
                {
                    answer.type === "toggle" ? toggleAnswer(answer, response) :
                        response[answer.id] && response[answer.id].answer
                }
            </div>
        </div>
    ))
);

const renderMultiRowList = (response, field) => {
    return (
        <ul>
            { filterCheckBoxValues(response && response.answer ? response.answer : response ? response : [], field.answers) }
        </ul>
    )
}