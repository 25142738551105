import React, { useEffect, useState } from 'react';

import { Table, message, Button, Spin, Card, Row, Col } from 'antd';

import { openFile } from '../utils/doc-utils'
import { getTag, getProgressIcon, formatDateToDisplay } from '../utils/common-utils'
import { getDocumentReviewRecordURL } from './actions';
import { STATUS, DOC_TYPE } from '../utils/constants';

export const AudubonDocumentListView = (props) => {

    const [documentsList, setDocumentsList] = useState([]);
    const [orgId, setOrgId] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTaskId, setSelectedTaskId] = useState();
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        let docProgress = props.data;
        let docRecords = docProgress && docProgress.records ? docProgress.records.filter(rec=>rec.is_preliminary === (props.isSiteAssessment ? DOC_TYPE.PRELIMINARY : DOC_TYPE.CERTIFICATION)).sort((a, b) => a.doc_title.localeCompare(b.doc_title)) : []
        setDocumentsList(docRecords);
        setProgress(props.progress);
        setOrgId(props.orgId);

        const docsPercent = (docProgress.totalTasks > 0 && docProgress.totalRecords > 0) ? ((docProgress.completedRecords + (docProgress.inprogressRecords*.5)) * docProgress.assignedTasks) / (docProgress.totalRecords * docProgress.totalTasks) : 0;
        setProgress(docsPercent * 100);
    }, [props]);

    const downloadReviewRecordURL = async (docRecord) => {

        try {
            setIsLoading(true);
            setSelectedTaskId(docRecord.task_id);
            const url = await getDocumentReviewRecordURL(orgId, docRecord.task_id);
            setIsLoading(false);
            openFile(url)
            setSelectedTaskId();
        } catch (e) {
            console.error(e)
            message.error('Error in getting document content. Retry later');
        }

    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'doc_title',
            render: (text, record) => record.doc_title
        },
        {
            title: 'User',
            dataIndex: 'name',
            render: (text, record) => record.name
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text, record) => getTag(record.status)
        },
        {
            title: 'Assigned Date',
            dataIndex: 'created_at',
            key: 'task_id',
            render: (_, record) => { if (record.created_at) { return formatDateToDisplay(record.created_at) } }
        },
        {
            title: 'Completed Date',
            dataIndex: 'completed_at',
            key: 'task_id',
            render: (_, record) => { if (record.completed_at && record.status === STATUS.DONE) { return formatDateToDisplay(record.completed_at) } }
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                if (record.status === STATUS.DONE || record.status === STATUS.PENDING_RESPONSE || record.status === STATUS.REWORK_INPROGRESS) {
                    return <><Button data-testid={`${record.task_id}-doc-download`} type="link" onClick={() => downloadReviewRecordURL(record)}
                        icon={
                            isLoading && selectedTaskId === record.task_id ? (
                                <Spin size="small"
                                />
                            ) : null
                        }> &nbsp;View </Button>
                        </>
                } else {
                    return <Button type="link" disabled> Not Ready</Button>
                }
            }
        }
    ];

    const cardTitle = <Row><Col span={12}>Document Records</Col><Col span={12} style={{ textAlign: 'right' }}>{getProgressIcon(progress)}</Col></Row>;

    return (
        <>
        {<Card title={!props.isSiteAssessment ? cardTitle : ''}>
            <div>
                <div className="table-wrapper">
                    {<Table dataSource={documentsList} columns={columns} />}
                </div>
            </div>
        </Card>}
        </>
    );

}