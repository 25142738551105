import * as ol from 'ol';
import * as layer from 'ol/layer';
import * as source from 'ol/source';
import {Circle as CircleStyle, Fill, Stroke, Style, Text, Icon} from 'ol/style';
import { Control } from 'ol/control';
import GeoJSON from 'ol/format/GeoJSON';
import {fromLonLat} from 'ol/proj';
import Draw from 'ol/interaction/Draw';
import * as proj from 'ol/proj';
import Feature from 'ol/Feature.js';
import {Point} from 'ol/geom.js';
import LayerSwitcher from 'ol-layerswitcher';
import Geocoder from 'ol-geocoder';
import { isMobile } from '../utils/common-utils'; 
import { SITE_VALUE_TYPES } from './constants'

const getTransparentColor = (hexColor) => {
  return hexColor + 'A6';
};

const style = new Style({
  fill: new Fill({
    color: getTransparentColor('#007bff')
  }),
  stroke: new Stroke({
    color: 'white',
    width: 1
  }),
  image: new CircleStyle({
    radius: 7,
    fill: new Fill({
      color: getTransparentColor('#007bff')
    }),
  })
})

const getText = function(feature) {
  var text = feature.get('name');
  return text;
};

const createTextStyle = function(feature) {
  return new Text({
    font: '10px Verdana',
    text: getText(feature),
    fill: new Fill({color: 'black', width: 2}),
    stroke: new Stroke({color: 'white', width: 2})
  });
};

const getPointIconImageSrc = () => {
  const svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 413.099 413.099" style="enable-background:new 0 0 413.099 413.099;" xml:space="preserve">	<g>		<g>			<path d="M206.549,0L206.549,0c-82.6,0-149.3,66.7-149.3,149.3c0,28.8,9.2,56.3,22,78.899l97.3,168.399c6.1,11,18.4,16.5,30,16.5    c11.601,0,23.3-5.5,30-16.5l97.3-168.299c12.9-22.601,22-49.601,22-78.901C355.849,66.8,289.149,0,206.549,0z M206.549,193.4    c-30,0-54.5-24.5-54.5-54.5s24.5-54.5,54.5-54.5s54.5,24.5,54.5,54.5C261.049,169,236.549,193.4,206.549,193.4z" fill="orangered" />		</g>	</g>	<g>	</g>	<g>	</g>	<g>	</g>	<g>	</g>	<g>	</g>	<g>	</g>	<g>	</g>	<g>	</g>	<g>	</g>	<g>	</g>	<g>	</g>	<g>	</g>	<g>	</g>	<g>	</g>	<g>	</g></svg>';
  const svgSrc = 'data:image/svg+xml,' + escape(svg);
  return svgSrc;
}

const geocoder = new Geocoder('nominatim', {
  provider: 'osm',
  lang: 'en',
  placeholder: 'Address',
  limit: isMobile() ? 2 : 5,
  debug: false,
  autoComplete: true,
  keepOpen: false
});

export const styleFunction = (feature, resolution) => {
  let featureStyle = style;
  featureStyle = new Style({
    fill: new Fill({
      color: 'red'
    }),
    stroke: new Stroke({
      color: 'white',
      width: 1
    }),
    text: createTextStyle(feature),
    image: new Icon(({
      src: getPointIconImageSrc(),
      scale: 1
    }))
  });
  return featureStyle;
}

// ----------------- --------------------- ---------------

export function getDrawnFeaturesJson(features){
  const geojson = new GeoJSON();
  return geojson.writeFeaturesObject(features);
}

export function getDrawnFeatureJson(feature){
  const geojson = new GeoJSON();
  return geojson.writeFeatureObject(feature);
}

export function AddLocationControl(optOptions, handleClick) {
  var options = optOptions || {};

  var button = document.createElement('button');
  button.innerHTML = 'Add';
  button.className = 'epar__mapcontrol__add-location__button'

  button.addEventListener('click', handleClick, false);
  button.addEventListener('touchstart', handleClick, false);

  var element = document.createElement('div');
  element.className = 'epar__mapcontrol__add-location__wrapper ol-unselectable ol-control';
  element.appendChild(button);

  Control.call(this, {
    element: element,
    target: options.target
  });
}

export function createDraftFeatureLayer(customStyleFunction, title){
  return new layer
    .Vector({
      title: (title) ? title : '',
      source: new source.Vector({wrapX: false}),
      zIndex: 5,
      style: (customStyleFunction) ? customStyleFunction : styleFunction
    });  
}

export function createAddressSearch(map){
  // bind address search to map
  map.addControl(geocoder);
}

export function clearAddress(){
  const geocoderSource = geocoder.getSource();
  geocoderSource.clear();
}

export function getMapInstance(options, wrapX = false){
  // create map object with feature layer
  var googleLayer =new layer.Tile({
    title: "Google Satellite",
    source: new source.TileImage({ url: 'http://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}' }),
    visible: false
  });
  var googleLayerHybrid =new layer.Tile({
    title: "Google Satellite POI",
    source: new source.TileImage({ url: 'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}' }),
    visible: false
  });

  const map = new ol.Map({
    target: options.mapRef.current,
    layers: [
      //default OSM layer
      // arcgisGrayScaleLight,
      new layer
        .Tile({
          source: new source
            .OSM({wrapX})
        }),
      googleLayer,
      googleLayerHybrid,
      options.draftFeaturesLayer
    ],
    view: new ol.View({
      center: [
        14891779.49, -2909486.16
      ],
      zoom: 10
    })
  });  

  return map;
}

export function addLayerSwitcher(map){

  var layerSwitcher = new LayerSwitcher({
    groupSelectStyle: 'children' // Can be 'children' [default], 'group' or 'none'
  });
  map.addControl(layerSwitcher);
}

export function initAddPoint(options){

  const pointStyle = new Style({
    stroke: new Stroke({
      color: 'red',
      width: 5
    }),
    fill: new Fill({
      color: 'red'
    }),
    image: new Icon(({
      src: getPointIconImageSrc(),
      scale: 1
    }))
  });

  const draw = new Draw({
    source: options.draftFeatureLayer.getSource(),
    type: 'Point',
    style: pointStyle,
    geometryName: 'TODO:Hazard id'
  });

  draw.on('drawend', options.handleDrawEnd);

  options.map.addInteraction(draw);
  return draw;
}

export function endDrawing(options){
  options.map.removeInteraction(options.draw);
}

export function clearFeatures(options){
  const source = options.draftFeatureLayer.getSource();
  source.clear();
}

export function clearFeature(options, feature){
  const source = options.draftFeatureLayer.getSource();
  source.removeFeature(feature);
}

export function zoomToCurrentUserLocation(map, level = 17){

  if(navigator && navigator.geolocation) {
    try {
      var geoSuccess = function(pos) {
        const coords = fromLonLat([pos.coords.longitude, pos.coords.latitude]);
        map.getView().animate({center: coords, zoom: level});
      };
      var geoError = function(error) {
        console.error(error);
        switch(error.code) {
          case error.TIMEOUT:
            // The user didn't accept the callout
            break;
          default:
            break;
        }
      };
    
      navigator.geolocation.getCurrentPosition(geoSuccess, geoError, { enableHighAccuracy: true });
    }
    catch(e){
      console.error(e);
      console.log('Unable to navigate to current user location');
      map.getView().animate({center: [133.7751312, -25.2743988], zoom: level});
    }
  }

}

/**
 * Zoom to the extent of the points.
 * @param {*} map 
 * @param {*} points 
 */
export function zoomToPoints(map, layer){
  const extent = layer.getSource().getExtent();
  setTimeout(() => {
    map.getView().fit(extent, map.getSize());
    map.getView().setZoom(12);
  },200);
}

export function getPointStyle(color){
  const pointStyle = new Style({
      stroke: new Stroke({
          color: getTransparentColor(color),
          width: 5
      }),
      fill: new Fill({
          color: getTransparentColor(color) // 80 to make it 50% transparent in hex
      }),
      image: new Icon(({
          src: getPointIconImageSrc(getTransparentColor(color)),
          scale: 1
      }))
  });

  return pointStyle;
}

export function convertToFlatCoordinates(lon, lat){
  return proj.transform([lon, lat], 'EPSG:4326','EPSG:3857');
}

export function createPoint(lon, lat, color){
  const feature =  new Feature({
    'geometry': new Point(convertToFlatCoordinates(lon, lat)),
    'size': 10
  });
  const transparentColor = getTransparentColor(color);
  feature.setStyle(new Style({
    image : new CircleStyle({
      radius: 5,
      fill : new Fill({ color : transparentColor}),
      stroke: new Stroke({color : transparentColor, width : 3 })
    })
  }));
  return feature;
}

export function createPointWithText(lon, lat, color, text, textColor){
  const feature =  new Feature({
    'geometry': new Point(convertToFlatCoordinates(lon, lat)),
    'size': 10
  });
  const transparentColor = getTransparentColor(color);
  feature.setStyle(new Style({
    image : new CircleStyle({
      radius: 7,
      fill : new Fill({ color : transparentColor}),
      stroke: new Stroke({color , width : 2 })
    }),
    text : new Text({
      text: text,
      fill: new Fill({
        color: textColor
      })
    })
  }));
  return feature;
}

export function createVariousPoint(lon, lat, color, radius){
  const feature =  new Feature({
    'geometry': new Point(convertToFlatCoordinates(lon, lat)),
    'size': 10
  });
  const transparentColor = getTransparentColor(color);
  feature.setStyle(new Style({
    image : new CircleStyle({
      radius: radius,
      fill : new Fill({ color : transparentColor}),
      stroke: new Stroke({color : color, width : 2 })
    })
  }));
  return feature;
}

export function getPointImage(type) {
    return SITE_VALUE_TYPES.find(svt => svt.key === type).icon;
}

export function getPointStyleFunction(feature){
    const type = feature.get('type');
    if(!type) return styleFunction(feature);
    let color = '#FFFFFF';
    const pointStyle = new Style({
        stroke: new Stroke({
            color: getTransparentColor(color),
            width: 5
        }),
        fill: new Fill({
            color: getTransparentColor(color) // 80 to make it 50% transparent in hex
        }),
        image: new Icon(({
            src: `/icons/site-value/${getPointImage(type)}`,
            scale: 0.08,
            anchor: [0.5, 0.96],
        }))
    });
  
    return pointStyle;
  }
  