import { useTranslation } from 'react-i18next';

const InactiveUserView = () => {
    
    const { t } = useTranslation();

    return (
        <div className="banner-body">
            <div className="banner">
                {t("Sorry, you are not authorized to access this application. Please contact an admin.")}
            </div>
        </div>
    );
}

export default InactiveUserView;