import React, { useEffect, useState } from 'react';
import { Card, Space, TreeSelect, Row, Col, Select, Spin, Button, Typography, Steps, Input, message, Modal } from 'antd';
import { AudubonOrganisationDetailsView } from './AudubonOrganisationDetailsView';
import { AudubonDocumentListView } from './AudubonDocumentListView';
import { AudubonCertificationListView } from './AudubonCertificationListView';
import { AudubonSiteValuesMapView } from './AudubonSiteValuesMapView';
import { getOrgsList, getOrgUnitsList, getAudubonCertificationDetails, getAudubonDocumentDetails, getAudubonOrgDetails, getCertDetails, updateCertDetails, getMemberDetails, getAudubonCaseStudy } from './actions'
import { buildOUTree, isMobile } from '../utils/common-utils'
import { STATUS, CERT_ARRAY, STEPS_BAR, CERTIFICATION_STATUS, CERTIFICATION_STATUS_BY_NAME, CERTIFICATION_TYPE, DOC_TYPE } from '../utils/constants'
import { IssueCertificateModal } from './IssueCertificateModal'
import { AudubonDetails } from './AudubonDetails';
import { AudubonCaseStudyListView } from './AudubonCaseStudyListView';
import { AudubonSiteAssessmentListView } from './AudubonSiteAssessmentListView';
import { PreCertification } from './review-page/PreliminaryCertification';
import { CompleteCertification } from './review-page/CompleteCertification';
import { AudubonSiteVisit } from './AudubonSiteVisit';
import { IssuedCertificateDetailsModal } from './IssuedCertificateDetailsModal';

const { Text } = Typography;
const { Step } = Steps;
const { Search } = Input;

export const ReviewPage = ({ orgId, orgUnitId, certificate }) => {

    const [selectedOrgUnit, setSelectedOrgUnit] = useState();
    const [selectedOrg, setSelectedOrg] = useState();
    const [selectedCertificate, setSelectedCertificate] = useState();
    const [orgListOptions, setOrgListOptions] = useState([]);
    const [certCategoryOptions, setCertCategoryOptions] = useState([]);
    const [orgUnitListOptions, setOrgUnitListOptions] = useState([]);
    const [certificationProgressList, setCertificationProgressList] = useState([]);
    const [docProgressList, setDocProgressList] = useState([]);
    const [audubonOrgDetails, setAudubonOrgDetails] = useState({});
    const [isDetailsReady, setIsDetailsReady] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [issueCertModalOpen, setIssueCertModalOpen] = useState(false);
    const [editAudubonDetailsOpen, setEditAudubonDetailsOpen] = useState(false);
    const [membershipId, setMembershipId] = useState();
    const [certStatus, setCertStatus] = useState(0);
    const [isButtonLoading, setButtonLoading] = useState(false);
    const [s3FileKey, sets3FileKey] = useState(false);
    const [isSearching, setSearching] = useState(false);
    const [isSearchOuNotFound, setIsSearchOuNotFound] = useState(false);
    const [memberDetails, setMemberDetails] = useState();
    const [serchText, setSerchText] = useState('');
    const [courseName, setCourseName] = useState('');
    const [audubonCaseStudy, setAudubonCaseStudy] = useState({});
    const [selectedOrgName, setSelectedOrgName] = useState('');
    const [siteAssessmentCount, setSiteAssessmentCount] = useState({allRecords : 0, completedRecords : 0});
    const [audubonSiteVisitOpen, setAudubonSiteVisitOpen] = useState(false);
    const [issuedCertDetailModalOpen, setIssuedCertDetailModalOpen] = useState(false);
    const [certificationDetails, setCertificationDetails] = useState();

    const { Option } = Select;

    useEffect(() => {
        setOrgUnitListOptions([]);
        if (selectedOrg) {
            if (!orgId && !isSearching) {
                setSelectedOrgUnit(null);
            }
            populateOrgsUnitList();
        }
    }, [selectedOrg]);

    useEffect(async() => {
        setIsDetailsReady(false);
        if (selectedOrgUnit && selectedCertificate) {
            postOrgUnitSelect();
        }
    }, [selectedOrgUnit, selectedCertificate, selectedOrg]);

    useEffect(() => {
        populateOrgsList();
        if (orgId) {
            setSelectedOrg(orgId);
        }

        if (orgUnitId) {
            setSelectedOrgUnit(orgUnitId);
        }

        populateCertCategories();

        if (certificate) {
            setSelectedCertificate(certificate);
        }

    }, []);

    useEffect(() => {
        if(selectedOrg && orgListOptions.length > 0) {
            let org_name = orgListOptions.find(i => i.props.value === selectedOrg).props.children;
            setSelectedOrgName(org_name);
        }
    }, [orgListOptions, selectedOrg]);

    const populateOrgsList = async () => {
        const orgs = await getOrgsList();
        orgs.sort((a, b) => a.name.localeCompare(b.name));
        const orgOpts = orgs.map((d, i) => <Option key={`${d.org_id}_${i}`} value={d.org_id}>{d.name}</Option>);
        setOrgListOptions(orgOpts);
    }

    const populateCertCategories = () => {
        const certCategoryList = CERT_ARRAY.map(certificate => <Option key={certificate.id} value={certificate.id}>{certificate.name}</Option>);
        setCertCategoryOptions(certCategoryList);
    }

    const populateOrgsUnitList = async () => {
        var orgUnits = await getOrgUnitsList(selectedOrg);
        if(orgUnits.length > 0) {
            const ouTree = buildOUTree(orgUnits, [], selectedOrg);
            setOrgUnitListOptions(ouTree);
        }        
    }
    
    const refreshAudubonOrgDetails = async () => {
        const memberDetails = await getMemberDetails(selectedOrg, CERTIFICATION_TYPE, selectedOrgUnit);
        setMemberDetails(memberDetails);
    }

    const postOrgUnitSelect = async () => {

        setIsLoading(true);
        setSiteAssessmentCount({allRecords : 0, completedRecords : 0})
        const [insProgress, docProgress, orgDetails, certDetails, memberDetails, caseStudy] = await Promise.all([
            populateCertificationList(selectedOrg, selectedOrgUnit, selectedCertificate),
            populateDocumentList(selectedOrg, selectedOrgUnit, selectedCertificate),
            getAudubonOrgDetails(selectedOrg, selectedOrgUnit, selectedCertificate,),
            getCertificationStatus(selectedOrg, selectedOrgUnit, selectedCertificate),
            getMemberDetails(selectedOrg, CERTIFICATION_TYPE, selectedOrgUnit),
            getAudubonCaseStudy(selectedOrg, selectedOrgUnit, selectedCertificate)
        ]);
        setCertificationProgressList(insProgress);
        setDocProgressList(docProgress);
        setAudubonOrgDetails(orgDetails);
        setCertificationDetails(certDetails)
        setMemberDetails(memberDetails);
        setAudubonCaseStudy(caseStudy);
        setCourseName(orgDetails && orgDetails.audubon_details && orgDetails.audubon_details.GeneralInformationForm 
            && orgDetails.audubon_details.GeneralInformationForm.FormDetails.basic_information && orgDetails.audubon_details.GeneralInformationForm.FormDetails.basic_information.courseName 
            ? orgDetails.audubon_details.GeneralInformationForm.FormDetails.basic_information.courseName : "Not Assigned");
        
        const key = certDetails.length > 0 ? certDetails[0].s3_file_key.split('.')[0] : '';
        sets3FileKey(key);
        setCertStatus(certDetails.length > 0 ? certDetails[0].status : 0);
        setMembershipId(memberDetails.length > 0 ? memberDetails[0].member_id : '');
        setIsLoading(false);
        setIsDetailsReady(true);
    }

    const populateCertificationList = async (orgId, orgUnitId, certificate, task) => {

        let response = await getAudubonCertificationDetails(orgId, orgUnitId, certificate);
        let serviceResponse = {};
        serviceResponse.tasks = response;
        serviceResponse.assignedTasks = response.filter(x => x.records.length > 0).length;
        serviceResponse.totalTasks = response.length;
        let records = response.reduce((a, { records, is_preliminary }) => [...a, ...records.map(rec => ({ ...rec, is_preliminary }))], []);

        // incase the task engine is still processing the last request
        if (task) {
            records.forEach(record => {
                if (record.task_id === task) {
                    record.status = STATUS.DONE;
                    record.completed_at = new Date().toISOString();
                }
            });
        }

        if (records.length > 1) {
            //check for duplicates 
            let resultsMap = new Map(records.map(obj => [obj.task_id, obj]));
            records = Array.from(resultsMap.values());
        }
        records.sort(function (a, b) {
            return b.status - a.status
        })

        const premRecords = records.filter(x => x.is_preliminary);

        serviceResponse.records = records;
        serviceResponse.totalRecords = records.length;
        serviceResponse.completedRecords = records.filter(x => x.status === STATUS.DONE || x.status === STATUS.INSPECTION_COMPLETE).length;
        serviceResponse.pendingPreliminaryRecords = premRecords.filter(x => x.status !== STATUS.DONE).length;
        serviceResponse.inprogressRecords = records.filter(x => x.status === STATUS.PENDING_RESPONSE || x.status === STATUS.PENDING_REVIEW || x.status === STATUS.REWORK_INPROGRESS).length;
        return serviceResponse
    }

    const populateDocumentList = async (orgId, orgUnitId, certificate) => {

        var response = await getAudubonDocumentDetails(orgId, orgUnitId, certificate);
        var serviceResponse = {};
        serviceResponse.tasks = response;
        serviceResponse.assignedTasks = response.filter(x => x.records.length > 0).length;
        serviceResponse.totalTasks = response.length;

        var records = response.reduce((a, { records }) => [...a, ...records], []);
        records.sort(function (a, b) {
            return b.status - a.status
        })

        serviceResponse.records = records;
        serviceResponse.totalRecords = records.length;
        serviceResponse.completedRecords = records.filter(x => x.status === STATUS.DONE).length;
        serviceResponse.inprogressRecords = records.filter(x => x.status === STATUS.PENDING_RESPONSE || x.status === STATUS.PENDING_REVIEW || x.status === STATUS.REWORK_INPROGRESS).length;
        return serviceResponse
    }

    const getCertificationStatus = (orgId, orgUnitId, certificate) => {
        return getCertDetails(orgId, orgUnitId, certificate);
    }

    const onOrgSelect = value => {
        if(!isSearching) {
            setSelectedOrgUnit(null);
            setSerchText('');
        } 
        setSelectedOrg(value);
    };

    const onOrgUnitSelect = value => {
        if(!isSearching) setSerchText('');
        setSelectedOrgUnit(value);        
    };

    const onCertSelect = value => {
        setSelectedCertificate(value);
    };

    const onIssueCertClicked = () => {
        setIssueCertModalOpen(true);
    }

    const onCertAdded = async () => {
        setCertStatus(CERTIFICATION_STATUS_BY_NAME.CERTIFIED);
        const certDetails = await getCertificationStatus(selectedOrg, selectedOrgUnit, selectedCertificate);
        setCertificationDetails(certDetails);
    }

    const onIssueCertModalClosed = () => {
        setIssueCertModalOpen(false);
    }


    const onEditContactDetailsClicked = () => {
        setEditAudubonDetailsOpen(true);;
    }

    const onEditContactDetailsModalClosed = () => {
        setEditAudubonDetailsOpen(false);
    }

    const onAudubonDetailsUpdate = (memberNo) => {
        setMembershipId(memberNo)
        refreshAudubonOrgDetails();
    }

    const onReviewClicked = async() => {
        setButtonLoading(true);
        const data = {
            ou_id: selectedOrgUnit, 
            type: selectedCertificate,
            status: CERTIFICATION_STATUS_BY_NAME.PREM_DATA_REVIEW,
            org_name: selectedOrgName
        }

        // validate all the preliminary certifications are complete
        if(certificationProgressList.pendingPreliminaryRecords > 0){ 
            message.warn("There are pending preliminary certifications to approve. Please approve them and try again.");
            setButtonLoading(false);
            return;
        }

        try {
            const response = await updateCertDetails(selectedOrg, data);
            if(response.status) setCertStatus(response.status);
        } catch (e) {
            console.log(e)
        }
        setButtonLoading(false);
    }

    const updateAcceptedCertList = async (taskId) => {
        let newCertificationProgressList = await populateCertificationList(selectedOrg, selectedOrgUnit, selectedCertificate, taskId);
        setCertificationProgressList(newCertificationProgressList);
    }

    const onCertifyClicked = async() => {
        setButtonLoading(true);
        const data = {
            ou_id: selectedOrgUnit, 
            type: selectedCertificate,
            status: CERTIFICATION_STATUS_BY_NAME.CERT_DATA_REVIEW,
            org_name: selectedOrgName
        }

        // validate whether all the documents and certifications are complete
        if (certificationProgressList.totalRecords - certificationProgressList.completedRecords > 0 || docProgressList.totalRecords - docProgressList.completedRecords > 0) {
            message.warn("There are pending certifications to approve. Please approve them and try again.");
            setButtonLoading(false);
            return;
        }

        try {
            const response = await updateCertDetails(selectedOrg, data);
            if (response.status) setCertStatus(response.status);
        } catch (e) {
            console.log(e)
        }
        setButtonLoading(false);
    }

    const updateCertStatus = (content, onOk) => {
        return new Promise(() => {
          Modal.confirm({
            title: "Are you sure?",
            content: content,
            onOk: () => onOk(),
            onCancel() {},
          });
        });
    }

    const summaryExtraPanel = <Row>
            {certStatus === CERTIFICATION_STATUS_BY_NAME.PREM_DATA_SUBMISSION &&
                <Col style={{ marginRight: 10 }}>
                    <Button data-testid={`accept-preliminary-certification`} loading={isButtonLoading} type="primary" 
                        onClick={() =>updateCertStatus("Are you sure you want to approve the Preliminary Certifications Components?", onReviewClicked)}
                        >
                            Approve Preliminary Certification Components
                        </Button>
                </Col>}
            {certStatus === CERTIFICATION_STATUS_BY_NAME.CERT_DATA_SUBMISSION &&
                <Col style={{ marginRight: 10 }}>
                    <Button data-testid={`accept-certification-details`} loading={isButtonLoading} type="primary" 
                        onClick={() =>updateCertStatus("Are you sure you want to approve the Certifications Details?", onCertifyClicked)}
                        >
                        Approve Certification Details
                    </Button>
                </Col>}            
            {certStatus === CERTIFICATION_STATUS_BY_NAME.SITE_VISIT &&
                <Col style={{ marginRight: 10 }}>
                    <Button data-testid={`issue-certification`} type="primary" onClick={onIssueCertClicked}>Issue Certificate</Button>
                </Col>}
            {certStatus === CERTIFICATION_STATUS_BY_NAME.CERTIFIED &&
                <Col style={{ marginRight: 10 }}>
                    <Button data-testid={`certification-details`} type="primary" onClick={() => setIssuedCertDetailModalOpen(true)}>Certification Details</Button>
                </Col>}
            {certStatus >= CERTIFICATION_STATUS_BY_NAME.CERT_DATA_REVIEW &&
                <Col style={{ marginRight: 10 }}>
                    <Button data-testid={`site-visit-form`} loading={isButtonLoading} type="primary" onClick={() => setAudubonSiteVisitOpen(true)}>{certStatus > CERTIFICATION_STATUS_BY_NAME.CERT_DATA_REVIEW ? `Site Visit Details` :`Add Site Visit Details`}</Button>
                </Col>}
            <Col><Button data-testid={`membership-details`} type="primary" onClick={onEditContactDetailsClicked}>Membership Details</Button></Col>
        </Row>;

    let summaryCardTitle = membershipId ? (<Row><Col>Certification Summary </Col> <Col style={{ marginLeft: 10 }}> <Text style={{ fontSize: 14 }} type="secondary"> | Course Name : {courseName} </Text> </Col>
        <Col style={{ marginLeft: 10 }}> <Text style={{ fontSize: 14 }} type="secondary"> | Member Number : {membershipId} </Text> </Col></Row>)
        : (<Row><Col>Certification Summary </Col> <Col style={{ marginLeft: 10 }}> <Text style={{ fontSize: 14 }} type="secondary"> | Course Name : {courseName} </Text> </Col>
            <Col style={{ marginLeft: 10, marginTop: -4 }}> <Text style={{ fontSize: 14 }} type="secondary"> | Member Number : <Button data-testid={`add-member-number`} type="link" onClick={onEditContactDetailsClicked}>Add Member Number</Button> </Text> </Col></Row>);

    const onSearch = async(value) => {
        if(value) {
            setSearching(true);
            setIsSearchOuNotFound(false)
            setMemberDetails([]);
            setMembershipId('');
            setSelectedOrg();
            setSelectedOrgUnit();
            const response = await getMemberDetails(selectedOrg, CERTIFICATION_TYPE, '', value);
            if(!response || response.length === 0) {
                setIsSearchOuNotFound(true);
            } else {
                setSelectedOrg(response[0].org_id);
                setSelectedOrgUnit(response[0].ou_id);
                setMembershipId(response[0].member_id);
            }
            setSearching(false);
        }
    }

    const onSiteVisitSubmit = async() => {
        setButtonLoading(true);
        setAudubonSiteVisitOpen(false);
        const data = {
            ou_id: selectedOrgUnit, 
            type: selectedCertificate,
            status: CERTIFICATION_STATUS_BY_NAME.SITE_VISIT,
            org_name: selectedOrgName
        }
        try {
            const response = await updateCertDetails(selectedOrg, data);
            if(response.status) setCertStatus(response.status);
        } catch (e) {
            console.log(e)
        }
        setButtonLoading(false);
    }

    return (
        <div style={{ width: '100%' }}>
            <Row style={{ padding: 5, marginTop: 5, display: 'flex' }}>
            <Space style={{ padding: 5, marginRight :20 }}>
                    <Select
                        showSearch
                        style={{ width: 220 }}
                        placeholder="Select a certification"
                        optionFilterProp="children"
                        onChange={onCertSelect}
                        value={selectedCertificate}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        disabled={!certCategoryOptions}
                        data-testid={`select-certification`}
                    >
                        {certCategoryOptions}
                    </Select>
                </Space>
                <Space style={{ padding: 5 }}>
                    <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Select an organization"
                        optionFilterProp="children"
                        onChange={onOrgSelect}
                        value={selectedOrg}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        data-testid={`select-organization`}
                    >
                        {orgListOptions}
                    </Select>
                </Space>
                <Space style={{ padding: 5 }}>
                    {<TreeSelect
                        treeData={orgUnitListOptions}
                        style={{ width: 200 }}
                        value={orgUnitListOptions.length > 0 ? selectedOrgUnit : null}
                        placeholder="Select organization unit"
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        onChange={onOrgUnitSelect}
                        disabled={!orgUnitListOptions}
                        data-testid={`select-organization-unit`}
                    />}
                </Space>
                <Space style={{ padding: 5}}>
                    <Search className="search-bar"
                        value={serchText}
                        onChange={val => setSerchText(val.target.value)}
                        loading={isSearching}
                        style={{width: 260 }}
                        placeholder="Search by Member Number"
                        onSearch={onSearch} 
                        enterButton
                        data-testid={`search-membership-id`} />  
                </Space>
            </Row>
            { isLoading && (<div className="banner-body">
                <Spin tip="Loading..." />
            </div>)}
            { !isLoading && isDetailsReady && issueCertModalOpen &&
                (<IssueCertificateModal orgId={selectedOrg} orgUnitId={selectedOrgUnit} certificate={selectedCertificate} onClose={onIssueCertModalClosed} onCertIssue={onCertAdded} s3FileKey={s3FileKey} selectedOrgName={selectedOrgName}/>)}
            { !isLoading && isDetailsReady && editAudubonDetailsOpen &&
                (<AudubonDetails orgId={selectedOrg} orgUnitId={selectedOrgUnit} onClose={onEditContactDetailsModalClosed} onAudubonDetailsUpdate={onAudubonDetailsUpdate} audubonDetails={memberDetails} />)}
            { !isLoading && isDetailsReady && audubonSiteVisitOpen &&
                (<AudubonSiteVisit orgId={selectedOrg} orgUnitId={selectedOrgUnit} certificate={selectedCertificate} onClose={() => setAudubonSiteVisitOpen(false)} onSiteVisitSubmit={onSiteVisitSubmit} 
                    isReportSubmited={certStatus > CERTIFICATION_STATUS_BY_NAME.CERT_DATA_REVIEW} isCertificationsCompleted={certStatus === CERTIFICATION_STATUS_BY_NAME.CERTIFIED} selectedOrgName={selectedOrgName}/>)}
            { !isLoading && issuedCertDetailModalOpen && 
                (<IssuedCertificateDetailsModal certificationDetails={certificationDetails[0]} onClose={() => setIssuedCertDetailModalOpen(false)}/>)
            }
            { !isLoading && isDetailsReady && (<div><Row>
                <Col span={24} style={{ minWidth: 400 }} >
                    <Card style={{ marginTop: 16 }} title={summaryCardTitle} extra={summaryExtraPanel} >
                        <Row>
                            <Steps className={isMobile() ? 'steps-container-mobile' : 'steps-container'} current={(CERTIFICATION_STATUS[certStatus] + 1)} labelPlacement='vertical' direction={isMobile() ? 'vertical' : 'horizontal'}>
                                {
                                    STEPS_BAR.map(step => <Step key={step.key} description={step.title} />)
                                }
                            </Steps>
                        </Row>
                        <PreCertification certDetails={certificationProgressList} siteAssessment={siteAssessmentCount} audubonOrgDetails={audubonOrgDetails}/>
                       
                        {certStatus >= CERTIFICATION_STATUS_BY_NAME.PREM_DATA_REVIEW && 
                            <div style={{ marginTop: 10 }}>
                                <CompleteCertification audubonCaseStudy={audubonCaseStudy} certDetails={certificationProgressList} docDetails={docProgressList} />
                            </div>}
                    </Card>
                </Col>
            </Row>
                <Row>
                    <Col span={24}>
                        <AudubonOrganisationDetailsView orgId={selectedOrg} orgUnitId={selectedOrgUnit} data={audubonOrgDetails} />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <AudubonSiteAssessmentListView orgId={selectedOrg} orgUnitId={selectedOrgUnit} orgDetails={audubonOrgDetails} certDetails={certificationProgressList} certType={selectedCertificate} setLoading={setIsLoading} updateCertList={updateAcceptedCertList} orgName={selectedOrgName} setSiteAssessmentCount={setSiteAssessmentCount} siteAssessmentCount={siteAssessmentCount} docList={docProgressList} />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <AudubonDocumentListView orgId={selectedOrg} orgUnitId={selectedOrgUnit} certType={selectedCertificate} data={docProgressList} orgName={selectedOrgName} />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <AudubonCertificationListView orgId={selectedOrg} orgUnitId={selectedOrgUnit} certType={selectedCertificate} data={certificationProgressList} setLoading={setIsLoading} updateCertList={updateAcceptedCertList} orgName={selectedOrgName} />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <AudubonCaseStudyListView orgId={selectedOrg} orgUnitId={selectedOrgUnit} data={audubonCaseStudy} />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <AudubonSiteValuesMapView orgId={selectedOrg} orgUnitId={selectedOrgUnit} certificate={selectedCertificate} />
                    </Col>
                </Row></div>)}
            {!isLoading && !isDetailsReady && !isSearching && (<div className="banner-body">
                <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }} className="banner">
                    {   isSearchOuNotFound 
                        ? "Sorry, the Member does not seem to exist. Please check the member number and try again."
                        : `In order to review the Certification Status, please select the 3 dropdowns relating to 'select a certification', 'select an organization' and the 'select org unit'. Alternatively you may select the 2 dropdowns relating to 'select a certification'  & the 'search by member number', to review the Certification Status.`}
                </div>
            </div>)}
        </div>
    );
}
