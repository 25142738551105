import Amplify from 'aws-amplify';
import { AWS_CONGITO_CONFIG, AWS_API_CONFIG } from '../config/app-config';
import { APIS } from './api-utils';

const { baseApiUrls } = AWS_API_CONFIG;

export function awsAmplifyApiInit() {
    Amplify.configure({
        API: {
            endpoints: [
                {
                    name: APIS.SITE_VALUE_API,
                    endpoint: baseApiUrls.siteValueAPI
                },
                {
                    name: APIS.CONNECT_ADMIN_API,
                    endpoint: baseApiUrls.adminCommonAPI
                },
                {
                    name: APIS.EXTERNAL_CERTIFICATION_API,
                    endpoint: baseApiUrls.externalCertificationAPI
                },
                {
                    name: APIS.DOCUMENT_API,
                    endpoint: baseApiUrls.documentAPI
                },
                {
                    name: APIS.TASK_API,
                    endpoint: baseApiUrls.taskAPI
                },
                {
                    name: APIS.INSPECTION_API,
                    endpoint: baseApiUrls.inspectionAPI
                },
                {
                    name: APIS.COMMENTS_API,
                    endpoint: baseApiUrls.commentsAPI
                },
                {
                    name: APIS.ACTIVITIES_API,
                    endpoint: baseApiUrls.activitiesAPI
                }
            ]
        }
    });
}

export function awsAmplifyAuthInit() {
    Amplify.configure(AWS_CONGITO_CONFIG);
}