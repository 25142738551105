import { Button, Card, Form, Input, Spin } from "antd";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import { signIn } from "./actions";
import ForgotPassword from "./ForgotPassword";
import { sessionStore } from "../store/session";

export const LoginPage = ({ setIsAuthenticatedUser }) => {

    const [requireNewPassword, setRequireNewPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoginView, setIsLoginView] = useState(true);
    const [userName, setUserName] = useState('');

    const history = useHistory();
    const { dispatch } = useContext(sessionStore);

    const [form] = Form.useForm();

    const login = async ({ newPassword, confirmNewPassword, username, password }) => {
        
        setLoading(true);
        if (requireNewPassword && newPassword !== confirmNewPassword) {
            setErrorMessage("New password does not match with confirm password");
            setLoading(false);
            return;
        }

        const error = await signIn(username, password, newPassword, dispatch);
        if (error && error === "NEW_PASSWORD_REQUIRED") {
            setRequireNewPassword(true);
            setErrorMessage("Please set a new password");
            setLoading(false);
            return;
        }

        if (error && error === "UNAUTHORIZED_USER") {
            setErrorMessage("Sorry, you are not able to access this application. Please contact an admin.");
            setLoading(false);
            return;
        }

        if (error) {
            setErrorMessage(error);
            setLoading(false);
            return;
        }

        setLoading(false);
        setIsAuthenticatedUser(true);
        history.push('/');
    };

    const toggleForgotPasswordView = () => {
        setIsLoginView(!isLoginView);
    }

    const newPasswordFieldItems = (requireNewPassword) ? <>
        <Form.Item
            label="New Password"
            name="newPassword"
            rules={[
                {
                    required: true,
                    message: 'Please input your new password',
                },
            ]}
        >
            <Input.Password placeholder="Type you new password here" disabled={loading ? true : false} />
        </Form.Item>
        <Form.Item
            label="Confirm New Password"
            name="confirmNewPassword"
            rules={[
                {
                    required: true,
                    message: 'Please re-enter your new password',
                },
            ]}
        >
            <Input.Password placeholder="Type you new password here too" disabled={loading ? true : false} />
        </Form.Item>
    </> : null;

    const errorView = (errorMessage) ? <div className="login-error">{errorMessage}</div> : null;

    const loginFormView = isLoginView ?
    <>
        <Form name="login" layout="vertical" form={form} onFinish={login}>
            {errorView}
            <Form.Item
                label="Username"
                name="username"
                rules={[
                    {
                        required: true,
                        message: "Please enter your username",
                    },
                ]}
            >
                <Input
                    placeholder="Type in your username"
                    disabled={loading ? true : false}
                    onChange={(value)=>setUserName(value.target.value)}
                ></Input>
            </Form.Item>
            <Form.Item
                label="Password"
                name="password"
                rules={[
                    {
                        required: true,
                        message: "Please enter your password",
                    },
                ]}
            >
                <Input.Password
                    placeholder="Password"
                    disabled={loading ? true : false}
                ></Input.Password>
            </Form.Item>
            {newPasswordFieldItems}
            <Form.Item>
                <Button
                    className="sign-in-btn btn btn--primary"
                    htmlType="submit"
                    block
                    icon={loading ? <Spin className="sign-in-spin" /> : null}
                    data-testid={`sign-in`}
                >
                    Sign In
                    </Button>
            </Form.Item>
        </Form>
        <div className="forgot-password-wrapper">
            <Button data-testid={`forgot-password-link`} type='link' className="forgot-password-btn link-btn" onClick={toggleForgotPasswordView}>Forgot Password</Button>
        </div>
    </>
    :
    <ForgotPassword
    username = {userName}
    setUsername = {setUserName}
    setIsLoginView = {setIsLoginView} />

    return (
        <div className="login-body">
            <div className="login-wrp">

                <div className="login-header">
                    <h2>Welcome!</h2>
                    <span>to the Audubon Admin Portal.</span>
                </div>
                <Card className="login-form-card">
                    {
                        loginFormView
                    }
                </Card>
            </div>
        </div>
    );
}