import React from 'react';

export const audubonLogo = 
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path d="M11.15 10.62C16.18 7.24 21.11 9.02 22.16 9.46C21.34 4.11 16.73 0 11.15 0C5.57001 0 0.960015 4.11 0.140015 9.46C1.19001 9.01 6.11001 7.24 11.15 10.62Z" fill="#057EC2"/>
            <path d="M11.15 16.87C11.15 16.87 8.11 10.49 0.05 10.24C0.02 10.54 0 10.84 0 11.15C0 17.31 4.99 22.3 11.15 22.3C17.31 22.3 22.3 17.31 22.3 11.15C22.3 10.84 22.28 10.54 22.25 10.24C14.18 10.49 11.15 16.87 11.15 16.87Z" fill="#8DC640"/>
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="22.3" height="22.3" fill="white"/>
            </clipPath>
        </defs>
    </svg>

export const connectLogo = 
<svg width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.91003 15.99C6.91003 15.99 5.90003 15.68 5.04003 15.02L1.86003 12.6C-0.219973 11.02 -0.619973 8.04 0.960027 5.96L5.62003 0L8.11003 1.94L3.47003 7.89C2.95003 8.57 3.09003 9.56 3.78003 10.09L6.96003 12.51C7.65003 13.04 8.65003 12.9 9.17003 12.21L13.82 6.25L16.31 8.19L11.67 14.14C10.75 15.35 9.34003 15.99 7.91003 15.99Z" fill="#706F71"/>
    <path d="M16.54 16.78L14.04 14.85L18.68 8.82003C19.2 8.13003 19.07 7.14003 18.38 6.61003L15.19 4.20003C14.49 3.67003 13.51 3.81003 12.98 4.50003L8.33002 10.53L5.83002 8.60003L10.47 2.57003C12.05 0.500026 15.03 0.100026 17.1 1.68003L20.28 4.10003C22.36 5.68003 22.76 8.66003 21.18 10.74L16.54 16.78Z" fill="#53C2CD"/>
</svg>