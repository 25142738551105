export const CASE_STUDY_OVEROLL_INFORMATION = [
  { title: 'Project Description', data: 'projectDescription', description: 'Give an overview of the project. Why did you choose it? What were conditions like before and after implementing the project?' },
  { title: 'Goals', data: 'goals', description: 'Please list your goals for the project' },
  { title: 'Implementation & Maintenance', data: 'implementationMaintenance', description: 'What specific steps did you take to implement it? What kind of on-going maintenance does it require? Please give sufficient detail so that someone interested in duplicating this project could do so.' },
  { title: 'Results', data: 'results', description: 'Describe the results you achieved. What were the environmental benefits? Please be as specific as possible about any tangible results (e.g., number of acres naturalized, new species observed, increase inhabitat acreage, number of birds fledged from nest boxes, number of gallons of water saved, acres taken out of intensive management, increase or decrease in man-hours needed to maintain, increase or decrease in equipment wear and tear).' },
  { title: 'Golfer / Employee Response', data: 'golferEmployeeResponse', description: 'How did golfers respond to the project? How did you communicate about your actions?' },
  { title: 'Perspective & Recommendations', data: 'perspectiveRecommendations', description: 'What, if anything, would you do differently if you were to do the project again? What would you recommend to others implementing this project?' }];

export const CASE_STUDY_PROPERTIES = [
  { key: 'generalDetails' },
  { key: 'projectDescription' },
  { key: 'goals' },
  { key: 'implementationMaintenance' },
  { key: 'results' },
  { key: 'golferEmployeeResponse' },
  { key: 'perspectiveRecommendations' },
  { key: 'economicCostsAndBenefits' },
  { key: 'other' }
]

export const DYNAMIC_FORM_FIELDS = {
  DESCRIPTION :"description",
  TEXT: "text",
  POINT_SECTION: "pointsection",
  INPUT: "input",
  DATE_TIME: "datetime",
  TEXT_AREA: "textarea",
  CHECKBOX: "checkbox",
  RADIO_BUTTON: "radio",
  DIVIDER: "divider" ,
  SECTION: "section",
  BUTTON_GROUP: "buttongroup",
  INPUT_GROUP: "inputgroup",
  MULTI_PART: "multipart",
  INLINE_STRUCTURED: "inline-structured",
  UPLOAD: "upload",
  TOGGLE_GROUP: "togglegroup",
  CHECKBOX_GROUP: "checkboxgroup",
  SELECT_GROUP: "selectgroup",
  MULTI_SELECT_GROUP:"multiselectgroup",
  MULTI_ROW_STRUCTURED : "multirow-structured"
};

export const DYNAMIC_FIELD_QUESTION_TYPES = {
  TEXT: "text",
  STRUCTURED: "structured",
  SECTION: "section",
  INLINE_STRUCTURED: "inline-structured",
  MULTIROW_STRUCTURED: "multirow-structured"
}

export const CERTIFICATION_CATEGORY = {
  DYNAMIC_CERTIFICATION: "dynamic_certification_form"
}