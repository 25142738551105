import { Modal, message } from 'antd';

export async function openS3DocumentByURL(apiUrl) {
  message.loading("please wait", 0);
  await fetch(apiUrl, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/pdf'
    },
  })
    .then(res => res.blob())
    .then(blob => {
      var url = new Blob([blob], { type: 'application/pdf' });
      var file = window.URL.createObjectURL(url);
      message.destroy();
      window.open(file);
    }).catch(error => message.destroy());
}

export function openFile(fileUrl, loadInCurrentWindow) {
  setTimeout(() => {
    const open = window.open(fileUrl, '_blank');
    if (open === null || typeof (open) === 'undefined') {
      // If popups are not supported let user download the file in a modal window.
      message.info("Turn off your pop-up blocker!");
      Modal.info({
        title: 'Download File',
        content: (
          <div>
            <a href={fileUrl} target='_blank'>Download</a>
          </div>
        ),
        onOk() { },
      });
    }
  }, 200);
}