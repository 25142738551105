import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Spin } from 'antd'
import { awsAmplifyAuthInit, awsAmplifyApiInit } from './utils/aws-configure';
import { SessionStateProvider } from './store/session'
import "./i18n";

awsAmplifyAuthInit();
awsAmplifyApiInit();

// loading component for suspence fallback
const Loader = () => (
  <div className="banner-body">
    <Spin tip="Loading..." />
  </div>
);

ReactDOM.render(
  <Suspense fallback={<Loader />}>
    <SessionStateProvider>
      <App />
    </SessionStateProvider>
  </Suspense>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
