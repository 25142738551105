import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Spin, DatePicker, Upload, Col,message } from 'antd';
import moment from 'moment';
import { InboxOutlined } from '@ant-design/icons';
import { getFileExtension } from '../utils/common-utils';
import { notifyWarning } from '../utils/notification';
import { addSiteVisitReport, getSiteVisitDetails, getSiteVisitS3SignedUrl } from './actions';
import { uploadFile } from '../utils/api-utils';
import { ulid } from 'ulid';
import _ from 'lodash';
import { openFile } from '../utils/doc-utils';

const FormItem = Form.Item;
const { Dragger } = Upload;
const EXTENTION_STRING = 'pdf';

export const AudubonSiteVisit = ({ orgId, orgUnitId, certificate, onSiteVisitSubmit, onClose, isReportSubmited, isCertificationsCompleted, selectedOrgName }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [uploadingDate, setUploadingDate] = useState(null);
  const [uploadingFile, setUploadingFile] = useState(null);
  const [siteVisitDetails, setSiteVisitDetails] = useState();
  const [isEditing, setIsEditing] = useState(false);

  const [form] = Form.useForm();

  const loadSitiVisitData = async () => {
    setIsLoading(true);
    const siteVisitData = await getSiteVisitDetails(orgId, orgUnitId, certificate);
    setSiteVisitDetails(siteVisitData);
    setIsLoading(false)
  }

  useEffect(async () => {
    if (isReportSubmited) {
      await loadSitiVisitData();

    }
  }, [isReportSubmited]);

  const onSubmitClicked = async () => {
    try {
      if (!uploadingDate && !uploadingFile) return;

      setIsBtnLoading(true);
      let fileKey = `site-visit/${ulid()}`;
      let fileType = getFileExtension(uploadingFile.name);
      const url = await getSiteVisitS3SignedUrl(orgId, orgUnitId, fileKey, fileType, false);
      if (!url) return;
      await uploadFile(url.data, uploadingFile, fileType);

      let details = {
        certType: certificate,
        siteVisitDate: uploadingDate,
        siteVisitReportFile: fileKey,
        version: 1,
        isUpdate: isEditing,
        org_name: selectedOrgName
      }
      await addSiteVisitReport(orgId, orgUnitId, details);
      setIsBtnLoading(false);
      if (isReportSubmited) {
        await loadSitiVisitData();
        setIsEditing(false)
      } else {
        onSiteVisitSubmit();
      }
      message.success('Site visit details added successfully');
    } catch (e) {
      setIsBtnLoading(false);
      if (e.errorFields) {
        return;
      }
      console.error(e);
      message.error('Error in adding Site visit details, Please retry.')
    }
  }

  const disabledDates = (date) => {
    if (!date) return false;
    const diff = moment().diff(date, 'days');
    return diff < 0;
  }

  const pdfDraggerprops = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    beforeUpload: (file, fileList) => {
      const ext = getFileExtension(file.name);
      if (!ext || !EXTENTION_STRING.includes(ext.toLowerCase())) {
        notifyWarning("incorrect file type!");
        return false;
      }
      setUploadingFile(file);
      return false;
    },
  };

  const checkIsUploadingReady = () => {
    if (uploadingFile && uploadingDate) {
      return true;
    }
    return false
  }

  const downloadSiteVisit = async () => {
    if (_.isEmpty(siteVisitDetails, true)) return
    setIsLoading(true);
    const url = await getSiteVisitS3SignedUrl(orgId, orgUnitId, siteVisitDetails.SiteVisitReportFile, 'application/pdf', true);
    if (!url) return;
    openFile(url.data)
    setIsLoading(false);
  }


  return (
    <Modal
      visible={true}
      onCancel={onClose}
      wrapClassName=''
      maskClosable={false}
      centered
      title={isReportSubmited ? 'Site Visit Details' : 'Add Site Visit Details'}


      width='500px'
      footer={isCertificationsCompleted ? null : [
        <div>
          {(!isReportSubmited || isEditing) && <Button data-testid={`add-sitevisit-report`} className="btn btn--primary" onClick={onSubmitClicked} icon={
            isBtnLoading ? (
              <Spin size="small" />
            ) : null
          } disabled={!checkIsUploadingReady()}> &nbsp;Save</Button>}
          {(isReportSubmited && !isLoading) && <Button data-testid={`cancel-editing-sitevisit-form`} className="btn btn--primary"
            onClick={() => setIsEditing(!isEditing)}  disabled={isBtnLoading}> &nbsp;{isEditing ? 'Cancel' : 'Edit'}</Button>}
        </div>
      ]}
    >
      {isLoading
        ? <div className="banner-body">
          <Spin tip="Loading..." />
        </div>
        : <div>
          {isReportSubmited && !isEditing
            ? <div>
              <Form.Item name="submited-date">
                <Row style={{ alignItems: 'baseline' }}>
                  <Col span={8}>Site Visit Date</Col>
                  <Col span={16}>: {siteVisitDetails ? moment(siteVisitDetails.SiteVisitDate).format("DD/MM/YYYY") : ''}</Col>
                </Row>
              </Form.Item>
              <Form.Item name="submited-report">
                <Row style={{ alignItems: 'baseline' }}>
                  <Col span={8}>Site Visit Details</Col>
                  <Col span={16}>
                    <Row>:&nbsp; {<p onClick={() => downloadSiteVisit()} className='audubon-link-text'>{'Site visit details.pdf'}</p>}</Row>
                  </Col>
                </Row>
              </Form.Item>
            </div>
            : <div>
              <Form
                layout="vertical"
                form={form}
              >
                <Form.Item name="date">
                  <Row style={{ alignItems: 'baseline' }}>
                    <p style={{ marginRight: 20 }}>Site Visit Date</p>
                    <DatePicker data-testid={`site-visit-date`} disabledDate={disabledDates} onChange={(date, dateString) => setUploadingDate(dateString)} />
                  </Row>
                </Form.Item>

                <FormItem>
                  <Dragger {...pdfDraggerprops} onRemove={() => setUploadingFile(null)} accept=".pdf">
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                      Please upload your site visit details file here.
                    </p>
                  </Dragger>
                </FormItem>
              </Form>
            </div>}
        </div>}
    </Modal>
  )
}