import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Progress, Collapse } from 'antd';
import { STATUS } from '../../utils/constants';

const { Panel } = Collapse;
export const PreCertification = ({ certDetails, siteAssessment, audubonOrgDetails }) => {
  const [preCertCount, setPreCertCount] = useState(0);
  const [assingCertCount, setAssingCertCount] = useState(0);
  const [totalAssingCertCount, setTotalAssingCertCount] = useState(0);
  const [completedCertCount, setCompletedCertCount] = useState(0);
  const [orgFormStatus, setOrgFormStatus] = useState({ completedRecords: 0, allRecords: 1 })

  useEffect(() => {
    if (audubonOrgDetails) {
      const orgDetails = audubonOrgDetails.audubon_details;
      let completedRecords = orgDetails.GeneralInformationForm && orgDetails.GeneralInformationForm.Status === STATUS.DONE ? 1 : 0;
      let allRecords = 1
      setOrgFormStatus({ completedRecords: completedRecords, allRecords: allRecords })
    }
  }, [audubonOrgDetails]);

  useEffect(() => {
    if (certDetails) {
      let templateList = certDetails.tasks.filter(temp => temp.is_preliminary === 1);
      setPreCertCount(templateList.length);
      let assessment = [];
      let assingCertCount = 0;
      templateList.map(temp => {
        let assignee = certDetails.records.filter(record => record.template_id === temp.template_id);
        if (assignee.length > 0) assingCertCount++;
        assessment.push(...assignee);
      });
      let completed = assessment.filter(record => record.status === STATUS.DONE)
      setAssingCertCount(assingCertCount);
      setTotalAssingCertCount(assessment.length);
      setCompletedCertCount(completed.length);
    }

  }, [certDetails])
  const certCardStyle = { bodyStyle: { backgroundColor: '#ffffff', textAlign: 'center', fontSize: 'small' }, headStyle: { backgroundColor: '#ECEFF1' } };

  return (
    <Collapse accordion defaultActiveKey={1}>
      <Panel className="certification-summary-view" header='Pre Certification' key='1'>
        <Row>
          <Col span={6} style={{ paddingTop: 30, paddingRight: 20, minWidth: 200 }}>
            <Row justify="center">{<div><Progress type="dashboard" percent={100 * (orgFormStatus.completedRecords / orgFormStatus.allRecords)} format={() => `${orgFormStatus.completedRecords}  / ${orgFormStatus.allRecords}`} /></div>} </Row>
            <Row justify="center"> Org Details </Row>
          </Col>
          <Col span={6} style={{ paddingTop: 30, paddingRight: 20, minWidth: 200 }}>
            <Row justify="center">{<div><Progress type="dashboard" percent={100 * (siteAssessment.completedRecords / siteAssessment.allRecords)} format={() => `${siteAssessment.completedRecords}  / ${siteAssessment.allRecords}`} /></div>} </Row>
            <Row justify="center"> Site Assessment </Row>
          </Col>
          <Col span={12} style={{ paddingRight: 20, minWidth: 300 }}>
            <Card className='pre-certification-card-progress' title="Environment Planning" {...certCardStyle}>
              <Row> 
                <Col span={12}>
                  <Row justify="start"> Pre Certifications Assigned </Row>
                </Col>
                <Col span={12}>
                  <Row>
                    <Progress size="small" percent={assingCertCount / preCertCount * 100} format={() => `${assingCertCount}  / ${preCertCount}`} />
                  </Row>
                </Col>
                <Col span={12}>
                  <Row justify="start"> Pre Certifications Completed </Row>
                </Col>
                <Col span={12} style = {{padding:0}}>
                  <Row>
                    <Progress size="small" percent={completedCertCount / totalAssingCertCount * 100} format={() => `${completedCertCount}  / ${totalAssingCertCount}`} />
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
}