import React, { useState } from 'react';
import { Descriptions, Button } from 'antd';
import _ from 'lodash';
import { emptyContent, getFieldBoolean, getFieldValue, lblStyle } from '../form-actions/FormActions';
import { DownloadOutlined } from '@ant-design/icons';

export const PlantWildlifeInformation = ({ plantAndWildlifeForm, downloadFileFromS3 }) => {

  const [wildlifeInventoryDownloading, setWildlifeInventoryDownloading] = useState(false);
  const [plantInventoryDownloading, setPlantInventoryDownloading] = useState(false);

  return (
    <>
      {_.isEmpty(plantAndWildlifeForm) ? emptyContent
        : <Descriptions title='Plant and Wildlife Details' {...lblStyle} bordered style={{ paddingTop: 24 }} >
          <Descriptions.Item label='Do you maintain a wildlife inventory already' span={3}>
            {getFieldValue(plantAndWildlifeForm.maintainWildlifeInventory)}
            {plantAndWildlifeForm && plantAndWildlifeForm.maintainWildlifeInventory && plantAndWildlifeForm.maintainWildlifeInventory === 'Yes' &&
              <Button data-testid='orgDetails-wildlifeInventory' loading={wildlifeInventoryDownloading} style={{ marginLeft: 10 }} icon={<DownloadOutlined />} onClick={() => downloadFileFromS3(`wildLifeInventoryFile`, setWildlifeInventoryDownloading)}>
                View wild life inventory file
              </Button>}
          </Descriptions.Item>
          <Descriptions.Item label='Do you maintain a plant inventory already?' span={3}>
            {getFieldValue(plantAndWildlifeForm.maintainPlantInventory)}
            {plantAndWildlifeForm && plantAndWildlifeForm.maintainPlantInventory && plantAndWildlifeForm.maintainPlantInventory === 'Yes' &&
              <Button data-testid='orgDetails-plantInventory' loading={plantInventoryDownloading} style={{ marginLeft: 10 }} icon={<DownloadOutlined />} onClick={() => downloadFileFromS3(`plantInventoryFile`, setPlantInventoryDownloading)}>
                View plant inventory file
              </Button>}
          </Descriptions.Item>
          <Descriptions.Item label='Have any of the following been identified on the property?' span={3}> Endangered species : {getFieldBoolean(plantAndWildlifeForm.endangeredSpecies)} <br />
            Endangered species : {getFieldBoolean(plantAndWildlifeForm.endangeredSpecies)} <br />
            Threatened Species : {getFieldBoolean(plantAndWildlifeForm.threatenedSpecies)} <br />
            Species Of Special Concern : {getFieldBoolean(plantAndWildlifeForm.speciesOfSpecialConcern)} <br />
            "Problem" Wildlife Species : {getFieldBoolean(plantAndWildlifeForm.problemWildlifeSpecies)} <br />
            "Problem" Plant Species : {getFieldBoolean(plantAndWildlifeForm.problemPlantSpecies)} <br />
          </Descriptions.Item>
          <Descriptions.Item label='If you checked any of the above, please list' span={3}>{getFieldValue(plantAndWildlifeForm.plantWildlifeDetails)}</Descriptions.Item>
        </Descriptions>
      }
    </>
  )
}