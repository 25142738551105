import React, { useEffect, useState } from 'react';
import { Descriptions, Spin, Card, Row, Col, Empty, Collapse, Image } from 'antd';
import _ from 'lodash';
import { formatDateTimeToDisplay, getProgressIcon } from '../utils/common-utils';
import { CASE_STUDY_OVEROLL_INFORMATION } from './constants';
import { checkCaseStudyOverollProcess, checkProperties } from './case-study/caseStudyActions';

const { Panel } = Collapse;

export const AudubonCaseStudyListView = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [audubonCaseStudy, setAudubonCaseStudy] = useState({});
  const [progress, setProgress] = useState(0);

  const getFieldValue = (field) => field ? field : '';

  const emptyContent = <Empty description="No Data Added" />
  const cardTitle = <Row><Col span={12}>Case Study</Col><Col span={12} style={{ textAlign: 'right' }}>{getProgressIcon(progress)}</Col></Row>;

  const lblStyle = { labelStyle: { backgroundColor: '#E0EBF5', fontWeight: '500' } };
  const imgStyle = { style: { paddingRight: 15, paddingLeft: 15 } }

  useEffect(() => {
    setIsLoading(true);
    if (props.orgId && props.orgUnitId && props.data) {
      setAudubonCaseStudy(props.data);
      setProgress(checkCaseStudyOverollProcess(props.data) > 0 ? 100 : 0);
    }
    setIsLoading(false);
  }, [props]);

  const { generalDetails, economicCostsAndBenefits, other } = audubonCaseStudy;
  const genaralInformation = generalDetails ? generalDetails : {};
  const economiccosts = economicCostsAndBenefits ? economicCostsAndBenefits : {};
  const otherInfo = other ? other : {};

  const viewImageList = (images) => {
    return (
      <Image.PreviewGroup>
        {images && images.map(img => (
          <Row key={img.uid} style={{ paddingBottom: 10 }}>
            <Image width={150} height={80} {...imgStyle} src={img.imgURL} placeholder={true} />
            <p style={{ paddingLeft: 20, paddingTop: 8 }}>{(img.name)}</p>
          </Row>
        ))}
      </Image.PreviewGroup>
    )
  }

  return (
    <Card title={cardTitle}>
      <div>
        {isLoading && (<Spin tip='Loading CaseStudy Data...' />)}
        <Collapse accordion>
          <Panel header='General Details' key='1' extra={getProgressIcon(checkProperties(genaralInformation) ? 100 : 0, 24)}>
            {_.isEmpty(genaralInformation) ? emptyContent
              : <Descriptions title='General Details' {...lblStyle} bordered style={{ paddingTop: 24 }} >
                <Descriptions.Item label='Project Title' span={3}>{getFieldValue(genaralInformation.projectName)}</Descriptions.Item>
                <Descriptions.Item label='Property Name' span={3}>{getFieldValue(genaralInformation.propertyName)}</Descriptions.Item>
                <Descriptions.Item label='Location(City/State)' span={2}>{getFieldValue(genaralInformation.location)}</Descriptions.Item>
                <Descriptions.Item label='Project Coordinator(name and title)'>{getFieldValue(genaralInformation.projectCoordinator)}</Descriptions.Item>
                <Descriptions.Item label='Phone Number' span={2}>{getFieldValue(genaralInformation.phoneNumber)}</Descriptions.Item>
                <Descriptions.Item label='E-mail'>{getFieldValue(genaralInformation.email)}</Descriptions.Item>
              </Descriptions>
            }
          </Panel>
          {CASE_STUDY_OVEROLL_INFORMATION.map(info => (
            <Panel header={info.title} key={`info${info.data}`} extra={getProgressIcon(checkProperties(audubonCaseStudy[`${info.data}`]) ? 100 : 0, 24)}>
              {_.isEmpty(audubonCaseStudy[`${info.data}`]) ? emptyContent
                : <Descriptions title={info.title} {...lblStyle} bordered style={{ paddingTop: 24 }} >
                  <Descriptions.Item span={3}><b>{getFieldValue(info.description)}</b></Descriptions.Item>
                  <Descriptions.Item span={3}>{getFieldValue(audubonCaseStudy[`${info.data}`])}</Descriptions.Item>
                </Descriptions>
              }
            </Panel>
          ))
          }
          <Panel header='Economic Costs & Benefits' key='2' extra={getProgressIcon(checkProperties(economicCostsAndBenefits) ? 100 : 0, 24)}>
            {_.isEmpty(economiccosts) ? emptyContent
              : <Descriptions title='Economic Costs & Benefits' {...lblStyle} bordered style={{ paddingTop: 24 }} >
                <Descriptions.Item label='What was the funding source for this project ?' span={3}>{getFieldValue(economiccosts.fundingSource)}</Descriptions.Item>
                <Descriptions.Item label='How much did it cost to implement this project ?'>{economiccosts && economiccosts.implementCost ? `$ ${getFieldValue(economiccosts.implementCost)}` : ''}</Descriptions.Item>
                <Descriptions.Item label='What are your anticipated or actual financial savings ?'>{getFieldValue(economiccosts.actualFinancialSavings)}</Descriptions.Item>
              </Descriptions>
            }
          </Panel>
          <Panel header='Other' key='3' extra={getProgressIcon(checkProperties(other) ? 100 : 0, 24)}>
            {_.isEmpty(otherInfo) ? emptyContent
              : <>
                <Descriptions title='Other' {...lblStyle} bordered style={{ paddingTop: 24 }} >
                  <Descriptions.Item label='Are you willing to take calls from other Audubon International participants, organizations, or media regarding this project ?' span={3}>{otherInfo.makeCalls}</Descriptions.Item>
                  <Descriptions.Item label='Do you have photos available ?'>{otherInfo.photosAvailable}</Descriptions.Item>
                </Descriptions>
                {otherInfo.photosAvailable && otherInfo.photosAvailable === 'Yes' && <div>
                  {otherInfo.before.length > 0 && <Descriptions title='Before' {...lblStyle} bordered style={{ paddingTop: 24 }} >
                    {viewImageList(otherInfo.before)}
                  </Descriptions>}
                  {otherInfo.after.length > 0 && <Descriptions title='After' {...lblStyle} bordered style={{ paddingTop: 24 }} >
                    {viewImageList(otherInfo.after)}
                  </Descriptions>}
                </div>}
                <Descriptions title='' {...lblStyle} bordered style={{ paddingTop: 24 }} >
                  <Descriptions.Item label='Date Submitted  '>{getFieldValue(formatDateTimeToDisplay(otherInfo.dateSubmitted && otherInfo.dateSubmitted !== null ? otherInfo.dateSubmitted : '', 'DD MMMM YYYY'))}</Descriptions.Item>
                </Descriptions>
              </>}
          </Panel>
        </Collapse>
      </div>
    </Card>
  )
}