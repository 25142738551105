import React from "react";
import { Drawer, Layout, Menu } from 'antd';
import { FileTextOutlined, PlayCircleOutlined, HomeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const { Sider } = Layout;

export const SideMenu = ({ menuCollapsed, selectedMenu, isDesktop, toggle }) => {

    const { t } = useTranslation();

    const menuItems = (
            <Menu theme="light" mode="inline" defaultSelectedKeys={[selectedMenu]}>
                <Menu.Item key="home" icon={<HomeOutlined />} style={{height : 30}}>
                    <Link to="/">
                    {t("Dashboard")}
                    </Link>
                </Menu.Item>
                {/* <Menu.Item key="documents" icon={<FileTextOutlined />} style={{height : 30}}>
                    <Link to="/documents">
                    {t("Documents")}
                    </Link>
                </Menu.Item>
                <Menu.Item key="inspections" icon={<PlayCircleOutlined />} style={{height : 30}} >
                    <Link to="/inspections">
                    {t("Certifications")}
                    </Link>
                </Menu.Item> */}
            </Menu>
        );

    return ( !isDesktop ? <Drawer
            placement="left"
            closable={false}
            onClose={toggle}
            width={250}
            visible={menuCollapsed}
            className="side-drawer"
        >   
            <div>
                <div className="side-menu-logo" >
                    <img src='/icons/audubon-logo.png' alt='' style={{ maxHeight: '50px', maxWidth: '100px' }} />
                </div>
                {menuItems}
            </div>
        </Drawer> :
        <Sider trigger={null} collapsible collapsed={menuCollapsed} className="side-menu">
            {menuItems}
        </Sider>
    )
}