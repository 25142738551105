import React from 'react';
import { Descriptions } from 'antd';
import _ from 'lodash';
import { emptyContent, getFieldBoolean, getFieldValue, lblStyle, lblStyleMidLengthDesc } from '../form-actions/FormActions';


const SOIL_TYPE = [{ name: 'Greens', val: 'green' }, { name: 'Tees', val: 'tees' }, { name: 'Fairways', val: 'fairways' }, { name: 'Roughs', val: 'roughs' }, { name: 'Other', val: 'other' }];
export const ManagementContex = ({ managementContextForm }) => {
  const { uniqueFeatures, budget, soil } = managementContextForm ? managementContextForm : {};

  return (
    <>
      {_.isEmpty(managementContextForm) ? emptyContent
        : <>
          <Descriptions title='General Details'  {...lblStyleMidLengthDesc} bordered style={{ paddingTop: 24 }}>
            <Descriptions.Item label='What unique features or constraints of your site have to be accounted for when managing in-play and out-of-play areas on your golf course?' span={2}>
              Tight Layout : {getFieldBoolean(uniqueFeatures.tightLayout)} &nbsp;&nbsp;
              <br /> High Expectations Regarding Playing Conditions : {getFieldBoolean(uniqueFeatures.highExpectationsRegardingPlayingConditions)} &nbsp;&nbsp;
              <br /> Demanding Neighbors : {getFieldBoolean(uniqueFeatures.demandingNeighbors)} &nbsp;&nbsp;
              <br /> Historical Sites : {getFieldBoolean(uniqueFeatures.historicalSites)} &nbsp;&nbsp;
              <br /> Major Tournaments : {getFieldBoolean(uniqueFeatures.majorTournaments)} &nbsp;&nbsp;
              <br /> High Expectations Regarding Aesthetics : {getFieldBoolean(uniqueFeatures.highExpectationsRegardingAesthetics)} &nbsp;&nbsp;
              <br /> Endangered/Threatened Species : {getFieldBoolean(uniqueFeatures.endangeredThreatenedSpecie)} &nbsp;&nbsp;
              <br /> Endangered/Threatened Habitats : {getFieldBoolean(uniqueFeatures.endangeredThreatenedHabitats)} &nbsp;&nbsp;
              <br /> Wetlands : {getFieldBoolean(uniqueFeatures.wetlands)} &nbsp;&nbsp;
              <br /> Limited Staff : {getFieldBoolean(uniqueFeatures.limitedStaff)} &nbsp;&nbsp;
              <br /> Other : {getFieldBoolean(uniqueFeatures.other)} &nbsp;&nbsp;
            </Descriptions.Item>
          </Descriptions>
          <Descriptions title='Budget'  {...lblStyleMidLengthDesc} bordered style={{ paddingTop: 24 }}>
            <Descriptions.Item label='What is the total annual maintenance budget for your golf course?'>
              {getFieldValue(managementContextForm && managementContextForm.budget ? `$ ${budget.total}` : '')}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions title='Soil'  {...lblStyle} bordered style={{ paddingTop: 24 }} column={2}>
            {managementContextForm && managementContextForm.soil && SOIL_TYPE.map(st => (
              <Descriptions.Item label={st.name} key={st.val}>
                Soil Type : {getFieldValue(soil[st.val].type)} <br /> Soil Test Frequency : {getFieldValue(soil[st.val].soilTest)}
              </Descriptions.Item>
            ))
            }
          </Descriptions></>
      }
    </>
  )
}