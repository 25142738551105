import { CASE_STUDY_PROPERTIES } from "../constants";

export const isObject = (val) => val instanceof Object;

export const checkProperties = (obj) => {
  if (isObject(obj)) {
    for (var key in obj) {
      if ((!Array.isArray(obj[key]) && obj[key] !== null && obj[key] !== "") || (Array.isArray(obj[key]) && obj[key].length > 0))
        return true;
    }
    return false;
  } else {
    return obj ? true : false;
  }
}

export const checkCaseStudyOverollProcess = (caseStudyData) => {
  let completedRecords = 0;
  for (let i = 0; i < CASE_STUDY_PROPERTIES.length; i++) {
    if (checkProperties(caseStudyData[`${CASE_STUDY_PROPERTIES[i].key}`])) {
      completedRecords++;
    }
  }
  return completedRecords;
}