import { useState } from 'react';
import { Form, Input, Button, Spin, Alert } from 'antd';
import { resetNewPassword, sendForogotPasswordCode } from './actions';
import { useTranslation } from 'react-i18next';

const ForgotPassword = (props) => {

  const { t } = useTranslation();

    const {
        username,
        setUsername,
        setIsLoginView
    } = props;

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [codeSendView, setCodeSendView] = useState(false);

    const sendCode = async (userParams) => {
        setLoading(true);
        setUsername(userParams.userName);
        const error = await sendForogotPasswordCode(userParams.userName);
        if (error && error === "Username/client id combination not found.") {
          setErrorMessage(t("You are not a registered user"));
          setLoading(false);
          return;
        }
        if (error) {
          setErrorMessage(error);
          setLoading(false);
          return;
        }
        setCodeSendView(true);
        setLoading(false);
      };

      const resetPassword = async (credentialParams) => {
        setLoading(true);
        if (credentialParams.password !== credentialParams.confirmPassword) {
          setErrorMessage(t("Passwords are not matching"));
          setLoading(false);
          return;
        }
        const error = await resetNewPassword(
          username,
          credentialParams.code,
          credentialParams.password
        );
        if (
          error &&
          error === "Invalid verification code provided, please try again."
        ) {
          setErrorMessage(t("Invalid Verification Code,Please Try Again"));
          setLoading(false);
          return;
        }
        if (error) {
          setErrorMessage(error);
          setLoading(false);
          return;
        }
        setLoading(false);
        setCodeSendView(false);
        setErrorMessage('');
        setIsLoginView(true);
      };

    const errorView = errorMessage ? (
        <Alert message={errorMessage} type="error" showIcon />
    ) : null;

    return (
        <div>
        {codeSendView ? (
            <Form layout="vertical" onFinish={resetPassword}>
              <Form.Item
                label= {t("Verification Code")}
                name="code"
                rules={[
                  {
                    required: true,
                    message: t("Please input your verification code"),
                  },
                ]}
              >
                <Input
                  placeholder={t("Please input your verification code")}
                  autoComplete="off"
                  disabled={loading ? true : false}
                ></Input>
              </Form.Item>
              <Form.Item
                label={t("New Password")}
                name="password"
                rules={[
                  {
                    required: true,
                    message: t("Please enter your new password"),
                  },
                ]}
              >
                <Input.Password
                  placeholder={t("Enter your new password")}
                  autoComplete="off"
                  disabled={loading ? true : false}
                />
              </Form.Item>
              <Form.Item
                label={t("Confirm New Password")}
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    message: t("Please re-enter your new password"),
                  },
                ]}
              >
                <Input.Password
                  placeholder={t("Re-enter your new password")}
                  autoComplete="off"
                  disabled={loading ? true : false}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  className="send-password-btn btn btn--primary"
                  htmlType="submit"
                  block
                  shape="round"
                  data-testid={`set-new-password`}
                >
                  {t("Send")}
                </Button>
              </Form.Item>
              {errorView}
            </Form>
          ) : (
            <div>
              <div className="alert alert-info">
                <div><strong> {t("Forgot Password?")}</strong></div>
                {t("We will send you a verification code to your email.")}
              </div>
              <Form layout="vertical" onFinish={sendCode} initialValues={{userName: username}}>
                <Form.Item
                  label={t("Username")}
                  name="userName"
                  rules={[
                    {
                      required: true,
                      message: t("Please input your user name here"),
                    },
                  ]}
                >
                  <Input
                    placeholder={t("Please input your user name here")}
                    disabled={loading ? true : false}
                  ></Input>
                </Form.Item>
                <Form.Item >
                  <Button
                    className="forgot-password-btn btn btn--primary"
                    htmlType="submit"
                    block
                    shape="round"
                    icon={
                      loading ? (
                        <Spin size="small" />
                      ) : null
                    }
                    data-testid={`password-reset-request`}
                  >
                    &nbsp;{t("Request Pasword Reset")}
                  </Button>
                </Form.Item>
                {errorView}
              </Form>
            </div>
          )}
        </div>
    );
}

export default ForgotPassword;